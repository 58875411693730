// components/tabs/FormalitiesTab.jsx
import React from 'react';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";

// Constantes locales pour éviter les erreurs d'import
const FORMALITES = {
  FORFAIT_BASE: {
    VENTE: 130,
    PRET: 100,
    MAINLEVEE: 100
  },
  TELEACTES: 50,
  RECOMMANDE: 7.08,
  PREALABLES: 100,
  POSTERIEURES: 100
};

const FormalitiesTab = ({ formData, setFormData }) => {
  const updateFormalites = (field, value) => {
    setFormData(prev => ({
      ...prev,
      formalities: {
        ...prev.formalities,
        [field]: value
      }
    }));
  };

  // Calcul du sous-total des formalités
  const calculateSubTotal = () => {
    const { formalities = {} } = formData;
    let total = 0;

    // Forfait de base
    if (formalities.base) {
      total += FORMALITES.FORFAIT_BASE.VENTE;
    }

    // Téléactes
    if (formalities.teleactes) {
      total += FORMALITES.TELEACTES;
    }

    // Recommandés
    total += (formalities.registeredMails || 0) * FORMALITES.RECOMMANDE;

    // Formalités préalables
    if (formalities.prealables) {
      total += FORMALITES.PREALABLES;
    }

    // Formalités postérieures
    if (formalities.posterieures) {
      total += FORMALITES.POSTERIEURES;
    }

    return total;
  };

  const subTotal = calculateSubTotal();

  // Initialiser les valeurs par défaut si nécessaire
  React.useEffect(() => {
    if (!formData.formalities) {
      setFormData(prev => ({
        ...prev,
        formalities: {
          base: true,
          teleactes: true,
          registeredMails: 2,
          prealables: true,
          posterieures: true
        }
      }));
    }
  }, []);

  return (
    <div className="space-y-6">
      {/* En-tête avec sous-total */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Formalités</h3>
        <div className="text-sm text-gray-500">
          Sous-total HT : {subTotal.toFixed(2)} €
        </div>
      </div>

      {/* Forfait de base */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.formalities?.base || false}
              onChange={(e) => updateFormalites('base', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">Forfait de base</span>
            <Tooltip content="Forfait incluant les formalités courantes obligatoires">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </label>
          <span className="text-sm text-gray-500">
            {FORMALITES.FORFAIT_BASE.VENTE} €
          </span>
        </div>
      </div>

      {/* Téléactes */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.formalities?.teleactes || false}
              onChange={(e) => updateFormalites('teleactes', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">Téléactes</span>
            <Tooltip content="Service de dématérialisation des actes">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </label>
          <span className="text-sm text-gray-500">
            {FORMALITES.TELEACTES} €
          </span>
        </div>
      </div>

      {/* Lettres recommandées */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div>
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">
              Nombre de lettres recommandées ({FORMALITES.RECOMMANDE} € / unité)
            </label>
            <Tooltip content="Lettres recommandées obligatoires pour certaines notifications">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <input
            type="number"
            min="0"
            value={formData.formalities?.registeredMails || 0}
            onChange={(e) => updateFormalites('registeredMails', parseInt(e.target.value) || 0)}
            className="w-24 p-2 border rounded-lg"
          />
        </div>
      </div>

      {/* Formalités préalables et postérieures */}
      <div className="bg-white p-4 rounded-lg border border-gray-200 space-y-4">
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.formalities?.prealables || false}
              onChange={(e) => updateFormalites('prealables', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">Formalités préalables</span>
            <Tooltip content="Démarches et vérifications préalables à l'acte">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </label>
          <span className="text-sm text-gray-500">
            {FORMALITES.PREALABLES} €
          </span>
        </div>

        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.formalities?.posterieures || false}
              onChange={(e) => updateFormalites('posterieures', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">Formalités postérieures</span>
            <Tooltip content="Démarches et formalités après signature de l'acte">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </label>
          <span className="text-sm text-gray-500">
            {FORMALITES.POSTERIEURES} €
          </span>
        </div>
      </div>

      {/* Note d'information */}
      <div className="bg-blue-50 p-4 rounded-lg">
        <p className="text-sm text-blue-700">
          Les formalités correspondent aux démarches administratives obligatoires. 
          Les montants sont soumis à TVA au taux de 20%.
          Le forfait de base comprend les formalités courantes obligatoires.
        </p>
      </div>
    </div>
  );
};

export default FormalitiesTab;