import { useState, useCallback, useEffect } from 'react';
import { CALCUL_CONSTANTS } from '@/constants/calculator';

export const useHistory = (formData, results) => {
  const [savedCalculations, setSavedCalculations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  // Chargement initial
  useEffect(() => {
    try {
      const saved = localStorage.getItem('savedCalculations');
      if (saved) {
        setSavedCalculations(JSON.parse(saved));
      }
    } catch (error) {
      console.error('Erreur chargement historique:', error);
    }
  }, []);

  const handleSave = useCallback(async (resultsToSave = results) => {
    if (!resultsToSave) return;
    
    setIsSaving(true);
    try {
      const calculationData = {
        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
        date: new Date().toISOString(),
        formData: {
          ...formData,
          savedAt: new Date().toISOString()
        },
        results: resultsToSave,
        metadata: {
          version: CALCUL_CONSTANTS.VERSION,
          userAgent: navigator.userAgent,
          timestamp: Date.now()
        }
      };

      const savedCalcs = [...savedCalculations, calculationData]
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 50);

      localStorage.setItem('savedCalculations', JSON.stringify(savedCalcs));
      setSavedCalculations(savedCalcs);

    } catch (error) {
      console.error('Erreur sauvegarde:', error);
      throw new Error('Impossible de sauvegarder le calcul');
    } finally {
      setIsSaving(false);
    }
  }, [formData, results, savedCalculations]);

  return {
    savedCalculations,
    isSaving,
    showHistory,
    setShowHistory,
    handleSave
  };
};