import React from "react";

const Card = ({ className, children, ...props }) => {
  return (
    <div className={`bg-white rounded-lg shadow ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

const CardHeader = ({ className, children, ...props }) => {
  return (
    <div className={`p-6 pb-3 ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

const CardTitle = ({ className, children, ...props }) => {
  return (
    <h3 className={`text-lg font-semibold text-gray-900 ${className || ""}`} {...props}>
      {children}
    </h3>
  );
};

const CardContent = ({ className, children, ...props }) => {
  return (
    <div className={`p-6 pt-3 ${className || ""}`} {...props}>
      {children}
    </div>
  );
};

export { Card, CardHeader, CardTitle, CardContent };