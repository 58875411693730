import { useState, useCallback, useRef } from 'react';
import { calculationService } from '@/services/calculationService';
import { initialFormState } from '../config';

export const useCalculator = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [results, setResults] = useState(null);  // Assurez-vous que c'est déclaré
  const [calculationError, setCalculationError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const calculationTimeoutRef = useRef(null);

  const calculate = useCallback(async () => {
    try {
      setIsCalculating(true);
      setCalculationError(null);

      // Log des données avant calcul
      console.log('Données pour le calcul:', {
        acte: formData.selectedAct,
        montant: formData.baseAmount,
        specifics: formData.specifics,
        debours: formData.debours,
        formalites: formData.formalities,
        documents: formData.documents,
        taxes: formData.taxes,
        options: formData.options
      });

      // Calcul via le service
      const results = await calculationService.calculateAll(formData);
      
      // Log détaillé des résultats
      console.log('Résultats détaillés:', {
        emoluments: {
          ht: results.sections?.emoluments?.montants?.ht,
          tva: results.sections?.emoluments?.montants?.tva,
          ttc: results.sections?.emoluments?.montants?.ttc
        },
        debours: {
          total: results.sections?.debours?.total,
          details: results.sections?.debours?.details
        },
        formalites: {
          ht: results.sections?.formalites?.montants?.ht,
          tva: results.sections?.formalites?.montants?.tva,
          ttc: results.sections?.formalites?.montants?.ttc
        },
        documents: {
          ht: results.sections?.documents?.montants?.ht,
          tva: results.sections?.documents?.montants?.tva,
          ttc: results.sections?.documents?.montants?.ttc
        },
        taxes: {
          total: results.sections?.taxes?.total,
          details: results.sections?.taxes?.details
        }
      });

      // Vérifications approfondies...
      if (!results || !results.total) {
        throw new Error("Le calcul n'a pas produit de résultats valides");
      }

      // Vérification des composants nuls
      if (results.total.ttc <= 0) {
        const composants = {
          emoluments: results.sections?.emoluments?.montants?.ttc || 0,
          debours: results.sections?.debours?.total || 0,
          formalites: results.sections?.formalites?.montants?.ttc || 0,
          documents: results.sections?.documents?.montants?.ttc || 0,
          taxes: results.sections?.taxes?.total || 0
        };
        
        console.log('Composants du total:', composants);
        
        if (Object.values(composants).every(v => v === 0)) {
          throw new Error("Tous les composants du calcul sont à zéro");
        }
      }

      // Vérification de la cohérence des montants
      Object.entries(results.sections).forEach(([section, data]) => {
        if (data?.montants) {
          const { ht = 0, tva = 0, ttc = 0 } = data.montants;
          if (ttc < ht || ttc < 0 || ht < 0 || tva < 0) {
            throw new Error(`Montants incohérents dans la section ${section}`);
          }
        }
      });

      setResults(results);
      setValidationErrors({});
      return results;

    } catch (error) {
      console.error('Erreur dans le calcul:', error);
      setCalculationError(error.message);
      setResults(null);
      throw error;
    } finally {
      setIsCalculating(false);
    }
  }, [formData]);

  // Autres méthodes utiles...
  
  return {
    formData,
    setFormData,
    results,
    calculationError,
    setCalculationError,
    isCalculating,
    validationErrors,
    setValidationErrors,
    calculate,
    // ... autres méthodes
  };
};