export class AbattementsService {
    // Constantes de calcul des abattements
    static TAUX = {
      IR: {  // Impôt sur le Revenu
        STANDARD: {
          ANNUEL: 0.06,    // 6% par an
          APRES_22: 0.04   // 4% après 22 ans
        },
        EXCEPTIONNEL: {    // Pour les ventes aux bailleurs sociaux
          ANNUEL: 0.10,    // 10% par an
          SEUIL: 10        // Exonération à partir de 10 ans
        }
      },
      PS: {  // Prélèvements Sociaux
        ANNUEL: 0.015,     // 1.5% par an
        APRES_22: 0.0175,  // 1.75% après 22 ans
        APRES_30: 0.09     // 9% pour la dernière année
      }
    };
  
    static SEUILS = {
      EXONERATION: {
        IR: 22, // années
        PS: 30  // années
      },
      MINIMUM: 0,
      MAXIMUM: 30
    };
  
    static REFERENCES = {
      IR: "CGI, art. 150 VC",
      PS: "Code de la sécurité sociale, art. L136-7"
    };
  
    /**
     * Calcule la durée de détention
     */
    static calculerDureeDetention(dateAcquisition, dateCalcul) {
      try {
        const debut = new Date(dateAcquisition);
        const fin = new Date(dateCalcul);
        
        // Vérification de la validité des dates
        if (isNaN(debut.getTime()) || isNaN(fin.getTime())) {
          throw new Error("Dates invalides");
        }
        
        if (debut > fin) {
          throw new Error("La date d'acquisition ne peut pas être postérieure à la date de calcul");
        }
  
        // Calcul de la durée en années
        let duree = fin.getFullYear() - debut.getFullYear();
        
        // Ajustement si nécessaire en fonction des mois et jours
        if (fin.getMonth() < debut.getMonth() || 
            (fin.getMonth() === debut.getMonth() && fin.getDate() < debut.getDate())) {
          duree--;
        }
  
        // Limitation aux seuils définis
        return Math.max(
          this.SEUILS.MINIMUM,
          Math.min(duree, this.SEUILS.MAXIMUM)
        );
  
      } catch (error) {
        console.error('Erreur dans le calcul de la durée de détention:', error);
        throw error;
      }
    }
  
    /**
     * Calcule les abattements pour durée de détention
     */
    static calculerAbattements(plusValueBrute, dureeDetention) {
      try {
        const tauxIR = this.calculerTauxAbattementIR(dureeDetention);
        const tauxPS = this.calculerTauxAbattementPS(dureeDetention);
  
        return {
          ir: plusValueBrute * tauxIR,
          ps: plusValueBrute * tauxPS,
          taux: {
            ir: tauxIR,
            ps: tauxPS
          },
          details: {
            dureeDetention,
            references: {
              ir: this.REFERENCES.IR,
              ps: this.REFERENCES.PS
            }
          }
        };
  
      } catch (error) {
        console.error('Erreur dans le calcul des abattements:', error);
        throw error;
      }
    }
  
    /**
     * Calcule le taux d'abattement IR
     */
    static calculerTauxAbattementIR(dureeDetention) {
      // Exonération totale après 22 ans
      if (dureeDetention >= this.SEUILS.EXONERATION.IR) {
        return 1;
      }
  
      // Pas d'abattement avant 5 ans
      if (dureeDetention <= 5) {
        return 0;
      }
  
      // Calcul de l'abattement standard
      let taux = Math.min(1, (dureeDetention - 5) * this.TAUX.IR.STANDARD.ANNUEL);
      
      // Majoration pour les années après la 22ème
      if (dureeDetention > 22) {
        taux += (dureeDetention - 22) * this.TAUX.IR.STANDARD.APRES_22;
      }
  
      return Math.min(1, taux);
    }
  
    /**
     * Calcule le taux d'abattement PS
     */
    static calculerTauxAbattementPS(dureeDetention) {
      // Exonération totale après 30 ans
      if (dureeDetention >= this.SEUILS.EXONERATION.PS) {
        return 1;
      }
  
      // Pas d'abattement avant 5 ans
      if (dureeDetention <= 5) {
        return 0;
      }
  
      // Calcul de l'abattement standard
      let taux = (dureeDetention - 5) * this.TAUX.PS.ANNUEL;
  
      // Majoration pour les années après la 22ème
      if (dureeDetention > 22) {
        taux += (dureeDetention - 22) * this.TAUX.PS.APRES_22;
      }
  
      // Dernière année (30ème)
      if (dureeDetention === 29) {
        taux += this.TAUX.PS.APRES_30;
      }
  
      return Math.min(1, taux);
    }
  
    /**
     * Calcule l'abattement exceptionnel si applicable
     */
    static calculerAbattementExceptionnel(plusValue, formData) {
      if (!formData.specifics?.abattementExceptionnel) {
        return 0;
      }
  
      // Vérification des conditions d'application
      if (!this.verifierConditionsAbattementExceptionnel(formData)) {
        return 0;
      }
  
      const dureeDetention = this.calculerDureeDetention(
        formData.dateAcquisition,
        formData.dateVente
      );
  
      // Application de l'abattement exceptionnel
      if (dureeDetention >= this.TAUX.IR.EXCEPTIONNEL.SEUIL) {
        return plusValue * this.TAUX.IR.EXCEPTIONNEL.ANNUEL;
      }
  
      return 0;
    }
  
    /**
     * Vérifie les conditions d'application de l'abattement exceptionnel
     */
    static verifierConditionsAbattementExceptionnel(formData) {
      // Vérification des conditions spécifiques pour l'abattement exceptionnel
      // (vente à un bailleur social, engagement de construction, etc.)
      return formData.specifics?.venteHLM || formData.specifics?.engagementConstruction;
    }
  }