// services/calculationService/documents.js

export class DocumentsService {
  // Constantes réglementaires
  static TARIFS = {
    COPIE_EXECUTOIRE: {
      montant: 4.00,
      description: "Copie revêtue de la formule exécutoire",
      reference: "Art. A. 444-186"
    },
    COPIE_AUTHENTIQUE: {
      montant: 4.00,
      description: "Copie certifiée conforme à la minute",
      reference: "Art. A. 444-186"
    },
    COPIE_HYPOTHECAIRE: {
      montant: 4.00,
      description: "Copie pour la publicité foncière",
      reference: "Art. A. 444-186"
    },
    FRAIS_ROLE: {
      montant: 2.00,
      description: "Frais d'établissement par rôle d'écriture",
      reference: "Art. A. 444-186"
    }
  };

  // Nombre de pages par défaut selon le type d'acte
  static PAGES_PAR_DEFAUT = {
    'vente-gre-a-gre': {
      pages: 25,
      commentaire: "Vente classique avec annexes standards"
    },
    'vente-viager': {
      pages: 30,
      commentaire: "Vente en viager avec clauses spécifiques"
    },
    'vente-vefa': {
      pages: 35,
      commentaire: "VEFA avec états descriptifs détaillés"
    },
    'pret-hypothecaire': {
      pages: 20,
      commentaire: "Prêt hypothécaire standard"
    },
    'mainlevee': {
      pages: 8,
      commentaire: "Mainlevée simple"
    },
    'donation': {
      pages: 20,
      commentaire: "Donation avec conditions"
    },
    'contrat-mariage': {
      pages: 15,
      commentaire: "Contrat de mariage standard"
    },
    'attestation-propriete': {
      pages: 12,
      commentaire: "Attestation simple"
    },
    DEFAULT: {
      pages: 15,
      commentaire: "Acte standard"
    }
  };

  // Nombre de copies par défaut selon le type d'acte
  static COPIES_PAR_DEFAUT = {
    'vente-gre-a-gre': {
      executoire: 0,
      authentique: 2,  // Vendeur et acquéreur
      hypothecaire: 1  // Publication
    },
    'pret-hypothecaire': {
      executoire: 1,   // Pour la banque
      authentique: 2,  // Banque et emprunteur
      hypothecaire: 1  // Publication
    },
    'donation': {
      executoire: 0,
      authentique: 3,  // Donateur et donataires
      hypothecaire: 1  // Publication
    },
    DEFAULT: {
      executoire: 0,
      authentique: 1,
      hypothecaire: 0
    }
  };

  static TVA = 0.20;

  /**
   * Calcule les frais de documents
   */
  static calculerDocuments(formData) {
    try {
      const { documents = {}, selectedAct } = formData;

      // Initialisation avec valeurs par défaut si nécessaire
      const pageCount = documents.pageCount || this.getPagesParDefaut(selectedAct);
      const copies = this.getCopiesParDefaut(selectedAct, documents);

      // 1. Calcul des frais de rôle
      const fraisRole = this.calculerFraisRole(pageCount);

      // 2. Calcul des copies exécutoires
      const copiesExecutoires = this.calculerCopies(
        pageCount,
        copies.executoire,
        this.TARIFS.COPIE_EXECUTOIRE
      );

      // 3. Calcul des copies authentiques
      const copiesAuthentiques = this.calculerCopies(
        pageCount,
        copies.authentique,
        this.TARIFS.COPIE_AUTHENTIQUE
      );

      // 4. Calcul des copies hypothécaires
      const copiesHypothecaires = this.calculerCopies(
        pageCount,
        copies.hypothecaire,
        this.TARIFS.COPIE_HYPOTHECAIRE
      );

      // Total HT
      const totalHT = 
        fraisRole.montant +
        copiesExecutoires.montant +
        copiesAuthentiques.montant +
        copiesHypothecaires.montant;

      // Calcul TVA
      const tva = totalHT * this.TVA;

      return {
        montants: {
          ht: totalHT,
          tva: tva,
          ttc: totalHT + tva
        },
        details: {
          fraisRole,
          copiesExecutoires,
          copiesAuthentiques,
          copiesHypothecaires,
          pageCount,
          typesActe: selectedAct?.id || 'inconnu'
        },
        validation: {
          reference: "Art. A. 444-186",
          dateCalcul: new Date().toISOString()
        }
      };

    } catch (error) {
      console.error('Erreur dans le calcul des documents:', error);
      return this.getEmptyResult(error.message);
    }
  }

  /**
   * Calcule les frais de rôle
   */
  static calculerFraisRole(pageCount) {
    return {
      type: 'FRAIS_ROLE',
      pageCount,
      prixUnitaire: this.TARIFS.FRAIS_ROLE.montant,
      montant: pageCount * this.TARIFS.FRAIS_ROLE.montant,
      reference: this.TARIFS.FRAIS_ROLE.reference
    };
  }

  /**
   * Calcule le coût des copies
   */
  static calculerCopies(pageCount, nombreCopies, tarif) {
    return {
      type: tarif.description,
      pageCount,
      nombreCopies,
      prixUnitaire: tarif.montant,
      montant: pageCount * nombreCopies * tarif.montant,
      reference: tarif.reference
    };
  }

  /**
   * Détermine le nombre de pages par défaut
   */
  static getPagesParDefaut(selectedAct) {
    if (!selectedAct) return this.PAGES_PAR_DEFAUT.DEFAULT.pages;
    return (this.PAGES_PAR_DEFAUT[selectedAct.id] || this.PAGES_PAR_DEFAUT.DEFAULT).pages;
  }

  /**
   * Détermine le nombre de copies par défaut
   */
  static getCopiesParDefaut(selectedAct, documents = {}) {
    const defaut = selectedAct?.id
      ? (this.COPIES_PAR_DEFAUT[selectedAct.id] || this.COPIES_PAR_DEFAUT.DEFAULT)
      : this.COPIES_PAR_DEFAUT.DEFAULT;

    return {
      executoire: documents.executoryCopy ?? defaut.executoire,
      authentique: documents.authenticCopies ?? defaut.authentique,
      hypothecaire: documents.mortgageCopies ?? defaut.hypothecaire
    };
  }

  /**
   * Vérifie si des copies sont nécessaires selon le type d'acte
   */
  static necessiteCopies(selectedAct) {
    const result = {
      executoire: false,
      authentique: true, // Toujours au moins une copie authentique
      hypothecaire: false
    };

    if (!selectedAct) return result;

    // Copie exécutoire pour les prêts
    if (selectedAct.id.includes('pret')) {
      result.executoire = true;
    }

    // Copie hypothécaire pour les actes publiés
    if (['vente-gre-a-gre', 'pret-hypothecaire', 'donation'].includes(selectedAct.id)) {
      result.hypothecaire = true;
    }

    return result;
  }

  /**
   * Retourne un résultat vide avec message d'erreur
   */
  static getEmptyResult(error = '') {
    return {
      montants: {
        ht: 0,
        tva: 0,
        ttc: 0
      },
      details: {
        error
      }
    };
  }

  /**
   * Valide les données d'entrée
   */
  static validateDocuments(documents = {}) {
    const errors = [];

    // Le nombre de pages ne peut pas être négatif
    if (documents.pageCount < 0) {
      errors.push("Le nombre de pages ne peut pas être négatif");
    }

    // Validation des nombres de copies
    if (documents.executoryCopy < 0) {
      errors.push("Le nombre de copies exécutoires ne peut pas être négatif");
    }
    if (documents.authenticCopies < 0) {
      errors.push("Le nombre de copies authentiques ne peut pas être négatif");
    }
    if (documents.mortgageCopies < 0) {
      errors.push("Le nombre de copies hypothécaires ne peut pas être négatif");
    }

    // Vérification de cohérence
    if (documents.pageCount === 0 && 
        (documents.executoryCopy > 0 || 
         documents.authenticCopies > 0 || 
         documents.mortgageCopies > 0)) {
      errors.push("Impossible de faire des copies avec 0 pages");
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  /**
   * Estime le nombre de pages selon le contenu
   */
  static estimerNombrePages(selectedAct, specifics = {}) {
    let pages = this.getPagesParDefaut(selectedAct);

    // Ajustements selon les spécificités
    if (specifics.isViager) {
      pages += 5; // Clauses viager supplémentaires
    }
    if (specifics.isHLM) {
      pages += 3; // Clauses HLM supplémentaires
    }
    if (specifics.nombreParties > 2) {
      pages += (specifics.nombreParties - 2) * 2; // Pages supplémentaires par partie
    }

    return pages;
  }
}

export default DocumentsService;