import React, { useState } from 'react';
import {
  File,
  Upload,
  Download,
  Trash2,
  Search,
  Filter,
  AlertCircle,
  FileText,
  FolderOpen
} from 'lucide-react';

const DOCUMENT_CATEGORIES = {
  TAXATION: "Taxation d'actes",
  EXPERTISE: "Expertise immobilière",
  JUSTIFICATIFS: "Justificatifs",
  RAPPORTS: "Rapports d'évaluation",
  ACTES: "Actes notariés",
  CORRESPONDANCE: "Correspondance"
};

const DOCUMENT_TYPES = {
  TITRE_PROPRIETE: "Titre de propriété",
  TAXATION_ACTE: "Calcul de taxation",
  RAPPORT_EVALUATION: "Rapport d'évaluation",
  JUSTIFICATIF_IDENTITE: "Justificatif d'identité",
  PROCURATION: "Procuration",
  ATTESTATION: "Attestation",
  CORRESPONDANCE: "Correspondance",
  AUTRE: "Autre"
};

const DocumentsPage = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [loading, setLoading] = useState(false);
  const [uploadType, setUploadType] = useState('');

  // Gestion du dépôt de documents
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Validation du type de fichier
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];

      if (!allowedTypes.includes(file.type)) {
        alert('Type de fichier non autorisé. Veuillez déposer un document PDF, Word ou une image.');
        return;
      }

      // Validation de la taille (max 20MB)
      const maxSize = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSize) {
        alert('Le fichier est trop volumineux. Taille maximum : 20MB');
        return;
      }

      // Simulation d'upload
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simuler le délai réseau

      const newDocument = {
        id: Date.now(),
        name: file.name,
        type: uploadType || DOCUMENT_TYPES.AUTRE,
        category: selectedCategory,
        size: file.size,
        date: new Date().toISOString(),
        status: 'uploaded',
        metadata: {
          fileType: file.type,
          lastModified: new Date(file.lastModified).toISOString()
        }
      };

      setDocuments(prev => [...prev, newDocument]);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      alert('Erreur lors du téléchargement du document');
      setLoading(false);
    }
  };

  const handleDelete = (docId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce document ?')) {
      setDocuments(prev => prev.filter(doc => doc.id !== docId));
    }
  };

  // Filtrage des documents
  const filteredDocuments = documents.filter(doc => {
    const matchesSearch = doc.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || doc.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="space-y-6">
        {/* En-tête */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Gestion documentaire</h2>
          <p className="mt-1 text-sm text-gray-500">
            Gérez vos documents liés aux calculs de taxation et expertises immobilières
          </p>
        </div>

        {/* Zone d'upload avec sélection du type */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Type de document
            </label>
            <select
              value={uploadType}
              onChange={(e) => setUploadType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Sélectionnez un type...</option>
              {Object.entries(DOCUMENT_TYPES).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
              ))}
            </select>
          </div>

          <div className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center">
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer"
            >
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <p className="mt-2 text-sm text-gray-600">
                Cliquez pour uploader ou glissez-déposez vos fichiers ici
              </p>
              <p className="text-xs text-gray-500 mt-1">
                PDF, Word ou images jusqu'à 20MB
              </p>
            </label>
          </div>
        </div>

        {/* Barre de recherche et filtres */}
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex-1 min-w-[200px]">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher un document..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 
              focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="all">Toutes les catégories</option>
            {Object.entries(DOCUMENT_CATEGORIES).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </div>

        {/* Liste des documents */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          {loading ? (
            <div className="flex justify-center items-center h-48">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
          ) : filteredDocuments.length > 0 ? (
            <div className="divide-y divide-gray-200">
              {filteredDocuments.map((doc) => (
                <div key={doc.id} className="p-4 hover:bg-gray-50 flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="bg-gray-100 p-2 rounded-lg">
                      <FileText className="h-6 w-6 text-blue-600" />
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{doc.name}</p>
                      <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <span>{doc.type}</span>
                        <span>•</span>
                        <span>{new Date(doc.date).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => window.open(doc.url)}
                      className="p-2 text-gray-400 hover:text-blue-500"
                      title="Télécharger"
                    >
                      <Download className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(doc.id)}
                      className="p-2 text-gray-400 hover:text-red-500"
                      title="Supprimer"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-48 text-center">
              <FolderOpen className="h-12 w-12 text-gray-400 mb-3" />
              <h3 className="text-sm font-medium text-gray-900">Aucun document</h3>
              <p className="text-sm text-gray-500 mt-1">
                Déposez vos premiers documents pour commencer
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsPage;
