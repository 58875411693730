// src/constants/immobilier.js

export const PRIX_M2_PAR_ZONE = {
    PARIS: {
      LUXE: { min: 12000, max: 25000 },
      SUPERIEUR: { min: 8000, max: 12000 },
      COURANT: { min: 6000, max: 8000 },
    },
    LYON: {
      LUXE: { min: 6000, max: 8000 },
      SUPERIEUR: { min: 4000, max: 6000 },
      COURANT: { min: 2500, max: 4000 },
    },
    // Ajoutez d'autres villes et zones...
  };
  
  export const STANDINGS = {
    LUXE: 'luxe',
    SUPERIEUR: 'superieur',
    COURANT: 'courant',
  };
  
  export const VALEURS_LOCATIVES = {
    [STANDINGS.LUXE]: 30,
    [STANDINGS.SUPERIEUR]: 20,
    [STANDINGS.COURANT]: 15,
  };