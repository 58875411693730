// actes/successions.js
export const ACTES_SUCCESSIONS = [
    {
      id: "notoriete-succession",
      label: "Acte de notoriété (succession)",
      reference: "Art. A444-60",
      type: "FIXE",
      montant: 57.69
    },
    {
      id: "attestation-propriete",
      label: "Attestation de propriété immobilière",
      reference: "Art. A444-168",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.578 },
        { jusqu_a: 17000, taux: 0.868 },
        { jusqu_a: 60000, taux: 0.592 },
        { au_dela: true, taux: 0.434 }
      ]
    },
    {
      id: "certificat-propriete",
      label: "Certificat de propriété",
      reference: "Art. A444-63",
      type: "FIXE",
      montant: 115.39
    },
    {
      id: "declaration-succession",
      label: "Déclaration de succession",
      reference: "Art. A444-59",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.578 },
        { jusqu_a: 17000, taux: 0.868 },
        { jusqu_a: 60000, taux: 0.592 },
        { au_dela: true, taux: 0.434 }
      ]
    },
    {
      id: "inventaire",
      label: "Inventaire successoral",
      reference: "Art. A444-61",
      type: "VACATIONS",
      tarif_horaire: 219.25
    },
    {
      id: "partage-succession",
      label: "Partage successoral",
      reference: "Art. A444-65",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 4.575 },
        { jusqu_a: 17000, taux: 1.890 },
        { jusqu_a: 60000, taux: 1.290 },
        { au_dela: true, taux: 0.945 }
      ]
    },
    {
      id: "renonciation-succession",
      label: "Renonciation à succession",
      reference: "Art. A444-66",
      type: "FIXE",
      montant: 76.92
    }
  ];