import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { 
  User, Settings, FileText, Calculator, 
  LogOut, AlertCircle, Activity, Clock,
  ChevronRight, Shield, Scale, LineChart, 
  TrendingUp, FolderOpen, Building2 
} from 'lucide-react';

// Composant pour les cartes de statistiques
const DashboardCard = ({ title, value = 0, icon: Icon, color = "blue" }) => {
  const getBgColor = () => {
    switch (color) {
      case "green": return "bg-green-100";
      case "yellow": return "bg-yellow-100";
      case "red": return "bg-red-100";
      default: return "bg-blue-100";
    }
  };

  const getTextColor = () => {
    switch (color) {
      case "green": return "text-green-600";
      case "yellow": return "text-yellow-600";
      case "red": return "text-red-600";
      default: return "text-blue-600";
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow duration-300">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500 mb-1">{title}</p>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
        <div className={`${getBgColor()} p-3 rounded-full`}>
          <Icon className={`w-6 h-6 ${getTextColor()}`} />
        </div>
      </div>
    </div>
  );
};

// Composant pour les cartes de calcul principales
const CalculationCard = ({ title, description, icon: Icon, onClick }) => (
  <div
    onClick={onClick}
    className="bg-white p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-xl 
      transition-all duration-300 transform hover:scale-105 cursor-pointer"
  >
    <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-blue-600" />
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <div className="flex items-center text-blue-600">
      <span className="mr-2">Commencer</span>
      <ChevronRight className="w-4 h-4" />
    </div>
  </div>
);

// Composant pour les actions rapides secondaires
const QuickAction = ({ title, description, icon: Icon, onClick, disabled = false }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`flex items-start space-x-4 p-4 rounded-lg border border-gray-200 
      transition-all duration-300 w-full text-left
      ${disabled 
        ? 'opacity-50 cursor-not-allowed bg-gray-50' 
        : 'hover:bg-gray-50 hover:border-blue-300 hover:shadow-md'}`}
  >
    <div className="bg-blue-100 p-2 rounded-lg">
      <Icon className="w-5 h-5 text-blue-600" />
    </div>
    <div className="flex-1">
      <h3 className="font-medium text-gray-900">{title}</h3>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
    <ChevronRight className="w-5 h-5 text-gray-400 self-center" />
  </button>
);

// Composant pour l'activité récente
const ActivityItem = ({ activity }) => (
  <div className="p-4 hover:bg-gray-50 transition-colors duration-200">
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Activity className="h-5 w-5 text-gray-400 mr-3" />
        <div>
          <p className="text-sm font-medium text-gray-900">
            {activity.description}
          </p>
          <p className="text-sm text-gray-500">
            {activity.time}
          </p>
        </div>
      </div>
      <button className="text-sm text-blue-600 hover:text-blue-800 transition-colors">
        Voir les détails
      </button>
    </div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [stats, setStats] = useState({
    calculationsToday: 0,
    activeProjects: 0,
    pendingTasks: 0
  });
  const [recentActivities, setRecentActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Chargement des données
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setStats({
          calculationsToday: 0,
          activeProjects: 0,
          pendingTasks: 0
        });
        setRecentActivities([]);
      } catch (err) {
        console.error("Erreur lors du chargement des données:", err);
        setError("Impossible de charger les données du tableau de bord");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      setError('Erreur lors de la déconnexion. Veuillez réessayer.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      {/* En-tête */}
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Tableau de bord</h1>
          <p className="mt-1 text-sm text-gray-500">
            Bienvenue, {user?.name || 'Utilisateur'}
          </p>
        </div>
        <button
          onClick={handleLogout}
          className="inline-flex items-center px-4 py-2 border border-transparent 
            rounded-md shadow-sm text-sm font-medium text-white bg-red-600 
            hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
            focus:ring-red-500 transition-colors duration-200"
        >
          <LogOut className="w-4 h-4 mr-2" />
          Déconnexion
        </button>
      </div>

      {/* Affichage des erreurs */}
      {error && (
        <div className="mb-8 bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <p className="ml-3 text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <DashboardCard
          title="Calculs aujourd'hui"
          value={stats.calculationsToday}
          icon={Calculator}
          color="blue"
        />
        <DashboardCard
          title="Projets actifs"
          value={stats.activeProjects}
          icon={Activity}
          color="green"
        />
        <DashboardCard
          title="Tâches en attente"
          value={stats.pendingTasks}
          icon={Clock}
          color="yellow"
        />
      </div>

      {/* Calculateurs principaux */}
      <section className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Calculateurs</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <CalculationCard
            title="Calcul de taxation"
            description="Calculez les émoluments, débours et taxes pour tout type d'acte notarié"
            icon={Calculator}
            onClick={() => navigate('/taxation')}
          />
          <CalculationCard
            title="Calcul de plus-value"
            description="Évaluez la plus-value et les taxes applicables pour une cession immobilière"
            icon={TrendingUp}
            onClick={() => navigate('/plusvalue')}
          />
          <CalculationCard
            title="Évaluation immobilière"
            description="Estimez la valeur d'un bien immobilier selon les critères du marché"
            icon={Building2}
            onClick={() => navigate('/evaluation-immobiliere')}
          />
        </div>
      </section>

      {/* Actions rapides */}
      <section className="mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Autres actions</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <QuickAction
            title="Documents"
            description="Gérer vos documents et rapports"
            icon={FolderOpen}
            onClick={() => navigate('/documents')}
          />
          <QuickAction
            title="Mon profil"
            description="Modifier vos informations"
            icon={User}
            onClick={() => navigate('/profile')}
          />
          <QuickAction
            title="Paramètres"
            description="Configurer l'application"
            icon={Settings}
            onClick={() => navigate('/settings')}
          />
        </div>
      </section>

      {/* Activité récente */}
      <section>
        <h2 className="text-lg font-medium text-gray-900 mb-4">Activité récente</h2>
        <div className="bg-white shadow rounded-lg">
          {recentActivities.length > 0 ? (
            <div className="divide-y divide-gray-200">
              {recentActivities.map((activity, index) => (
                <ActivityItem key={index} activity={activity} />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <Activity className="mx-auto h-12 w-12 text-gray-400 mb-3" />
              <h3 className="text-sm font-medium text-gray-900">Aucune activité récente</h3>
              <p className="text-sm text-gray-500 mt-2">
                Vos activités récentes apparaîtront ici
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
