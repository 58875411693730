import React from 'react';
import { AlertCircle } from 'lucide-react';

export const CalculatorErrors = ({
  calculationError,
  validationErrors
}) => {
  if (!calculationError && Object.keys(validationErrors).length === 0) {
    return null;
  }

  return (
    <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
      <div className="flex">
        <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
        <div className="flex-1">
          {calculationError && (
            <p className="text-red-700 font-medium">{calculationError}</p>
          )}
          {Object.entries(validationErrors).map(([tab, error]) => (
            <p key={tab} className="text-red-600 text-sm mt-1">
              {error}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};