// components/tabs/DeboursTab.jsx
import React from 'react';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";

// Constantes locales
const DEBOURS = {
  CSI: {
    TAUX: 0.001,
    MINIMUM: 15,
    types: {
      PROPORTIONNEL: 'proportional',
      FIXE: 'fixed'
    }
  },
  ETAT_HYPOTHECAIRE: {
    HORS_FORMALITE: 12,
    SUR_FORMALITE: 30
  },
  EXTRAIT_CADASTRAL: {
    PRIX_UNITAIRE: 12
  },
  URBANISME: {
    CERTIFICAT: 40,
    ALIGNEMENT: 25
  }
};

const DeboursTab = ({ formData, setFormData }) => {
  // Initialisation des valeurs par défaut
  React.useEffect(() => {
    if (!formData.debours) {
      setFormData(prev => ({
        ...prev,
        debours: {
          csi: { type: DEBOURS.CSI.types.PROPORTIONNEL },
          mortgageStatements: { regularCount: 1, urgentCount: 0 },
          cadastral: { count: 1 },
          demandeCU: false,
          demandeAlignement: false
        }
      }));
    }
  }, []);

  const handleDeboursChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      debours: {
        ...prev.debours,
        [field]: value
      }
    }));
  };

  // Calculer le montant de la CSI
  const calculateCSI = () => {
    const { baseAmount, debours = {} } = formData;
    if (!baseAmount) return DEBOURS.CSI.MINIMUM;
    
    if (debours.csi?.type === DEBOURS.CSI.types.PROPORTIONNEL) {
      return Math.max(baseAmount * DEBOURS.CSI.TAUX, DEBOURS.CSI.MINIMUM);
    }
    
    return DEBOURS.CSI.MINIMUM;
  };

  return (
    <div className="space-y-6">
      {/* Contribution de Sécurité Immobilière */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Contribution de Sécurité Immobilière (CSI)
        </h3>
        <div className="space-y-4">
          <div>
            <label className="inline-flex items-center">
              <input
                type="radio"
                checked={formData.debours?.csi?.type === DEBOURS.CSI.types.PROPORTIONNEL}
                onChange={() => handleDeboursChange('csi', {
                  ...formData.debours?.csi,
                  type: DEBOURS.CSI.types.PROPORTIONNEL
                })}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2">Proportionnelle (0,1% avec minimum 15€)</span>
              <Tooltip content="Taux de 0,1% appliqué sur la valeur du bien">
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </label>
          </div>

          <div>
            <label className="inline-flex items-center">
              <input
                type="radio"
                checked={formData.debours?.csi?.type === DEBOURS.CSI.types.FIXE}
                onChange={() => handleDeboursChange('csi', {
                  ...formData.debours?.csi,
                  type: DEBOURS.CSI.types.FIXE
                })}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2">Forfaitaire (15€)</span>
              <Tooltip content="Montant fixe pour certains types d'actes">
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </label>
          </div>

          <div className="mt-2 text-sm text-gray-500">
            Montant calculé: {calculateCSI().toFixed(2)} €
          </div>
        </div>
      </div>

      {/* États hypothécaires */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">États hypothécaires</h3>
          <Tooltip content="Documents attestant de la situation hypothécaire du bien">
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <HelpCircle className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-700 mb-1">
              Nombre d'états hors formalité ({DEBOURS.ETAT_HYPOTHECAIRE.HORS_FORMALITE}€)
            </label>
            <input
              type="number"
              value={formData.debours?.mortgageStatements?.regularCount || 0}
              onChange={(e) => handleDeboursChange('mortgageStatements', {
                ...formData.debours?.mortgageStatements,
                regularCount: parseInt(e.target.value) || 0
              })}
              className="w-24 p-2 border rounded-lg"
              min="0"
            />
          </div>

          <div>
            <label className="block text-sm text-gray-700 mb-1">
              Nombre d'états urgents ({DEBOURS.ETAT_HYPOTHECAIRE.SUR_FORMALITE}€)
            </label>
            <input
              type="number"
              value={formData.debours?.mortgageStatements?.urgentCount || 0}
              onChange={(e) => handleDeboursChange('mortgageStatements', {
                ...formData.debours?.mortgageStatements,
                urgentCount: parseInt(e.target.value) || 0
              })}
              className="w-24 p-2 border rounded-lg"
              min="0"
            />
          </div>
        </div>
      </div>

      {/* Extraits cadastraux */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Extraits cadastraux</h3>
          <Tooltip content="Documents obligatoires pour identifier le bien">
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <HelpCircle className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
        <div>
          <label className="block text-sm text-gray-700 mb-1">
            Nombre d'extraits ({DEBOURS.EXTRAIT_CADASTRAL.PRIX_UNITAIRE}€/unité)
          </label>
          <input
            type="number"
            value={formData.debours?.cadastral?.count || 0}
            onChange={(e) => handleDeboursChange('cadastral', {
              ...formData.debours?.cadastral,
              count: parseInt(e.target.value) || 0
            })}
            className="w-24 p-2 border rounded-lg"
            min="0"
          />
        </div>
      </div>

      {/* Urbanisme */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Documents d'urbanisme</h3>
          <Tooltip content="Documents relatifs aux règles d'urbanisme applicables">
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <HelpCircle className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
        <div className="space-y-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={formData.debours?.demandeCU || false}
              onChange={(e) => handleDeboursChange('demandeCU', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">
              Certificat d'urbanisme ({DEBOURS.URBANISME.CERTIFICAT}€)
            </span>
          </label>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={formData.debours?.demandeAlignement || false}
              onChange={(e) => handleDeboursChange('demandeAlignement', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="ml-2">
              Demande d'alignement ({DEBOURS.URBANISME.ALIGNEMENT}€)
            </span>
          </label>
        </div>
      </div>

      {/* Note explicative */}
      <div className="bg-blue-50 p-4 rounded-lg">
        <p className="text-sm text-blue-700">
          Les débours correspondent aux sommes acquittées pour le compte du client 
          auprès des différentes administrations. Ces montants sont fixés par la 
          réglementation et ne sont pas soumis à TVA.
        </p>
      </div>
    </div>
  );
};

export default DeboursTab;