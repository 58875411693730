import { Scale, FileText, Shield, File, DollarSign } from 'lucide-react';
import EmolumentsTab from '../tabs/EmolumentsTab';
import DeboursTab from '../tabs/DeboursTab';
import FormalitiesTab from '../tabs/FormalitiesTab';
import DocumentsTab from '../tabs/DocumentsTab';
import TaxesTab from '../tabs/TaxesTab';
import { HELP_TEXTS } from '@/constants/calculator';

export const TABS = [
  {
    id: 'emoluments',
    label: 'Émoluments',
    component: EmolumentsTab,
    icon: Scale,
    help: HELP_TEXTS.CATEGORIES.EMOLUMENTS,
    validateIcon: Shield
  },
  {
    id: 'debours',
    label: 'Débours',
    component: DeboursTab,
    icon: DollarSign,
    help: HELP_TEXTS.CATEGORIES.DEBOURS,
    validateIcon: Shield
  },
  {
    id: 'formalites',
    label: 'Formalités',
    component: FormalitiesTab,
    icon: FileText,
    help: HELP_TEXTS.CATEGORIES.FORMALITES,
    validateIcon: Shield
  },
  {
    id: 'documents',
    label: 'Documents',
    component: DocumentsTab,
    icon: File,
    help: HELP_TEXTS.CATEGORIES.DOCUMENTS,
    validateIcon: Shield
  },
  {
    id: 'taxes',
    label: 'Taxes',
    component: TaxesTab,
    icon: DollarSign,
    help: HELP_TEXTS.CATEGORIES.TAXES,
    validateIcon: Shield
  }
];

export const TAB_VALIDATIONS = {
  emoluments: (data) => {
    if (!data.selectedAct) return false;
    if (data.selectedAct.type === 'PROPORTIONNEL' && !data.baseAmount) return false;
    return true;
  },
  debours: (data) => {
    // Ajoutez ici les conditions de validation pour l'onglet Débours
    return true;
  },
  formalites: (data) => {
    // Ajoutez ici les conditions de validation pour l'onglet Formalités
    return true;
  },
  documents: (data) => {
    // Ajoutez ici les conditions de validation pour l'onglet Documents
    return true;
  },
  taxes: (data) => {
    // Ajoutez ici les conditions de validation pour l'onglet Taxes
    return true;
  }
};

export const initialFormState = {
  selectedCategory: '',
  selectedAct: null,
  baseAmount: '',
  specifics: {
    isHLM: false,
    isNeuf: false,
    viagerDetails: null,
    hasPret: false,
    hasHypothèque: false,
    nombreLots: 1,
    nombreParties: 2,
    typeLocal: 'HABITATION',
    zonage: 'NORMAL'
  },
  debours: {
    csi: { type: 'proportional', isUrgent: false },
    mortgageStatements: { regularCount: 0, urgentCount: 0 },
    cadastral: { count: 0 },
    demandeCU: false,
    demandeAlignement: false
  },
  formalities: {
    base: true,
    teleactes: true,
    registeredMails: 1,
    prealables: true,
    posterieures: true
  },
  documents: {
    pageCount: 10,
    executoryCopy: 0,
    authenticCopies: 1,
    mortgageCopies: 0
  },
  taxes: {
    type: 'old',
    hasVAT: false,
    marginBased: false,
    margin: 0,
    hasDepartmentalTax: true,
    hasMunicipalTax: true
  },
  options: {
    discount: 0
  }
};