import React from 'react';
import { Calculator as CalculatorIcon, RefreshCw, Save } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";

export const CalculatorActions = ({
  onCalculate,
  onSave,
  isCalculating,
  isSaving,
  hasValidationErrors,
  hasResults
}) => (
  <div className="flex space-x-4">
    <button
      onClick={onCalculate}
      disabled={isCalculating || hasValidationErrors}
      className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-lg 
        hover:bg-blue-700 transition-colors flex items-center 
        justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isCalculating ? (
        <>
          <RefreshCw className="w-5 h-5 animate-spin" />
          Calcul en cours...
        </>
      ) : (
        <>
          <CalculatorIcon className="w-5 h-5" />
          Calculer les frais
        </>
      )}
    </button>

    {hasResults && (
      <button
        onClick={onSave}
        disabled={isSaving}
        className="bg-green-600 text-white py-3 px-4 rounded-lg 
          hover:bg-green-700 transition-colors flex items-center 
          justify-center disabled:opacity-50 disabled:cursor-not-allowed
          group relative"
      >
        {isSaving ? (
          <RefreshCw className="w-5 h-5 animate-spin" />
        ) : (
          <Save className="w-5 h-5" />
        )}
        <Tooltip content="Sauvegarder le calcul">
          <span className="sr-only">Sauvegarder</span>
        </Tooltip>
      </button>  
    )}
  </div>
);