import React from 'react';
import { Tab } from "@headlessui/react";
import { Card, CardContent } from "@/components/ui/card";
import { TABS } from '../config';

export const CalculatorForm = ({
  activeTab,
  formData,
  setFormData,
  validationErrors,
  setValidationErrors,
  onCategoryChange,
  onActChange,
  onBaseAmountChange
}) => (
  <div className="space-y-6">
    <Card>
      <CardContent className="p-6">
        {TABS.map((tab, idx) => (
          <Tab.Panel
            key={tab.id}
            className={activeTab === idx ? 'block' : 'hidden'}
          >
            {React.createElement(tab.component, {
              formData,
              setFormData,
              onCategoryChange,
              onActChange,
              onBaseAmountChange,
              error: validationErrors[tab.id],
              setError: (error) => setValidationErrors(prev => ({
                ...prev,
                [tab.id]: error
              }))
            })}
          </Tab.Panel>
        ))}
      </CardContent>
    </Card>
  </div>
);