// Base constants for calculator
export const CALCUL_CONSTANTS = {
    TVA: 0.20,
    MINIMUM_PERCEPTION: 83.33,
    MAJORATION_MAX: 0.30,
    CSI_RATE: 0.001,
    CSI_MIN: 15,
    FORMALITY_BASE: 130,
    TELEACTES: 50,
    REGISTERED_MAIL: 7.08,
    PAGE_COST: 4
  };
  
  export const ROUNDING = {
    EMOLUMENTS: 2,
    TVA: 2,
    TOTAL: 2,
    RULES: {
      STANDARD: "ROUND",      // Arrondi standard
      TVA: "ROUND_UP",        // Arrondi supérieur pour TVA
      DROITS: "ROUND_DOWN"    // Arrondi inférieur pour les droits
    }
  };
  
  export const VALIDATION_RULES = {
    MONTANT_MINIMUM: 0,
    REMISE_MAX: 40,  // 40%
    AGE_VIAGER: {
      MIN: 0,
      MAX: 120
    },
    DUREE_BAIL: {
      MIN: 1,
      MAX: 99
    },
    TAUX: {
      MIN: 0,
      MAX: 100
    },
    TVA: {
      SEUILS: {
        ANCIEN: 5,  // 5 ans pour distinction neuf/ancien
        RENOVATION: {
          ELEMENTS_DETERMINANTS: 0.5,  // 50% pour considérer comme neuf
          DELAI_ACHEVEMENT: 5  // 5 ans après achèvement des travaux
        }
      }
    },
    TERRAIN: {
      CRITERES_CONSTRUCTIBLE: [
        "PLU_POSITIF",
        "CARTE_COMMUNALE",
        "RNU_CONSTRUCTIBLE"
      ]
    }
  };
  
  export const ERROR_CODES = {
    INVALID_AMOUNT: {
      code: 'INVALID_AMOUNT',
      message: 'Le montant saisi est invalide',
      details: 'Le montant doit être un nombre positif'
    },
    INVALID_REMISE: {
      code: 'INVALID_REMISE',
      message: 'La remise saisie est invalide',
      details: 'La remise doit être comprise entre 0 et le maximum autorisé'
    },
    MISSING_REQUIRED: {
      code: 'MISSING_REQUIRED',
      message: 'Données requises manquantes',
      details: 'Veuillez remplir tous les champs obligatoires'
    },
    INVALID_AGE: {
      code: 'INVALID_AGE',
      message: 'L\'âge saisi est invalide',
      details: 'L\'âge doit être compris entre 0 et 120 ans'
    },
    INVALID_DURATION: {
      code: 'INVALID_DURATION',
      message: 'La durée saisie est invalide',
      details: 'La durée doit être comprise entre 1 et 99 ans'
    },
    CALCULATION_ERROR: {
      code: 'CALCULATION_ERROR',
      message: 'Erreur dans le calcul',
      details: 'Une erreur est survenue lors du calcul'
    }
  };