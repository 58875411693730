import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/auth/authService';

const AuthContext = createContext(null);

const INITIAL_STATE = {
  user: null,
  loading: true,
  error: null,
  isAuthenticated: false
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const navigate = useNavigate();

  /**
   * Gestion des états
   */
  const updateState = useCallback((updates) => {
    setState(current => ({ ...current, ...updates }));
  }, []);

  /**
   * Gestion des erreurs centralisée
   */
  const handleError = useCallback((error) => {
    const errorMessage = error?.message || 'Une erreur est survenue';
    updateState({ error: errorMessage, loading: false });
    console.error('Erreur AuthContext:', errorMessage);
    return errorMessage;
  }, [updateState]);

  /**
   * Vérification d'authentification
   */
  const checkAuth = useCallback(async () => {
    try {
      updateState({ loading: true, error: null });
      
      const token = authService.getSession();
      if (!token) {
        updateState({ loading: false, isAuthenticated: false });
        return;
      }

      const profile = await authService.getProfile();
      updateState({ 
        user: profile, 
        isAuthenticated: true, 
        loading: false 
      });
    } catch (error) {
      handleError(error);
      // On utilise authService.logout() directement pour éviter une boucle avec le useCallback
      authService.logout();
      navigate('/login');
    }
  }, [handleError, updateState, navigate]);

  /**
   * Vérification automatique de l'authentification
   */
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  /**
   * Actions d'authentification
   */
  const login = async (credentials) => {
    try {
      updateState({ loading: true, error: null });
      
      const response = await authService.login(credentials);
      const profile = await authService.getProfile();
      
      updateState({
        user: profile,
        isAuthenticated: true,
        loading: false,
        error: null
      });

      navigate('/dashboard');
      return response;
    } catch (error) {
      throw handleError(error);
    }
  };

  const register = async (userData) => {
    try {
      updateState({ loading: true, error: null });
      
      const response = await authService.register(userData);
      const profile = await authService.getProfile();
      
      updateState({
        user: profile,
        isAuthenticated: true,
        loading: false,
        error: null
      });

      navigate('/dashboard');
      return response;
    } catch (error) {
      throw handleError(error);
    }
  };

  const logout = useCallback(() => {
    authService.logout();
    updateState({
      ...INITIAL_STATE,
      loading: false
    });
    navigate('/login');
  }, [navigate, updateState]);

  /**
   * Gestion du profil
   */
  const updateProfile = async (profileData) => {
    try {
      updateState({ loading: true, error: null });
      
      const updatedProfile = await authService.updateProfile(profileData);
      
      updateState({
        user: updatedProfile,
        loading: false,
        error: null
      });

      return updatedProfile;
    } catch (error) {
      throw handleError(error);
    }
  };

  const refreshProfile = async () => {
    try {
      if (!state.isAuthenticated) return;
      
      const profile = await authService.getProfile();
      updateState({ 
        user: profile,
        error: null 
      });
      
      return profile;
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Réinitialisation du mot de passe
   */
  const resetPassword = async (email) => {
    try {
      updateState({ loading: true, error: null });
      
      const response = await authService.resetPassword(email);
      updateState({ loading: false });
      
      return response;
    } catch (error) {
      throw handleError(error);
    }
  };

  /**
   * Loading spinner
   */
  if (state.loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
      </div>
    );
  }

  /**
   * Valeurs du contexte
   */
  const contextValue = {
    ...state,
    login,
    register,
    logout,
    updateProfile,
    resetPassword,
    refreshProfile,
    checkAuth
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth doit être utilisé à l\'intérieur d\'un AuthProvider');
  }
  return context;
};

export default AuthContext;
