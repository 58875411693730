// components/tabs/EmolumentsTab.jsx
import React, { useState, useCallback, useEffect } from 'react';
import { HelpCircle, Calculator } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";
import { ACTES_CATEGORIES } from '@/constants/calculator';

// Constantes locales pour les tranches d'émoluments et les remises
const TRANCHES = [
  { jusqu_a: 6500, taux: 3.870 },
  { jusqu_a: 17000, taux: 1.596 },
  { jusqu_a: 60000, taux: 1.064 },
  { au_dela: true, taux: 0.799 }
];

const REMISES = {
  TRANCHE_1: { limite: 100000, taux: 0.10 },
  TRANCHE_2: { limite: 300000, taux: 0.20 },
  TRANCHE_3: { taux: 0.40 }
};

const HLM_REMISE = 0.50;
const MINIMUM_PERCEPTION = 83.33;
const TVA = 0.20;

const DEFAULT_VIAGER_VALUES = {
  bouquet: 0,
  rente: 0,
  age: 0
};

const DEFAULT_HELP_TEXT = "Information non disponible";

const HELP_TEXTS = {
  CATEGORIES: {
    FAMILLE: "Actes relatifs à la famille (mariage, succession, donation...)",
    IMMOBILIER: "Actes concernant les biens immobiliers (vente, bail, hypothèque...)",
    PRETS: "Actes liés aux prêts et garanties bancaires",
    SOCIETES: "Actes relatifs à la vie des sociétés",
    DIVERS: "Autres types d'actes (procurations, attestations...)"
  },
  OPTIONS: {
    REMISE: "Remise possible selon la valeur du bien",
    HLM: "Remise obligatoire de 50% pour les organismes HLM",
  },
  SPECIFIQUES: {
    VIAGER: "Calcul tenant compte de l'âge du crédirentier",
    NEUF: "Bien achevé depuis moins de 5 ans",
    HLM: "Réduction obligatoire de 50% pour les organismes HLM"
  }
};

const EmolumentsTab = ({ formData, setFormData }) => {
  const [localErrors, setLocalErrors] = useState({});
  const [emolumentsCalcules, setEmolumentsCalcules] = useState({
    ht: 0,
    tva: 0,
    ttc: 0,
    details: {}
  });

  // Initialisation des valeurs par défaut
  useEffect(() => {
    if (!formData.specifics) {
      setFormData(prev => ({
        ...prev,
        specifics: {
          isHLM: false,
          isViager: false,
          viagerDetails: null
        },
        discount: 0
      }));
    }
  }, []);

  // Calcul automatique des émoluments lors des changements
  useEffect(() => {
    if (formData.selectedAct && formData.baseAmount) {
      const calculEmoluments = calculateEmoluments();
      setEmolumentsCalcules(calculEmoluments);
    }
  }, [formData.selectedAct, formData.baseAmount, formData.discount, formData.specifics]);

  const handleActChange = useCallback((categoryId, actId) => {
    try {
      const category = ACTES_CATEGORIES[categoryId];
      const act = category?.actes?.find(a => a.id === actId);
      
      setFormData(prev => ({
        ...prev,
        selectedCategory: categoryId,
        selectedAct: act,
        specifics: {
          ...prev.specifics,
          isViager: act?.specificites?.type === "VIAGER",
          viagerDetails: act?.specificites?.type === "VIAGER" 
            ? { ...DEFAULT_VIAGER_VALUES }
            : null
        }
      }));

      setLocalErrors({});
      
    } catch (error) {
      console.error("Erreur lors du changement d'acte:", error);
      setLocalErrors(prev => ({
        ...prev,
        actChange: "Erreur lors de la sélection de l'acte"
      }));
    }
  }, [setFormData]);

  const handleViagerChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      specifics: {
        ...prev.specifics,
        viagerDetails: {
          ...prev.specifics.viagerDetails,
          [field]: value
        }
      }
    }));
  }, [setFormData]);

  const getMaxRemise = useCallback((montant) => {
    try {
      if (!montant) return REMISES.TRANCHE_1.taux;
      
      if (montant <= REMISES.TRANCHE_1.limite) {
        return REMISES.TRANCHE_1.taux;
      }
      if (montant <= REMISES.TRANCHE_2.limite) {
        return REMISES.TRANCHE_2.taux;
      }
      return REMISES.TRANCHE_3.taux;
    } catch (error) {
      console.error("Erreur calcul remise:", error);
      return 0.10;
    }
  }, []);

  const calculateEmoluments = () => {
    const montant = parseFloat(formData.baseAmount) || 0;
    let total = 0;
    const details = {
      tranches: []
    };

    // Calcul par tranches
    let montantRestant = montant;
    let seuilPrecedent = 0;

    for (const tranche of TRANCHES) {
      if (montantRestant <= 0) break;

      const montantTranche = tranche.au_dela 
        ? montantRestant 
        : Math.min(montantRestant, tranche.jusqu_a - seuilPrecedent);

      const emolumentTranche = montantTranche * (tranche.taux / 100);
      total += emolumentTranche;

      details.tranches.push({
        seuil: tranche.jusqu_a || 'au-delà',
        montant: montantTranche,
        taux: tranche.taux,
        emolument: emolumentTranche
      });

      montantRestant -= montantTranche;
      seuilPrecedent = tranche.jusqu_a;
    }

    // Minimum de perception
    total = Math.max(total, MINIMUM_PERCEPTION);

    // Application de la remise
    if (formData.discount) {
      const remise = total * (formData.discount / 100);
      total -= remise;
      details.remise = remise;
    }

    // Remise HLM
    if (formData.specifics?.isHLM) {
      const remiseHLM = total * HLM_REMISE;
      total -= remiseHLM;
      details.remiseHLM = remiseHLM;
    }

    // Calcul TVA
    const tva = total * TVA;
    const ttc = total + tva;

    return {
      ht: total,
      tva: tva,
      ttc: ttc,
      details: details
    };
  };

  const handleBaseAmountChange = useCallback((value) => {
    const numValue = Number(value);
    if (isNaN(numValue) || numValue < 0) {
      setLocalErrors(prev => ({
        ...prev,
        baseAmount: "Le montant doit être un nombre positif"
      }));
      return;
    }

    setFormData(prev => ({
      ...prev,
      baseAmount: value,
      discount: Math.min(
        prev.discount || 0,
        getMaxRemise(numValue) * 100
      )
    }));

    setLocalErrors(prev => {
      const { baseAmount, ...rest } = prev;
      return rest;
    });
  }, [getMaxRemise, setFormData]);

  const renderSpecificFields = useCallback(() => {
    if (!formData.selectedAct) return null;

    if (formData.selectedAct.specificites?.type === "VIAGER") {
      return (
        <div className="mt-4 space-y-4 p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium text-gray-900">Détails du viager</h4>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm text-gray-700">Bouquet (€)</label>
              <input
                type="number"
                value={formData.specifics.viagerDetails?.bouquet || 0}
                onChange={(e) => handleViagerChange('bouquet', Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-700">Rente annuelle (€)</label>
              <input
                type="number"
                value={formData.specifics.viagerDetails?.rente || 0}
                onChange={(e) => handleViagerChange('rente', Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-700 flex items-center">
                Âge du crédirentier
                <Tooltip content={HELP_TEXTS.SPECIFIQUES.VIAGER}>
                  <button className="ml-2 text-gray-400 hover:text-gray-600">
                    <HelpCircle className="w-4 h-4" />
                  </button>
                </Tooltip>
              </label>
              <input
                type="number"
                value={formData.specifics.viagerDetails?.age || 0}
                onChange={(e) => handleViagerChange('age', Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                min="0"
                max="120"
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [formData.selectedAct, formData.specifics.viagerDetails, handleViagerChange]);

  return (
    <div className="space-y-6">
      {/* Message d'info */}
      <div className="bg-blue-50 p-4 rounded-lg">
        <p className="text-sm text-blue-800 flex items-center">
          <Calculator className="w-4 h-4 mr-2" />
          Les émoluments sont calculés selon les tranches réglementaires et mis à jour automatiquement.
        </p>
      </div>

      {/* Affichage des erreurs */}
      {Object.keys(localErrors).length > 0 && (
        <div className="bg-red-50 p-4 rounded-lg">
          {Object.entries(localErrors).map(([key, error]) => (
            <p key={key} className="text-sm text-red-600">{error}</p>
          ))}
        </div>
      )}

      {/* Sélection de la catégorie */}
      <div>
        <div className="flex items-center mb-2">
          <label className="text-sm font-medium text-gray-700">
            Catégorie d'acte
          </label>
          <Tooltip content={HELP_TEXTS.CATEGORIES[formData.selectedCategory] || DEFAULT_HELP_TEXT}>
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <HelpCircle className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
        <select
          value={formData.selectedCategory || ''}
          onChange={(e) => handleActChange(e.target.value, '')}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option value="">Sélectionnez une catégorie...</option>
          {Object.entries(ACTES_CATEGORIES || {}).map(([id, category]) => (
            <option key={id} value={id}>{category.label}</option>
          ))}
        </select>
      </div>

      {/* Sélection de l'acte */}
      {formData.selectedCategory && (
        <div>
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">Type d'acte</label>
            <Tooltip content={HELP_TEXTS.OPTIONS.REMISE || DEFAULT_HELP_TEXT}>
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <select
            value={formData.selectedAct?.id || ''}
            onChange={(e) => handleActChange(formData.selectedCategory, e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">Sélectionnez un acte...</option>
            {ACTES_CATEGORIES[formData.selectedCategory]?.actes?.map(acte => (
              <option key={acte.id} value={acte.id}>{acte.label}</option>
            ))}
          </select>
        </div>
      )}

      {/* Montant de base */}
      {formData.selectedAct?.type === "PROPORTIONNEL" && (
        <div>
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">Valeur du bien (€)</label>
            <Tooltip content={HELP_TEXTS.SPECIFIQUES.NEUF || DEFAULT_HELP_TEXT}>
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <input
            type="number"
            value={formData.baseAmount || ''}
            onChange={(e) => handleBaseAmountChange(e.target.value)}
            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
              focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md
              ${localErrors.baseAmount ? 'border-red-300' : ''}`}
            placeholder="Montant en euros"
            min="0"
          />
          {localErrors.baseAmount && (
            <p className="mt-1 text-sm text-red-600">{localErrors.baseAmount}</p>
          )}
        </div>
      )}

      {/* Champs spéciaux pour l'acte sélectionné */}
      {renderSpecificFields()}

      {/* Montant calculé */}
      {formData.selectedAct && formData.baseAmount > 0 && (
        <div className="mt-4 bg-gray-50 p-4 rounded-lg">
          <h4 className="font-medium text-gray-900 mb-4">Résultat du calcul</h4>
          <div className="space-y-2">
            <div className="flex justify-between items-center text-sm">
              <span>Émoluments HT :</span>
              <span>{emolumentsCalcules.ht.toFixed(2)} €</span>
            </div>
            <div className="flex justify-between items-center text-sm">
              <span>TVA (20%) :</span>
              <span>{emolumentsCalcules.tva.toFixed(2)} €</span>
            </div>
            <div className="flex justify-between items-center font-medium text-sm pt-2 border-t border-gray-200">
              <span>Total TTC :</span>
              <span>{emolumentsCalcules.ttc.toFixed(2)} €</span>
            </div>
          </div>

          {/* Détails des calculs */}
          {emolumentsCalcules.details.remise && (
            <div className="mt-2 text-sm text-gray-600">
              <p>Remise appliquée : {formData.discount}% (-{emolumentsCalcules.details.remise.toFixed(2)} €)</p>
            </div>
          )}
          {emolumentsCalcules.details.remiseHLM && (
            <div className="mt-2 text-sm text-gray-600">
              <p>Remise HLM : 50% (-{emolumentsCalcules.details.remiseHLM.toFixed(2)} €)</p>
            </div>
          )}
        </div>
      )}

      {/* Options communes */}
      {formData.selectedAct && (
        <div className="mt-6">
          <h4 className="font-medium text-gray-900 mb-4">Options de calcul</h4>
          <div className="space-y-4">
            {/* Remise */}
            <div>
              <div className="flex items-center mb-2">
                <label className="text-sm text-gray-700">
                  Remise (maximum {(getMaxRemise(formData.baseAmount) * 100).toFixed(1)}%)
                </label>
                <Tooltip content={HELP_TEXTS.OPTIONS.REMISE || DEFAULT_HELP_TEXT}>
                  <button className="ml-2 text-gray-400 hover:text-gray-600">
                    <HelpCircle className="w-4 h-4" />
                  </button>
                </Tooltip>
              </div>
              <input
                type="number"
                value={formData.discount || 0}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  discount: Math.min(Math.max(0, Number(e.target.value)), getMaxRemise(formData.baseAmount) * 100)
                }))}
                className="block w-32 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                min="0"
                max={getMaxRemise(formData.baseAmount) * 100}
                step="0.1"
              />
            </div>

            {/* HLM */}
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.specifics?.isHLM || false}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    specifics: {
                      ...prev.specifics,
                      isHLM: e.target.checked
                    }
                  }))}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Organisme HLM (remise 50%)
                </span>
                <Tooltip content={HELP_TEXTS.SPECIFIQUES.HLM || DEFAULT_HELP_TEXT}>
                  <button className="ml-2 text-gray-400 hover:text-gray-600">
                    <HelpCircle className="w-4 h-4" />
                  </button>
                </Tooltip>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmolumentsTab;