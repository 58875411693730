import React from 'react';
import { Info, Printer, Calculator, FileText, DollarSign, Percent, ChevronUp, ChevronDown, Split } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

// Utilitaire de formatage monétaire
const formatCurrency = (value) => {
  if (typeof value !== 'number') return '0,00 €';
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  }).format(value);
};

// Configuration des catégories
const RESULT_CATEGORIES = [
  {
    key: 'prixRevient',
    title: 'Prix de revient',
    icon: Calculator,
    showTva: false,
    description: "Prix d'acquisition et frais"
  },
  {
    key: 'debours',
    title: 'Débours',
    icon: DollarSign,
    showTva: false,
    description: 'Sommes acquittées pour le compte du client'
  },
  {
    key: 'formalites',
    title: 'Formalités',
    icon: FileText,
    showTva: true,
    description: 'Démarches administratives obligatoires'
  },
  {
    key: 'plusValue',
    title: 'Plus-value',
    icon: Percent,
    showTva: false,
    description: 'Calcul de la plus-value nette'
  }
];

// Composant pour les détails d'une catégorie
const CategoryDetails = ({ 
  title, 
  icon: Icon, 
  montants, 
  details,
  description,
  showTva = false 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const total = showTva ? (montants?.ttc || 0) : (details?.total || montants?.total || 0);

  return (
    <Card className="mb-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 
          transition-colors rounded-t-lg"
      >
        <div className="flex items-center space-x-3">
          <Icon className="w-5 h-5 text-blue-600" />
          <div className="text-left">
            <span className="font-medium text-gray-900">{title}</span>
            {description && (
              <p className="text-sm text-gray-500">{description}</p>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <span className="font-medium">{formatCurrency(total)}</span>
          {isExpanded ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
        </div>
      </button>

      {isExpanded && (
        <CardContent className="border-t border-gray-200 bg-gray-50">
          <div className="space-y-4">
            {/* Montants standards */}
            <div className="space-y-2">
              {showTva ? (
                <>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Montant HT</span>
                    <span>{formatCurrency(montants?.ht)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">TVA (20%)</span>
                    <span>{formatCurrency(montants?.tva)}</span>
                  </div>
                  <div className="flex justify-between font-medium border-t border-gray-200 pt-2">
                    <span>Total TTC</span>
                    <span>{formatCurrency(montants?.ttc)}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span>{formatCurrency(total)}</span>
                </div>
              )}
            </div>

            {/* Détails supplémentaires */}
            {details && Object.keys(details).length > 0 && (
              <div className="border-t border-gray-200 pt-4 space-y-2">
                <h4 className="font-medium text-sm text-gray-900 mb-2">Détails</h4>
                {Object.entries(details).map(([key, value]) => (
                  typeof value === 'number' && (
                    <div key={key} className="flex justify-between text-sm">
                      <span className="text-gray-600">
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      </span>
                      <span>{formatCurrency(value)}</span>
                    </div>
                  )
                ))}
              </div>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

const ResultDisplay = ({ results, formData }) => {
  const hasDemembrement = formData.demembrement?.type !== 'pleine-propriete';

  return (
    <div className="lg:sticky lg:top-8 space-y-6">
      {/* En-tête avec total */}
      <Card className="bg-gradient-to-r from-blue-600 to-blue-700 text-white">
        <CardHeader>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Résultat du calcul</h3>
            <button
              onClick={() => window.print()}
              className="flex items-center px-4 py-2 bg-white text-blue-600 rounded-lg 
                hover:bg-blue-50 transition-colors"
            >
              <Printer className="w-4 h-4 mr-2" />
              Imprimer
            </button>
          </div>
          <div className="text-3xl font-bold">
            {formatCurrency(results.total)}
          </div>
          {hasDemembrement && (
            <div className="text-sm mt-2 bg-blue-500/20 p-2 rounded">
              Calcul pour {formData.demembrement.type === 'usufruit' ? "l'usufruit" : 'la nue-propriété'} 
              ({(formData.demembrement.type === 'usufruit' 
                ? formData.demembrement.valeurUsufruit 
                : formData.demembrement.valeurNuePropriete * 100).toFixed(0)}%)
            </div>
          )}
        </CardHeader>
      </Card>

      {/* Détails par catégorie */}
      <div className="space-y-4">
        {RESULT_CATEGORIES.map(({ key, title, icon, showTva, description }) => (
          results.sections?.[key] && (
            <CategoryDetails
              key={key}
              title={title}
              icon={icon}
              montants={results.sections[key].montants}
              details={results.sections[key].details}
              description={description}
              showTva={showTva}
            />
          )
        ))}

        {/* Section démembrement si applicable */}
        {hasDemembrement && (
          <Card className="mb-4">
            <CardHeader>
              <CardTitle className="flex items-center">
                <Split className="w-5 h-5 mr-2 text-blue-600" />
                Détails du démembrement
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex justify-between text-sm">
                <span>Type de droit</span>
                <span className="font-medium">
                  {formData.demembrement.type === 'usufruit' ? 'Usufruit' : 'Nue-propriété'}
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Âge de l'usufruitier</span>
                <span className="font-medium">{formData.demembrement.age} ans</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Coefficient appliqué</span>
                <span className="font-medium">
                  {(formData.demembrement.type === 'usufruit'
                    ? formData.demembrement.valeurUsufruit
                    : formData.demembrement.valeurNuePropriete * 100).toFixed(0)}%
                </span>
              </div>
              <div className="pt-4 border-t border-gray-200">
                <p className="text-sm text-gray-600">
                  Calculs effectués selon le barème de l'article 669 du CGI
                </p>
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      {/* Notes importantes */}
      <Card className="bg-blue-50 border-none">
        <CardContent className="p-4">
          <div className="flex">
            <Info className="h-5 w-5 text-blue-400 mt-0.5 flex-shrink-0" />
            <div className="ml-3">
              <h4 className="text-sm font-medium text-blue-800">Informations importantes</h4>
              <div className="mt-2 text-sm text-blue-700 space-y-1">
                <p>• Les montants sont calculés selon les tarifs en vigueur</p>
                <p>• Les calculs tiennent compte des dernières règles fiscales</p>
                {hasDemembrement && (
                  <p>• Valeurs de démembrement selon l'article 669 du CGI</p>
                )}
                <p>• Ce calcul est donné à titre indicatif</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Styles d'impression */}
      <style>{`
        @media print {
          .no-print { display: none; }
          .print-only { display: block; }
        }
      `}</style>
    </div>
  );
};

export default ResultDisplay;