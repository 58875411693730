import React, { useState, useEffect } from 'react';
import {
  Calculator,
  Building2,
  Brain,
  ArrowRight,
  CheckCircle,
  Scale,
  Clock,
  Shield
} from 'lucide-react';

const Hero = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ["notariale", "comptable", "immobilière"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((current) => (current + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="bg-gradient-to-r from-blue-600 to-indigo-700">
      <div className="max-w-7xl mx-auto px-6 py-20">
        <div className="flex flex-col md:flex-row items-center justify-between gap-20">
          <div className="flex-grow max-w-2xl">
            <h1 className="text-3xl md:text-4xl font-bold mb-8 leading-tight text-white">
              Aidez vos clercs à prétaxer et vérifiez vos prétaxes sans service comptable
            </h1>
            
            <ul className="text-lg mb-12 text-blue-100 space-y-3">
              <li className="flex items-start">
                <CheckCircle className="w-6 h-6 mr-2 mt-1 flex-shrink-0 text-green-400" />
                <span>Calculs de taxation d'après l'annexe 4-7 du Code de commerce et l'arrêté du 28 février 2020</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="w-6 h-6 mr-2 mt-1 flex-shrink-0 text-green-400" />
                <span>Module complémentaire d'évaluation immobilière basé sur la Charte de l'expertise immobilière</span>
              </li>
              <li className="flex items-start">  
                <CheckCircle className="w-6 h-6 mr-2 mt-1 flex-shrink-0 text-green-400" />
                <span>Obtenez des évaluations sourcées pour conseiller vos clients et affiner vos calculs de succession et de plus-value</span>
              </li>
            </ul>

            <div className="flex flex-col sm:flex-row items-center gap-6">
              <a 
                href="/essai-gratuit"
                className="bg-white text-blue-600 hover:bg-blue-50 px-8 py-4 rounded-lg 
                  font-semibold text-lg transition-all shadow-lg hover:shadow-xl 
                  transform hover:-translate-y-0.5 hover:scale-105
                  flex items-center justify-center min-w-[240px]"
              >
                Essayer gratuitement
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
              
              <a 
                href="/contact"
                className="bg-blue-700 text-white hover:bg-blue-800 
                  px-8 py-4 rounded-lg font-semibold transition-all
                  min-w-[240px] text-center hover:scale-105"
              >
                Nous contacter
              </a>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
};

export default function NewHomePage() {
  const [activeTab, setActiveTab] = useState('taxation');

  const features = [
    {
      icon: Calculator,
      title: "Taxation automatisée",
      description: "Calculez vos émoluments en quelques clics selon les derniers tarifs réglementés"  
    },
    {
      icon: Building2,
      title: "Expertise immobilière",
      description: "Évaluations conformes aux normes avec rapports détaillés"
    },
    {
      icon: Brain, 
      title: "Intelligence Artificielle",
      description: "Détection automatique des erreurs et assistance 24/7"
    }
  ];

  const services = [
    {
      title: "Taxation d'actes",
      description: "Calcul automatisé des émoluments",
      items: [
        "Vérification instantanée des calculs",
        "Prise en compte des dernières réformes", 
        "Archivage sécurisé des calculs"
      ]
    },
    {
      title: "Évaluation immobilière",
      description: "Expertise aux normes professionnelles",
      items: [
        "Rapports détaillés et personnalisables",
        "Base de données des transactions",
        "Modèles d'évaluation avancés" 
      ]
    },
    {
      title: "Assistance comptable",
      description: "Support comptable spécialisé",
      items: [
        "Vérification des comptes",
        "Assistance TVA immobilière",
        "Régularisation des dossiers"
      ]  
    }
  ];

  return (
    <div className="min-h-screen">
      <Hero />

      {/* Features Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <feature.icon className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* Services Tabs Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Nos Services</h2>
            <div className="flex justify-center space-x-4">
              {services.map((service, index) => (
                <button
                  key={index}
                  className={`px-6 py-2 rounded-lg font-medium transition-colors
                    ${activeTab === service.title.toLowerCase().replace(/\s+/g, '') 
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                  onClick={() => setActiveTab(service.title.toLowerCase().replace(/\s+/g, ''))}
                >
                  {service.title}
                </button>
              ))}
            </div>
          </div>
          
          {services.map((service, index) => (
            <div
              key={index}  
              className={`transition-opacity duration-300
                ${activeTab === service.title.toLowerCase().replace(/\s+/g, '')
                  ? 'opacity-100'
                  : 'hidden opacity-0'}`}
            >
              <div className="bg-white p-8 rounded-xl shadow-sm">
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-4">
                  {service.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-center gap-3">
                      <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Trust Section */}
      <section className="bg-gray-900 text-white py-20">
        <div className="container mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Pourquoi nous faire confiance ?</h2>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <Scale className="w-12 h-12 mx-auto mb-4 text-blue-400" />
              <h3 className="text-xl font-bold mb-2">Expertise des associés</h3>
              <p className="text-gray-400">
                +20 ans d'expérience en comptabilité notariale et +10 ans d'expérience en expertise immobilière / judicaire
              </p>
            </div>
            <div className="text-center">
              <Shield className="w-12 h-12 mx-auto mb-4 text-blue-400" />  
              <h3 className="text-xl font-bold mb-2">Sécurité maximale</h3>
              <p className="text-gray-400">
                Données chiffrées et sécurisées aux normes RGPD
              </p>
            </div>
            <div className="text-center">
              <Clock className="w-12 h-12 mx-auto mb-4 text-blue-400" />
              <h3 className="text-xl font-bold mb-2">Support réactif</h3>
              <p className="text-gray-400">
                Assistance technique sous 48h
              </p>
            </div>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-2xl text-white p-12">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">
                Prêt à simplifier votre gestion notariale ?
              </h2>
              <p className="text-xl mb-8">
                Rejoignez les études qui font confiance à NotariaPrime
              </p>
              <a
                href="/essai-gratuit"
                className="inline-flex items-center bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
              >
                Commencer maintenant
                <ArrowRight className="ml-2 w-5 h-5" />  
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
