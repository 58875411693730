import React from 'react';
import { Info, HelpCircle } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tooltip } from "@/components/ui/tooltip";

// Constantes pour les types de bien
const PROPERTY_TYPES = {
  RESIDENTIAL: {
    id: "RESIDENTIAL",
    label: "Résidentiel",
    description: "Maison, appartement, garage..."
  },
  BUILDING_LAND: {
    id: "BUILDING_LAND",
    label: "Terrain à bâtir",
    description: "Terrain constructible"
  },
  OTHER: {
    id: "OTHER",
    label: "Autre bien",
    description: "Bien non résidentiel"
  }
};

// Constantes pour les types d'acquisition
const ACQUISITION_TYPES = {
  PURCHASE: {
    id: "PURCHASE",
    label: "Achat",
    description: "Acquisition à titre onéreux"
  },
  INHERITANCE: {
    id: "INHERITANCE",
    label: "Succession",
    description: "Acquisition par héritage"
  },
  DONATION: {
    id: "DONATION",
    label: "Donation",
    description: "Acquisition par donation"
  }
};

const PropertyInfo = ({ formData, handleChange }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Info className="w-5 h-5 mr-2 text-blue-600" />
          Nature du bien
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {/* Type de bien */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Type de bien
            <Tooltip content="Sélectionnez la nature du bien cédé">
              <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
            </Tooltip>
          </label>
          <select
            value={formData.propertyType}
            onChange={(e) => handleChange('propertyType', e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Sélectionnez le type de bien...</option>
            {Object.values(PROPERTY_TYPES).map((type) => (
              <option key={type.id} value={type.id}>
                {type.label}
              </option>
            ))}
          </select>
          {formData.propertyType && (
            <p className="mt-1 text-sm text-gray-500">
              {PROPERTY_TYPES[formData.propertyType]?.description}
            </p>
          )}
        </div>

        {/* Type d'acquisition */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mode d'acquisition
            <Tooltip content="Comment avez-vous acquis le bien ?">
              <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
            </Tooltip>
          </label>
          <select
            value={formData.acquisitionType}
            onChange={(e) => handleChange('acquisitionType', e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Sélectionnez le mode d'acquisition...</option>
            {Object.values(ACQUISITION_TYPES).map((type) => (
              <option key={type.id} value={type.id}>
                {type.label}
              </option>
            ))}
          </select>
          {formData.acquisitionType && (
            <p className="mt-1 text-sm text-gray-500">
              {ACQUISITION_TYPES[formData.acquisitionType]?.description}
            </p>
          )}
        </div>

        {/* Quote-part de propriété */}
        <div className="space-y-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={formData.soleOwner}
              onChange={(e) => handleChange('soleOwner', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label className="ml-2 text-sm text-gray-700">
              Seul propriétaire du bien
            </label>
          </div>
          
          {!formData.soleOwner && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Quote-part vendue (%)
                <Tooltip content="Votre pourcentage de propriété dans le bien">
                  <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="number"
                min="1"
                max="99"
                value={formData.ownershipShare}
                onChange={(e) => handleChange('ownershipShare', Math.min(99, Math.max(1, Number(e.target.value) || 0)))}
                className="block w-32 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PropertyInfo;