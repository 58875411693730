import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
  Bell,
  Moon,
  Sun,
  Shield,
  Mail,
  Percent,
  Save
} from 'lucide-react';

const SettingsPage = () => {
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    notifications: {
      email: true,
      browser: true,
      mobile: false
    },
    appearance: {
      theme: 'light',
      fontSize: 'normal'
    },
    calculation: {
      defaultTvaRate: 20,
      showRemiseOptions: true,
      autoSave: true
    },
    security: {
      twoFactorAuth: false,
      sessionTimeout: 30
    }
  });

  const updateSetting = (category, setting, value) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      // Ici viendra l'appel API pour sauvegarder les paramètres
      alert('Paramètres sauvegardés avec succès');
    } catch (error) {
      alert('Erreur lors de la sauvegarde des paramètres');
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="space-y-6">
        {/* En-tête */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Paramètres</h2>
          <p className="mt-1 text-sm text-gray-500">
            Gérez vos préférences et personnalisez votre expérience
          </p>
        </div>

        {/* Notifications */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Bell className="h-5 w-5 text-blue-600 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">Notifications</h3>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-700">Notifications par email</p>
                <p className="text-sm text-gray-500">Recevoir les mises à jour par email</p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={settings.notifications.email}
                  onChange={(e) => updateSetting('notifications', 'email', e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                  peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full 
                  peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] 
                  after:left-[2px] after:bg-white after:border-gray-300 after:border 
                  after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* Calculs */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Percent className="h-5 w-5 text-blue-600 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">Calculs</h3>
          </div>
          <div className="space-y-4">
            <div>
              <label className="block font-medium text-gray-700 mb-1">
                Taux de TVA par défaut
              </label>
              <select
                value={settings.calculation.defaultTvaRate}
                onChange={(e) => updateSetting('calculation', 'defaultTvaRate', Number(e.target.value))}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 
                  focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm 
                  rounded-md"
              >
                <option value={20}>20%</option>
                <option value={10}>10%</option>
                <option value={5.5}>5.5%</option>
              </select>
            </div>
          </div>
        </div>

        {/* Sécurité */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Shield className="h-5 w-5 text-blue-600 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">Sécurité</h3>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-700">Double authentification</p>
                <p className="text-sm text-gray-500">Sécurité renforcée pour votre compte</p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={settings.security.twoFactorAuth}
                  onChange={(e) => updateSetting('security', 'twoFactorAuth', e.target.checked)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                  peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full 
                  peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] 
                  after:left-[2px] after:bg-white after:border-gray-300 after:border 
                  after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* Bouton de sauvegarde */}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="inline-flex items-center px-4 py-2 border border-transparent 
              rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 
              hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-blue-500"
          >
            <Save className="h-4 w-4 mr-2" />
            Sauvegarder les paramètres
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
