/**
 * Service de calcul des émoluments notariaux
 * @class EmolumentsService
 */
export class EmolumentsService {
  // Constantes réglementaires
  static VERSION = '1.0.0';
  static REFERENCE = 'Art. A. 444-53 à A. 444-186 du Code de commerce';

  static TRANCHES = Object.freeze([
    { jusqu_a: 6500, taux: 3.870, reference: 'Art. A. 444-54' },
    { jusqu_a: 17000, taux: 1.596, reference: 'Art. A. 444-54' },
    { jusqu_a: 60000, taux: 1.064, reference: 'Art. A. 444-54' },
    { au_dela: true, taux: 0.799, reference: 'Art. A. 444-54' }
  ]);

  static REMISES = Object.freeze({
    TRANCHE_1: { limite: 100000, taux: 0.10, reference: 'Art. R. 444-10' },
    TRANCHE_2: { limite: 300000, taux: 0.20, reference: 'Art. R. 444-10' },
    TRANCHE_3: { taux: 0.40, reference: 'Art. R. 444-10' }
  });

  static TAUX = Object.freeze({
    MINIMUM_PERCEPTION: 83.33,
    TVA: 0.20,
    REMISE_HLM: 0.50
  });

  /**
   * Méthode utilitaire d'arrondi
   */
  static arrondirMontant(montant) {
    return Math.round(montant * 100) / 100;
  }

  /**
   * Calcule les émoluments pour un acte
   * @async
   * @param {Object} formData - Données du formulaire
   * @returns {Promise<Object>} Résultat détaillé du calcul
   * @throws {Error} Si les données sont invalides
   */
  static async calculerEmoluments(formData) {
    try {
      // Validation des données
      const validation = this.validateInput(formData);
      if (!validation.isValid) {
        throw new Error(validation.errors.join('; '));
      }

      // Traitement selon le type d'acte
      const resultat = formData.selectedAct.type === "PROPORTIONNEL"
        ? await this.calculerEmolumentsProportionnels(formData)
        : await this.calculerEmolumentsFixe(formData);

      return this.enrichirResultat(resultat, formData);

    } catch (error) {
      console.error('[EmolumentsService] Erreur de calcul:', error);
      return this.getEmptyResult(error.message);
    }
  }

  /**
   * Calcule les émoluments proportionnels
   * @private
   */
  static async calculerEmolumentsProportionnels(formData) {
    try {
      const montant = Number(formData.baseAmount);
      let resultat = await this.calculerEmolumentBase(montant);

      // Application séquentielle des remises et TVA
      resultat = await this.appliquerRemises(resultat, formData);
      resultat = await this.appliquerTVA(resultat);
      resultat = await this.ajouterDetails(resultat, formData);

      return resultat;
    } catch (error) {
      console.error('Erreur dans calculerEmolumentsProportionnels:', error);
      throw error;
    }
  }

  /**
   * Calcule l'émolument de base
   * @private
   */
  static async calculerEmolumentBase(montant) {
    let total = 0;
    const details = {
      tranches: [],
      calcul: []
    };

    let montantRestant = montant;
    let seuilPrecedent = 0;

    for (const tranche of this.TRANCHES) {
      if (montantRestant <= 0) break;

      const montantTranche = tranche.au_dela
        ? montantRestant
        : Math.min(montantRestant, tranche.jusqu_a - seuilPrecedent);

      const emolumentTranche = this.calculerEmolumentTranche(montantTranche, tranche.taux);
      total += emolumentTranche;

      details.tranches.push({
        seuil: tranche.jusqu_a || 'au-delà',
        montant: montantTranche,
        taux: tranche.taux,
        emolument: emolumentTranche,
        reference: tranche.reference
      });

      details.calcul.push(
        `Tranche ${tranche.jusqu_a || '>'} : ${montantTranche.toFixed(2)}€ × ${tranche.taux}% = ${emolumentTranche.toFixed(2)}€`
      );

      montantRestant -= montantTranche;
      seuilPrecedent = tranche.jusqu_a;
    }

    return {
      montants: {
        ht: Math.max(total, this.TAUX.MINIMUM_PERCEPTION),
        tva: 0,
        ttc: 0
      },
      details
    };
  }

  /**
   * Calcule l'émolument pour une tranche
   * @private
   */
  static calculerEmolumentTranche(montant, taux) {
    return (montant * taux) / 100;
  }

  /**
   * Applique les remises (commerciale et HLM)
   * @private
   */
  static async appliquerRemises(resultat, formData) {
    let res = { ...resultat };

    // Remise commerciale
    if (formData.discount) {
      const tauxMax = this.getTauxRemiseMax(formData.baseAmount);
      const tauxEffectif = Math.min(formData.discount / 100, tauxMax);
      const remise = res.montants.ht * tauxEffectif;

      res.montants.ht -= remise;
      res.details.remiseCommerciale = {
        taux: tauxEffectif * 100,
        montant: remise,
        maximum: tauxMax * 100
      };
    }

    // Remise HLM
    if (formData.specifics?.isHLM) {
      const remiseHLM = res.montants.ht * this.TAUX.REMISE_HLM;
      res.montants.ht -= remiseHLM;
      res.details.remiseHLM = {
        taux: this.TAUX.REMISE_HLM * 100,
        montant: remiseHLM
      };
    }

    return res;
  }

  /**
   * Applique la TVA
   * @private
   */
  static async appliquerTVA(resultat) {
    const res = { ...resultat };
    res.montants.tva = this.arrondirMontant(res.montants.ht * this.TAUX.TVA);
    res.montants.ttc = res.montants.ht + res.montants.tva;
    return res;
  }

  /**
   * Ajoute les détails au résultat
   * @private
   */
  static async ajouterDetails(resultat, formData) {
    return {
      ...resultat,
      details: {
        ...resultat.details,
        typeActe: formData.selectedAct.type,
        baseCalcul: formData.baseAmount,
        dateCalcul: new Date().toISOString()
      }
    };
  }

  /**
   * Calcule les émoluments fixes
   * @private
   */
  static async calculerEmolumentsFixe(formData) {
    const montantFixe = formData.selectedAct.montant || this.TAUX.MINIMUM_PERCEPTION;
    return {
      montants: {
        ht: montantFixe,
        tva: this.arrondirMontant(montantFixe * this.TAUX.TVA),
        ttc: montantFixe * (1 + this.TAUX.TVA)
      },
      details: {
        type: 'FIXE',
        reference: formData.selectedAct.reference,
        montantBase: montantFixe
      }
    };
  }

  /**
   * Enrichit le résultat avec des métadonnées
   * @private
   */
  static enrichirResultat(resultat, formData) {
    return {
      ...resultat,
      metadata: {
        version: this.VERSION,
        reference: this.REFERENCE,
        dateCalcul: new Date().toISOString(),
        acte: {
          type: formData.selectedAct.type,
          id: formData.selectedAct.id,
          reference: formData.selectedAct.reference
        }
      }
    };
  }

  /**
   * Retourne un résultat vide avec message d'erreur
   */
  static getEmptyResult(error = '') {
    return {
      montants: { ht: 0, tva: 0, ttc: 0 },
      details: { error },
      metadata: {
        version: this.VERSION,
        dateCalcul: new Date().toISOString(),
        error
      }
    };
  }

  static getTauxRemiseMax(montant) {
    if (montant <= this.REMISES.TRANCHE_1.limite) return this.REMISES.TRANCHE_1.taux;
    if (montant <= this.REMISES.TRANCHE_2.limite) return this.REMISES.TRANCHE_2.taux;
    return this.REMISES.TRANCHE_3.taux;
  }

  static validateInput(data) {
    const errors = [];

    if (!data || !data.selectedAct) {
      errors.push("Type d'acte non spécifié");
    } else {
      if (data.selectedAct.type === 'PROPORTIONNEL') {
        if (!data.baseAmount || data.baseAmount <= 0) {
          errors.push("Montant invalide pour un acte proportionnel");
        }
        if (data.discount && (data.discount < 0 || data.discount > 40)) {
          errors.push("Taux de remise invalide (doit être entre 0 et 40%)");
        }
      }
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }
}

export default EmolumentsService;