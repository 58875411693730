export class ValidationService {
    static VALIDATION = {
      MONTANTS: {
        MAX: 100000000, // 100M€
        MIN: 0
      },
      DATES: {
        MIN: "1945-01-01",
        MAX: new Date().toISOString().split('T')[0]
      },
      QUOTE_PART: {
        MIN: 1,
        MAX: 99
      },
      TRAVAUX: {
        PLAFOND: 500000 // 500k€
      },
      DUREE: {
        MIN: 0,
        MAX: 50 // années
      }
    };
  
    static TYPES_BIEN = ["RESIDENTIAL", "BUILDING_LAND", "OTHER"];
  
    /**
     * Validation principale des données d'entrée
     */
    static validateInput(formData) {
      const errors = [];
      
      try {
        // Validation des champs obligatoires
        this.validateRequiredFields(formData, errors);
  
        // Validation des montants
        if (formData.prixAcquisition !== undefined || formData.prixVente !== undefined) {
          this.validateMontants(formData, errors);
        }
  
        // Validation des dates
        if (formData.dateAcquisition || formData.dateVente) {
          this.validateDates(formData, errors);
        }
  
        // Validation de la quote-part
        if (formData.soleOwner === false) {
          this.validateQuotePart(formData, errors);
        }
  
        // Validation des travaux
        if (formData.travauxRealisesSaisis) {
          this.validateTravaux(formData, errors);
        }
  
        // Validation du type de bien
        if (formData.propertyType) {
          this.validateTypeBien(formData, errors);
        }
  
        // Validation de cohérence générale
        this.validateCoherence(formData, errors);
  
      } catch (error) {
        console.error('Erreur lors de la validation:', error);
        errors.push(`Erreur de validation: ${error.message}`);
      }
  
      return {
        isValid: errors.length === 0,
        errors
      };
    }
  
    /**
     * Validation des champs obligatoires
     */
    static validateRequiredFields(formData, errors) {
      const required = [
        { field: 'propertyType', message: "Le type de bien est obligatoire" },
        { field: 'dateAcquisition', message: "La date d'acquisition est obligatoire" },
        { field: 'dateVente', message: "La date de vente est obligatoire" },
        { field: 'prixAcquisition', message: "Le prix d'acquisition est obligatoire" },
        { field: 'prixVente', message: "Le prix de vente est obligatoire" }
      ];
  
      required.forEach(({ field, message }) => {
        if (!formData[field]) {
          errors.push(message);
        }
      });
    }
  
    /**
     * Validation des montants
     */
    static validateMontants(formData, errors) {
      const montants = [
        { value: formData.prixAcquisition, name: "Prix d'acquisition" },
        { value: formData.prixVente, name: "Prix de vente" }
      ];
  
      montants.forEach(({ value, name }) => {
        if (value !== undefined) {
          const montant = Number(value);
          if (isNaN(montant)) {
            errors.push(`${name} invalide`);
          } else if (montant < this.VALIDATION.MONTANTS.MIN) {
            errors.push(`${name} doit être positif`);
          } else if (montant > this.VALIDATION.MONTANTS.MAX) {
            errors.push(`${name} ne peut pas dépasser ${this.VALIDATION.MONTANTS.MAX.toLocaleString()} €`);
          }
        }
      });
    }
  
    /**
     * Validation des dates
     */
    static validateDates(formData, errors) {
      try {
        const dateAcquisition = new Date(formData.dateAcquisition);
        const dateVente = new Date(formData.dateVente);
        const dateMinimale = new Date(this.VALIDATION.DATES.MIN);
        const dateMaximale = new Date(this.VALIDATION.DATES.MAX);
  
        if (isNaN(dateAcquisition.getTime())) {
          errors.push("Date d'acquisition invalide");
        }
        if (isNaN(dateVente.getTime())) {
          errors.push("Date de vente invalide");
        }
  
        if (dateAcquisition < dateMinimale || dateVente > dateMaximale) {
          errors.push("Les dates doivent être comprises entre 1945 et aujourd'hui");
        }
  
        if (dateAcquisition >= dateVente) {
          errors.push("La date d'acquisition doit être antérieure à la date de vente");
        }
  
        // Validation de la promesse si présente
        if (formData.hasPromise && formData.promiseDate) {
          const datePromesse = new Date(formData.promiseDate);
          if (datePromesse > dateVente) {
            errors.push("La date de promesse ne peut pas être postérieure à la date de vente");
          }
          if (datePromesse < dateAcquisition) {
            errors.push("La date de promesse ne peut pas être antérieure à la date d'acquisition");
          }
        }
      } catch (error) {
        errors.push("Format de date invalide");
      }
    }
  
    /**
     * Validation de la quote-part
     */
    static validateQuotePart(formData, errors) {
      const quotePart = Number(formData.ownershipShare);
      if (isNaN(quotePart) || 
          quotePart < this.VALIDATION.QUOTE_PART.MIN || 
          quotePart > this.VALIDATION.QUOTE_PART.MAX) {
        errors.push("Quote-part invalide (doit être entre 1 et 99%)");
      }
    }
  
    /**
     * Validation des travaux
     */
    static validateTravaux(formData, errors) {
      if (!formData.travauxForfait) {
        const montantTravaux = Number(formData.travauxRealisesSaisis);
        if (montantTravaux > this.VALIDATION.TRAVAUX.PLAFOND) {
          errors.push(`Les travaux ne peuvent pas dépasser ${this.VALIDATION.TRAVAUX.PLAFOND.toLocaleString()} €`);
        }
      }
    }
  
    /**
     * Validation du type de bien
     */
    static validateTypeBien(formData, errors) {
      if (!this.TYPES_BIEN.includes(formData.propertyType)) {
        errors.push("Type de bien invalide");
      }
    }
  
    /**
     * Validation de cohérence générale
     */
    static validateCoherence(formData, errors) {
      // Vérification de la cohérence des exonérations
      if (formData.exonerations) {
        // Résidence principale et première cession sont mutuellement exclusives
        if (formData.exonerations.residencePrincipale && formData.exonerations.premiereCession) {
          errors.push("Un bien ne peut pas être à la fois résidence principale et première cession");
        }
  
        // Vérifications spécifiques pour la première cession
        if (formData.exonerations.premiereCession && !formData.premiereCessionDetails) {
          errors.push("Les détails de première cession sont requis");
        }
      }
  
      // Vérification des montants relatifs
      if (formData.prixVente && formData.prixAcquisition) {
        if (formData.prixVente < formData.prixAcquisition / 2) {
          errors.push("Le prix de vente semble anormalement bas par rapport au prix d'acquisition");
        }
      }
    }
  }