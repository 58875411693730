import React from 'react';
import { AlertCircle } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

export const ResetModal = ({
  hasResults,
  onConfirm,
  onCancel
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <Card className="max-w-md mx-4">
      <CardHeader>
        <CardTitle className="flex items-center">
          <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
          Confirmer la réinitialisation
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <p className="text-gray-600">
          Cette action effacera toutes les données saisies. 
          {hasResults && " Le calcul en cours sera perdu."}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-600 hover:text-gray-900"
          >
            Annuler
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-lg 
              hover:bg-red-700 transition-colors"
          >
            Réinitialiser
          </button>
        </div>
      </CardContent>
    </Card>
  </div>
);