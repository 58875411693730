// services/calculationService/taxes.js

export class TaxesService {
  // Constantes réglementaires
  static TVA = {
    TAUX: 0.20,
    TAUX_REDUIT: 0.055, // 5.5% pour le logement social
    SEUILS: {
      NEUF: 5, // 5 ans pour distinction neuf/ancien
      RENOVATION: {
        SEUIL_ELEMENTS: 0.50, // 50% des éléments essentiels
        DELAI_ACHEVEMENT: 5 // 5 ans après travaux
      }
    }
  };

  static DROITS_MUTATION = {
    ANCIEN: {
      TAUX: 0.05806625, // 5.80665%
      DETAIL: {
        DEPARTEMENT: 0.04,          // 4.00%
        COMMUNE: 0.012,             // 1.20%
        FRAIS_ASSIETTE: 0.00234625  // 0.234625%
      }
    },
    NEUF: {
      TAUX: 0.00715,  // 0.715%
      TVA: 0.20       // 20%
    },
    HLM: {
      TAUX: 0.00715,  // 0.715% comme le neuf
      TVA: 0.055      // 5.5%
    }
  };

  static EXONERATIONS = {
    PREMIERE_ACQUISITION: 0.50, // 50% d'exonération
    ZONE_PRIORITAIRE: 0.30,    // 30% d'exonération
    ENGAGEMENT_LOCATION: 0.20  // 20% d'exonération
  };

  /**
   * Calcule l'ensemble des taxes applicables
   */
  static calculerTaxes(formData) {
    try {
      const { taxes = {}, baseAmount = 0, specifics = {} } = formData;
      const result = {
        details: {},
        total: 0
      };

      // 1. Détermination du régime de TVA
      const regimeTVA = this.determinerRegimeTVA(formData);
      result.details.regimeTVA = regimeTVA;

      // 2. Calcul de la TVA si applicable
      if (regimeTVA.applicable) {
        const tva = this.calculerTVA(formData, regimeTVA);
        result.details.tva = tva;
        result.total += tva.montant;
      }

      // 3. Calcul des droits de mutation
      const droitsMutation = this.calculerDroitsMutation(formData);
      result.details.droitsMutation = droitsMutation;
      result.total += droitsMutation.total;

      // 4. Application des exonérations éventuelles
      if (taxes.exonerations) {
        const exonerations = this.appliquerExonerations(formData, result.total);
        result.details.exonerations = exonerations;
        result.total -= exonerations.montant;
      }

      // 5. Arrondir le total à 2 décimales
      result.total = Math.round(result.total * 100) / 100;

      return {
        ...result,
        validation: {
          dateCalcul: new Date().toISOString(),
          references: this.getReferencesLegales(formData)
        }
      };

    } catch (error) {
      console.error('Erreur dans le calcul des taxes:', error);
      return this.getEmptyResult(error.message);
    }
  }

  /**
   * Détermine le régime de TVA applicable
   */
  static determinerRegimeTVA(formData) {
    const { taxes = {}, specifics = {} } = formData;
    
    const result = {
      applicable: false,
      type: 'ANCIEN',
      taux: 0,
      surMarge: false
    };

    // Cas du logement social
    if (specifics.isHLM) {
      result.applicable = true;
      result.type = 'HLM';
      result.taux = this.TVA.TAUX_REDUIT;
      return result;
    }

    // Bien neuf ou assimilé
    if (taxes.type === 'new' || specifics.isNeuf) {
      result.applicable = true;
      result.type = 'NEUF';
      result.taux = this.TVA.TAUX;
      return result;
    }

    // TVA sur marge
    if (taxes.marginBased) {
      result.applicable = true;
      result.type = 'MARGE';
      result.taux = this.TVA.TAUX;
      result.surMarge = true;
    }

    return result;
  }

  /**
   * Calcule la TVA selon le régime applicable
   */
  static calculerTVA(formData, regimeTVA) {
    const { taxes = {}, baseAmount = 0 } = formData;
    let baseTVA = baseAmount;
    
    // Calcul sur la marge si applicable
    if (regimeTVA.surMarge && taxes.margin) {
      baseTVA = taxes.margin;
    }

    const montant = baseTVA * regimeTVA.taux;

    return {
      base: baseTVA,
      taux: regimeTVA.taux,
      montant,
      details: {
        type: regimeTVA.type,
        surMarge: regimeTVA.surMarge,
        reference: this.getReferenceTVA(regimeTVA.type)
      }
    };
  }

  /**
   * Calcule les droits de mutation
   */
  static calculerDroitsMutation(formData) {
    const { taxes = {}, baseAmount = 0, specifics = {} } = formData;
    
    // Détermination du taux applicable
    let tauxGlobal, detail;
    if (taxes.type === 'new' || specifics.isHLM) {
      tauxGlobal = this.DROITS_MUTATION.NEUF.TAUX;
      detail = null;
    } else {
      tauxGlobal = this.DROITS_MUTATION.ANCIEN.TAUX;
      detail = {
        departement: baseAmount * this.DROITS_MUTATION.ANCIEN.DETAIL.DEPARTEMENT,
        commune: baseAmount * this.DROITS_MUTATION.ANCIEN.DETAIL.COMMUNE,
        fraisAssiette: baseAmount * this.DROITS_MUTATION.ANCIEN.DETAIL.FRAIS_ASSIETTE
      };
    }

    const total = baseAmount * tauxGlobal;

    return {
      base: baseAmount,
      taux: tauxGlobal,
      total,
      detail,
      reference: this.getReferenceDroitsMutation(taxes.type)
    };
  }

  /**
   * Applique les exonérations éventuelles
   */
  static appliquerExonerations(formData, montantTotal) {
    const { taxes = {} } = formData;
    const exonerations = [];
    let montantExoneration = 0;

    if (taxes.exonerations?.premiereAcquisition) {
      const montant = montantTotal * this.EXONERATIONS.PREMIERE_ACQUISITION;
      exonerations.push({
        type: 'PREMIERE_ACQUISITION',
        taux: this.EXONERATIONS.PREMIERE_ACQUISITION,
        montant
      });
      montantExoneration += montant;
    }

    if (taxes.exonerations?.zonePrioritaire) {
      const montant = montantTotal * this.EXONERATIONS.ZONE_PRIORITAIRE;
      exonerations.push({
        type: 'ZONE_PRIORITAIRE',
        taux: this.EXONERATIONS.ZONE_PRIORITAIRE,
        montant
      });
      montantExoneration += montant;
    }

    return {
      exonerations,
      montant: montantExoneration
    };
  }

  /**
   * Retourne les références légales applicables
   */
  static getReferencesLegales(formData) {
    const references = ["CGI, Art. 683 et suivants"];
    
    if (this.determinerRegimeTVA(formData).applicable) {
      references.push("CGI, Art. 256 et suivants");
    }

    return references;
  }

  /**
   * Retourne la référence spécifique pour la TVA
   */
  static getReferenceTVA(type) {
    switch (type) {
      case 'NEUF':
        return "CGI, Art. 257-I-2-2°";
      case 'HLM':
        return "CGI, Art. 278 sexies";
      case 'MARGE':
        return "CGI, Art. 268";
      default:
        return "CGI, Art. 256";
    }
  }

  /**
   * Retourne la référence spécifique pour les droits de mutation
   */
  static getReferenceDroitsMutation(type) {
    return type === 'new' 
      ? "CGI, Art. 1594 F quinquies"
      : "CGI, Art. 1594 D";
  }

  /**
   * Retourne un résultat vide avec message d'erreur
   */
  static getEmptyResult(error = '') {
    return {
      details: { error },
      total: 0
    };
  }

  /**
   * Valide les données d'entrée
   */
  static validateTaxes(formData) {
    const errors = [];
    const { taxes = {}, baseAmount } = formData;

    if (!baseAmount || baseAmount <= 0) {
      errors.push("Le montant de base est requis pour le calcul des taxes");
    }

    if (taxes.marginBased && !taxes.margin) {
      errors.push("La marge doit être spécifiée pour la TVA sur marge");
    }

    if (taxes.margin && taxes.margin > baseAmount) {
      errors.push("La marge ne peut pas être supérieure au prix de vente");
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }
}

export default TaxesService;