export const SPECIAL_RULES = {
    REMISES: {
      HLM: 0.50,  // Article A. 444-71
      TRANCHE_1: { limite: 100000, taux: 0.10 },  // Article A. 444-174
      TRANCHE_2: { limite: 300000, taux: 0.20 },
      TRANCHE_3: { taux: 0.40 }
    },
    MAJORATIONS: {
      URGENCE: { 
        taux: 0.30, 
        reference: "Article A. 444-174",
        conditions: "Réception ou signature de l'acte en urgence à la demande expresse du client" 
      },
      COMPLEXITE: { 
        taux: 0.30, 
        reference: "Article A. 444-174",
        conditions: "Recherches ou difficultés particulières tenant à la complexité de l'acte" 
      },
      PLURALITE: { 
        taux: 0.30, 
        reference: "Article A. 444-174",
        conditions: "Pluralité de biens ou de parties dans l'acte ou élection de command" 
      },
      HORS_ETUDE: {
        taux: 0.30,
        reference: "Article A. 444-174",
        conditions: "Réception ou signature de l'acte hors des locaux de l'office notarial"
      }
    }
  };
  
  export const HELP_TEXTS = {
    CATEGORIES: {
      FAMILLE: "Actes relatifs au droit de la famille (mariage, succession, donation...)",
      IMMOBILIER: "Actes concernant les biens immobiliers (vente, bail, hypothèque...)",
      PRETS: "Actes liés aux prêts et garanties bancaires",
      SOCIETES: "Actes relatifs à la vie des sociétés",
      DIVERS: "Autres types d'actes (procurations, attestations...)"
    },
    TVA: {
      BIEN_NEUF: "Un bien est considéré comme neuf s'il est achevé depuis moins de 5 ans",
      RENOVATION: "Les travaux de rénovation peuvent rendre le bien à l'état neuf sous certaines conditions",
      TERRAIN: "Un terrain à bâtir est défini par son caractère constructible selon les règles d'urbanisme",
      OPTION_TVA: "L'option pour la TVA doit être expressément mentionnée dans l'acte",
      MARGE: "La TVA sur marge s'applique dans certains cas spécifiques d'acquisition"
    },
    OPTIONS: {
      REMISE: "Remises possibles selon les tranches de prix (Article A. 444-174)",
      HLM: "Remise obligatoire de 50% pour les organismes HLM (Article A. 444-71)",
      CALCUL: "La remise s'applique sur l'émolument proportionnel avant TVA",
      MAJORATION_URGENCE: "Majoration possible pour urgence à la demande expresse du client",
      MAJORATION_COMPLEXITE: "Majoration possible pour acte complexe nécessitant des recherches particulières",
      MAJORATION_PLURALITE: "Majoration possible en cas de pluralité de biens ou de parties"
    },
    SPECIFIQUES: {
      HLM: "Réduction obligatoire de 50% pour les organismes HLM",
      NEUF: "Bien achevé depuis moins de 5 ans ou vendu en l'état futur d'achèvement",
      VIAGER: "Le calcul tient compte de l'âge du crédirentier et du montant de la rente",
      PRET_RELAIS: "Réduction de 25% si la durée est inférieure ou égale à 2 ans",
      MAINLEVEE: "Acte de radiation d'une inscription hypothécaire"
    }
  };
  
  export const DEBOURS_HELP = {
    CSI: {
      title: "Contribution de Sécurité Immobilière",
      description: "Taxe perçue lors de la publication de l'acte",
      mode_calcul: "0,1% de la valeur du bien avec minimum de 15€",
      reference: "Article 879 du CGI"
    },
    EXTRAIT_CADASTRAL: {
      title: "Extraits cadastraux",
      description: "Documents officiels identifiant le bien",
      tarif: "Prix unitaire fixé par l'administration",
      reference: "Arrêté du 30 septembre 2021"
    },
    ETAT_HYPOTHECAIRE: {
      title: "États hypothécaires",
      description: "Recherche des inscriptions sur le bien",
      details: "Standard (15€) ou urgent (30€)",
      reference: "Article A. 444-169"
    }
  };