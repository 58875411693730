export class PrixDeRevientService {
    static FORFAITS = {
      FRAIS_ACQUISITION: {
        TAUX: 0.075, // 7.5%
        REFERENCE: "BOI-RFPI-PVI-20-10-20-20"
      },
      TRAVAUX: {
        ANCIEN: {
          TAUX: 0.15,  // 15%
          CONDITIONS: {
            DUREE_DETENTION_MIN: 5  // années
          }
        },
        NEUF: {
          TAUX: 0,     // Pas de forfait pour les biens neufs
          CONDITIONS: {
            DUREE_MAX: 5  // années
          }
        },
        ENERGIE: {
          MAJORATION: 0.05,  // +5% pour travaux d'amélioration énergétique
          REFERENCE: "BOI-RFPI-PVI-20-10-20-20"
        },
        PLAFOND_REEL: 500000  // Plafond pour les travaux réels
      },
      VRD: {  // Voirie et Réseaux Divers
        PLAFOND: 100000,
        TYPES_AUTORISES: ["BUILDING_LAND"]
      }
    };
  
    /**
     * Calcule le prix de revient total
     */
    static async calculer(formData) {
      try {
        // 1. Prix d'acquisition
        const prixBase = this.calculerPrixBase(formData);
  
        // 2. Frais d'acquisition
        const fraisAcquisition = this.calculerFraisAcquisition(formData, prixBase);
  
        // 3. Travaux
        const travaux = this.calculerTravaux(formData, prixBase);
  
        // 4. Frais de voirie et réseaux divers
        const vrd = this.calculerVRD(formData);
  
        // Total et détails
        const total = prixBase + fraisAcquisition + travaux.montant + vrd;
  
        return {
          total,
          details: {
            prixAcquisition: prixBase,
            fraisAcquisition: {
              montant: fraisAcquisition,
              forfait: formData.fraisAcquisitionForfait,
              taux: formData.fraisAcquisitionForfait ? this.FORFAITS.FRAIS_ACQUISITION.TAUX : null
            },
            travaux: {
              montant: travaux.montant,
              forfait: formData.travauxForfait,
              type: travaux.type,
              taux: travaux.taux
            },
            vrd: vrd > 0 ? {
              montant: vrd,
              reference: "BOI-RFPI-PVI-20-10-20-20-20"
            } : null
          }
        };
  
      } catch (error) {
        console.error('Erreur dans le calcul du prix de revient:', error);
        throw new Error(`Erreur de calcul du prix de revient: ${error.message}`);
      }
    }
  
    /**
     * Calcule le prix d'acquisition de base
     */
    static calculerPrixBase(formData) {
      const prix = Number(formData.prixAcquisition);
      if (isNaN(prix) || prix <= 0) {
        throw new Error("Prix d'acquisition invalide");
      }
      return prix;
    }
  
    /**
     * Calcule les frais d'acquisition
     */
    static calculerFraisAcquisition(formData, prixBase) {
      if (formData.fraisAcquisitionForfait) {
        return prixBase * this.FORFAITS.FRAIS_ACQUISITION.TAUX;
      }
  
      const fraisReels = Number(formData.fraisAcquisitionSaisis);
      if (isNaN(fraisReels) || fraisReels < 0) {
        throw new Error("Montant des frais d'acquisition invalide");
      }
      return fraisReels;
    }
  
    /**
     * Calcule le montant des travaux
     */
    static calculerTravaux(formData, prixBase) {
      // Mode forfaitaire
      if (formData.travauxForfait) {
        const dureeDetention = this.calculerDureeDetention(
          formData.dateAcquisition,
          formData.dateVente
        );
  
        // Détermination du taux applicable
        let taux = 0;
        let type = 'NEUF';
  
        if (dureeDetention > this.FORFAITS.TRAVAUX.ANCIEN.CONDITIONS.DUREE_DETENTION_MIN) {
          taux = this.FORFAITS.TRAVAUX.ANCIEN.TAUX;
          type = 'ANCIEN';
  
          // Majoration pour travaux énergétiques si applicable
          if (formData.travauxEnergetiques) {
            taux += this.FORFAITS.TRAVAUX.ENERGIE.MAJORATION;
            type = 'ANCIEN_ENERGIE';
          }
        }
  
        return {
          montant: prixBase * taux,
          type,
          taux
        };
      }
  
      // Mode réel
      const montantTravaux = Number(formData.travauxRealisesSaisis) || 0;
      return {
        montant: Math.min(montantTravaux, this.FORFAITS.TRAVAUX.PLAFOND_REEL),
        type: 'REEL',
        taux: null
      };
    }
  
    /**
     * Calcule les frais de voirie et réseaux
     */
    static calculerVRD(formData) {
      if (!this.FORFAITS.VRD.TYPES_AUTORISES.includes(formData.propertyType)) {
        return 0;
      }
  
      const montant = Number(formData.fraisVoirieReseaux) || 0;
      return Math.min(montant, this.FORFAITS.VRD.PLAFOND);
    }
  
    /**
     * Calcule la durée de détention en années
     */
    static calculerDureeDetention(dateAcquisition, dateVente) {
      const debut = new Date(dateAcquisition);
      const fin = new Date(dateVente);
      
      let duree = fin.getFullYear() - debut.getFullYear();
      
      // Ajustement si nécessaire en fonction des mois et jours
      if (fin.getMonth() < debut.getMonth() || 
          (fin.getMonth() === debut.getMonth() && fin.getDate() < debut.getDate())) {
        duree--;
      }
      
      return Math.max(0, duree);
    }
  }