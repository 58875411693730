// src/components/immobilier/ImmobilierEvaluationCalculator.jsx

import React, { useState } from 'react';
import { immobilierEvaluationService } from '@/services/immobilierEvaluationService';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import ImmobilierEvaluationResultDisplay from './ImmobilierEvaluationResultDisplay';

const PROPERTY_TYPES = [
  { id: 'house', name: 'Maison' },
  { id: 'apartment', name: 'Appartement' },
  { id: 'commercial', name: 'Local commercial' },
  { id: 'land', name: 'Terrain' },
];

const PROPERTY_STATES = [
  { id: 'new', name: 'Neuf' },
  { id: 'good', name: 'Bon état' },
  { id: 'average', name: 'État moyen' },
  { id: 'work_needed', name: 'Travaux à prévoir' },
];

const ImmobilierEvaluationCalculator = () => {
  const [formData, setFormData] = useState({
    type: '',
    surface: '',
    rooms: '',
    state: '',
    address: '',
    location: '',
    parking: false,
    elevator: false,
    balcony: false,
  });

  const [evaluationResult, setEvaluationResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await immobilierEvaluationService.evaluate(formData);
      setEvaluationResult(result);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <div className="max-w-3xl mx-auto mt-8">
      <Card>
        <CardHeader>
          <CardTitle>Évaluation immobilière</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Select
              label="Type de bien"
              name="type"
              value={formData.type}
              onChange={(e) => handleChange('type', e.target.value)}
              options={PROPERTY_TYPES}
            />

            <Input
              type="number"
              label="Surface (m²)"
              name="surface"
              value={formData.surface}
              onChange={(e) => handleChange('surface', e.target.value)}
              min={0}
            />

            <Input
              type="number"
              label="Nombre de pièces"
              name="rooms"
              value={formData.rooms}
              onChange={(e) => handleChange('rooms', e.target.value)}
              min={0}
            />

            <Select
              label="État général"
              name="state"
              value={formData.state}
              onChange={(e) => handleChange('state', e.target.value)}
              options={PROPERTY_STATES}
            />

            <Input
              type="text"
              label="Adresse"
              name="address"
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
            />

            <Input
              type="text"
              label="Localisation (Quartier, Ville)"
              name="location"
              value={formData.location}
              onChange={(e) => handleChange('location', e.target.value)}
            />

            <div className="flex space-x-4">
              <label>
                <input
                  type="checkbox"
                  name="parking"
                  checked={formData.parking}
                  onChange={(e) => handleChange('parking', e.target.checked)}
                />
                Parking
              </label>
              <label>
                <input
                  type="checkbox"
                  name="elevator"
                  checked={formData.elevator}
                  onChange={(e) => handleChange('elevator', e.target.checked)}
                />
                Ascenseur
              </label>
              <label>
                <input
                  type="checkbox"
                  name="balcony"
                  checked={formData.balcony}
                  onChange={(e) => handleChange('balcony', e.target.checked)}
                />
                Balcon
              </label>
            </div>

            <Button type="submit" disabled={loading}>
              {loading ? 'Évaluation en cours...' : 'Évaluer'}
            </Button>
          </form>

          {evaluationResult && (
            <ImmobilierEvaluationResultDisplay result={evaluationResult} />
          )}
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </CardContent>
      </Card>
    </div>
  );
};

export default ImmobilierEvaluationCalculator;