import React, { useState } from 'react';
import { Calculator, AlertCircle } from 'lucide-react';
import { plusValueService } from "@/services/plusValueService";
import PropertyInfo from './PropertyInfo';
import DatesPrices from './DatesPrices';
import WorksExpenses from './WorksExpenses';
import ExemptionCases from './ExemptionCases';
import ResultDisplay from './ResultDisplay';
import PropertyDismemberment from './PropertyDismemberment';

const INITIAL_STATE = {
  propertyType: '',
  dateAcquisition: '',
  dateVente: '',
  hasPromise: false,
  promiseDate: '',
  prixAcquisition: '',
  prixVente: '',
  soleOwner: true,
  ownershipShare: '',
  fraisAcquisitionForfait: true,
  fraisAcquisitionSaisis: '',
  travauxForfait: true,
  travauxRealisesSaisis: '',
  travauxEnergetiques: false,
  fraisVoirieReseaux: '',
  exonerations: {
    residencePrincipale: false,
    premiereCession: false,
    retraite: false,
    invalidite: false,
    expropriation: false
  },
  premiereCessionDetails: {
    benefice_anterieur: false,
    proprio_4_ans: false,
    remploi_24_mois: false
  },
  dateDepart: '',
  dureeActivite: '',
  categorieInvalidite: '',
  revenuFiscal: '',
  expropriationDetails: {
    remployIndemnite: false
  },
  // Ajout du démembrement
  demembrement: {
    type: 'pleine-propriete',
    age: '',
    valeurUsufruit: null,
    valeurNuePropriete: null
  }
};

const PlusValueCalculator = () => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setResults(null);
    setError(null);
  };

  const handleExonerationChange = (exoId, checked) => {
    setFormData(prev => ({
      ...prev,
      exonerations: {
        ...prev.exonerations,
        [exoId]: checked
      }
    }));
    setResults(null);
  };

  const handlePremiereCessionDetailChange = (detailId, checked) => {
    setFormData(prev => ({
      ...prev,
      premiereCessionDetails: {
        ...prev.premiereCessionDetails,
        [detailId]: checked
      }
    }));
    setResults(null);
  };

  const getDateCalcul = () => {
    return formData.hasPromise && formData.promiseDate
      ? formData.promiseDate
      : formData.dateVente;
  };

  const validateForm = () => {
    const errors = [];

    if (!formData.propertyType) {
      errors.push("Le type de bien est obligatoire");
    }
    
    if (!formData.dateAcquisition) {
      errors.push("La date d'acquisition est obligatoire");
    }
    
    if (!formData.dateVente) {
      errors.push("La date de vente est obligatoire");
    }
    
    if (!formData.prixAcquisition) {
      errors.push("Le prix d'acquisition est obligatoire");
    }
    
    if (!formData.prixVente) {
      errors.push("Le prix de vente est obligatoire");
    }

    if (!formData.soleOwner && (!formData.ownershipShare || formData.ownershipShare < 1 || formData.ownershipShare > 99)) {
      errors.push("La quote-part doit être comprise entre 1 et 99%");
    }

    // Validation spécifique au démembrement
    if (formData.demembrement.type !== 'pleine-propriete') {
      if (!formData.demembrement.age) {
        errors.push("L'âge de l'usufruitier est obligatoire en cas de démembrement");
      }
      if (formData.demembrement.age < 1 || formData.demembrement.age > 99) {
        errors.push("L'âge de l'usufruitier doit être compris entre 1 et 99 ans");
      }
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const validationErrors = validateForm();
      if (validationErrors.length > 0) {
        throw new Error(validationErrors.join('; '));
      }

      const calculDate = getDateCalcul();
      console.log('Date de calcul utilisée:', calculDate);
      
      const results = await plusValueService.calculate({
        ...formData,
        dateCalcul: calculDate
      });

      setResults(results);
      setError(null);

    } catch (error) {
      console.error('Erreur de calcul:', error);
      setError(error.message);
      setResults(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Calcul de plus-value immobilière
        </h1>
        <p className="mt-2 text-lg text-gray-600">
          Simulez votre plus-value selon les derniers textes en vigueur
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <form onSubmit={handleSubmit} className="space-y-6">
          <PropertyInfo 
            formData={formData}
            handleChange={handleChange}
          />
          <PropertyDismemberment
            formData={formData}
            handleChange={handleChange}
          />
          <DatesPrices
            formData={formData}
            handleChange={handleChange}
          />
          <WorksExpenses
            formData={formData}
            handleChange={handleChange}
          />
          <ExemptionCases
            formData={formData}
            handleChange={handleChange}
            handleExonerationChange={handleExonerationChange}
            handlePremiereCessionDetailChange={handlePremiereCessionDetailChange}
          />

          <div className="mt-6">
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg 
                shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <>
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                  Calcul en cours...
                </>
              ) : (
                <>
                  <Calculator className="w-5 h-5 mr-2" />
                  Calculer la plus-value
                </>
              )}
            </button>
          </div>
        </form>

        <div>
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {results && (
            <ResultDisplay results={results} formData={formData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlusValueCalculator;