import React, { useState, useRef, useEffect } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '@/lib/utils';
import { Portal } from '@/components/ui/portal';
import { motion, AnimatePresence } from 'framer-motion';

// Configuration des thèmes
const THEME_STYLES = {
  dark: {
    bg: 'bg-gray-900/95',
    text: 'text-white',
    border: 'border-gray-700'
  },
  light: {
    bg: 'bg-white/95',
    text: 'text-gray-900',
    border: 'border-gray-200'
  }
};

// Configuration des animations
const variants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    transition: { duration: 0.1, ease: 'easeInOut' }
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "spring", stiffness: 300, damping: 25 }
  }
};

// Composants de base
const TooltipProvider = TooltipPrimitive.Provider;
const TooltipTrigger = TooltipPrimitive.Trigger;

// Composant du contenu du tooltip
const TooltipContent = React.forwardRef(({ 
  className, 
  children,
  theme = 'dark',
  showArrow = true,
  sideOffset = 4,
  align = 'center',
  ...props 
}, ref) => {
  const themeStyles = THEME_STYLES[theme];

  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      align={align}
      className={cn(
        'z-50 overflow-hidden rounded-lg shadow-lg backdrop-blur-sm border p-3',
        'animate-in fade-in-0 zoom-in-95',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        themeStyles.bg,
        themeStyles.text,
        themeStyles.border,
        className
      )}
      {...props}
    >
      {children}
      {showArrow && (
        <TooltipPrimitive.Arrow 
          className={cn('fill-current', themeStyles.bg)} 
          width={12} 
          height={6}
        />
      )}
    </TooltipPrimitive.Content>
  );
});

TooltipContent.displayName = 'TooltipContent';

// Composant principal Tooltip
const Tooltip = React.forwardRef(({
  children,
  content,
  title,
  position = 'auto',
  theme = 'dark',
  maxWidth = 320,
  showArrow = true,
  delay = 0,
  className,
  ...props
}, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const showTimeoutRef = useRef(null);
  const hideTimeoutRef = useRef(null);

  const updatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;
    
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    let pos = { x: 0, y: 0 };

    // Calcul position optimale
    switch (position) {
      case 'top':
        pos.x = triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2);
        pos.y = triggerRect.top - tooltipRect.height - 8;
        break;
      case 'bottom':
        pos.x = triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2);
        pos.y = triggerRect.bottom + 8;
        break;
      case 'left':
        pos.x = triggerRect.left - tooltipRect.width - 8;
        pos.y = triggerRect.top + (triggerRect.height / 2) - (tooltipRect.height / 2);
        break;
      case 'right':
        pos.x = triggerRect.right + 8;
        pos.y = triggerRect.top + (triggerRect.height / 2) - (tooltipRect.height / 2);
        break;
      default:
        // Position auto
        pos = calculateOptimalPosition(triggerRect, tooltipRect);
    }

    setTooltipPosition(pos);
  };

  const calculateOptimalPosition = (triggerRect, tooltipRect) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const padding = 8;

    let x = triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2);
    let y = triggerRect.top - tooltipRect.height - padding;

    // Ajustements pour garder le tooltip dans la fenêtre
    x = Math.max(padding, Math.min(x, viewportWidth - tooltipRect.width - padding));
    y = Math.max(padding, Math.min(y, viewportHeight - tooltipRect.height - padding));

    return { x, y };
  };

  const handleShow = () => {
    if (delay) {
      showTimeoutRef.current = setTimeout(() => setIsVisible(true), delay);
    } else {
      setIsVisible(true);
    }
  };

  const handleHide = () => {
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      updatePosition();
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition, true);
      
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition, true);
      };
    }
  }, [isVisible]);

  useEffect(() => {
    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
    };
  }, []);

  return (
    <TooltipProvider>
      <div
        ref={triggerRef}
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        onFocus={handleShow}
        onBlur={handleHide}
        className={cn("inline-block", className)}
        {...props}
      >
        {children}

        <Portal>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                ref={tooltipRef}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                style={{
                  position: 'fixed',
                  left: tooltipPosition.x,
                  top: tooltipPosition.y,
                  maxWidth,
                  zIndex: 50
                }}
                className="pointer-events-none"
              >
                <div
                  className={cn(
                    'relative rounded-lg shadow-lg backdrop-blur-sm border p-3',
                    THEME_STYLES[theme].bg,
                    THEME_STYLES[theme].text,
                    THEME_STYLES[theme].border
                  )}
                >
                  {title && (
                    <div className={cn(
                      'font-medium mb-1 pb-1 border-b',
                      THEME_STYLES[theme].border
                    )}>
                      {title}
                    </div>
                  )}
                  
                  <div className="text-sm whitespace-pre-wrap">
                    {content}
                  </div>

                  {showArrow && (
                    <div className={cn(
                      'absolute w-2.5 h-2.5 transform rotate-45',
                      THEME_STYLES[theme].bg,
                      {
                        'bottom-[-5px] left-1/2 -translate-x-1/2': position === 'top',
                        'top-[-5px] left-1/2 -translate-x-1/2': position === 'bottom',
                        'right-[-5px] top-1/2 -translate-y-1/2': position === 'left',
                        'left-[-5px] top-1/2 -translate-y-1/2': position === 'right'
                      }
                    )} />
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </Portal>
      </div>
    </TooltipProvider>
  );
});

Tooltip.displayName = 'Tooltip';

export { 
  Tooltip, 
  TooltipTrigger, 
  TooltipContent, 
  TooltipProvider 
};

export default Tooltip;