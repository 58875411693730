// actes/immobilier.js
export const ACTES_IMMOBILIER = [
    {
      id: "vente-gre-a-gre",
      label: "Vente de gré à gré",
      reference: "Art. A444-91",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 3.870 },
        { jusqu_a: 17000, taux: 1.596 },
        { jusqu_a: 60000, taux: 1.064 },
        { au_dela: true, taux: 0.799 }
      ],
      specificites: {
        tva_applicable: true,
        necessite_type_bien: true
      }
    },
    {
      id: "vente-viager",
      label: "Vente en viager",
      reference: "Art. A444-91",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 3.870 },
        { jusqu_a: 17000, taux: 1.596 },
        { jusqu_a: 60000, taux: 1.064 },
        { au_dela: true, taux: 0.799 }
      ],
      specificites: {
        type: "VIAGER",
        champsSup: ["bouquet", "rente", "age"]
      }
    },
    {
      id: "vente-vefa",
      label: "Vente en l'état futur d'achèvement",
      reference: "Art. A444-91",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 3.870 },
        { jusqu_a: 17000, taux: 1.596 },
        { jusqu_a: 60000, taux: 1.064 },
        { au_dela: true, taux: 0.799 }
      ],
      specificites: {
        tva_applicable: true,
        type_bien: "NEW"
      }
    },
    {
      id: "promesse-vente",
      label: "Promesse unilatérale de vente",
      reference: "Art. A444-149",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "compromis",
      label: "Promesse synallagmatique de vente",
      reference: "Art. A444-150",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "bail-habitation",
      label: "Bail d'habitation",
      reference: "Art. A444-103",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "bail-commercial",
      label: "Bail commercial",
      reference: "Art. A444-104",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "etat-descriptif",
      label: "État descriptif de division",
      reference: "Art. A444-110",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "reglement-copropriete",
      label: "Règlement de copropriété",
      reference: "Art. A444-108",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "servitude",
      label: "Constitution de servitude",
      reference: "Art. A444-102",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    }
  ];