import React from "react";
import { ScrollText, Shield, Scale, AlertCircle, Clock, HelpCircle } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">
          Conditions Générales d'Utilisation
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          NotariaPrime - Calculateur de tarifs notariaux
        </p>
      </div>

      <div className="space-y-8">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
              Préambule
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Les présentes conditions générales régissent l'utilisation du calculateur NotariaPrime, 
              service en ligne de calcul des émoluments, débours et taxes notariales.
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Scale className="w-5 h-5 mr-2 text-blue-600" />
              Service proposé
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <p>
                NotariaPrime propose un outil de calcul basé sur :
              </p>
              <ul className="list-disc ml-6 space-y-2">
                <li>L'annexe 4-7 du Code de commerce</li>
                <li>L'arrêté du 28 février 2020</li>
                <li>La table 5 pour les remises</li>
              </ul>
              <p className="mt-4 text-sm bg-yellow-50 p-4 rounded">
                Les calculs fournis sont indicatifs et doivent être vérifiés par un professionnel du notariat.
              </p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Shield className="w-5 h-5 mr-2 text-blue-600" />
              Responsabilités
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <h3 className="font-medium">Notre responsabilité :</h3>
              <ul className="list-disc ml-6 space-y-2">
                <li>Maintenir le service accessible</li>
                <li>Mettre à jour les calculs selon la réglementation</li>
                <li>Assurer la sécurité des données</li>
              </ul>
              <h3 className="font-medium mt-6">Votre responsabilité :</h3>
              <ul className="list-disc ml-6 space-y-2">
                <li>Vérifier les calculs obtenus</li>
                <li>Sécuriser vos accès</li>
                <li>Respecter les conditions d'utilisation</li>
              </ul>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <AlertCircle className="w-5 h-5 mr-2 text-blue-600" />
              Limitations
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <p>NotariaPrime ne peut être tenu responsable :</p>
              <ul className="list-disc ml-6 space-y-2">
                <li>Des erreurs dans les données saisies</li>
                <li>Des cas particuliers non prévus par les textes</li>
                <li>De l'utilisation des résultats sans vérification</li>
              </ul>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <HelpCircle className="w-5 h-5 mr-2 text-blue-600" />
              Support et assistance
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <p>Une assistance est disponible :</p>
              <ul className="list-disc ml-6 space-y-2">
                <li>Par email : contact@notariaprime.fr</li>
                <li>Du lundi au vendredi, 9h-18h</li>
                <li>Réponse sous 48h ouvrées</li>
              </ul>
            </div>
          </CardContent>
        </Card>

        <div className="mt-8 text-sm text-gray-500 text-center">
          <p>Dernière mise à jour : {new Date().toLocaleDateString()}</p>
          <p>
            Pour toute question : contact@notariaprime.fr
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
