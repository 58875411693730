// components/tabs/TaxesTab.jsx
import React from 'react';
import { HelpCircle, AlertCircle } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";

// Constantes locales
const TAXES = {
  TVA: {
    TAUX: 0.20,
    APPLICABLE_SUR: ['EMOLUMENTS', 'FORMALITES', 'COPIES']
  },
  DROITS_MUTATION: {
    ANCIEN: {
      TAUX: 0.05806625,  // 5.80665%
      DETAIL: {
        DEPARTEMENT: 0.04,          // 4.00%
        COMMUNE: 0.012,             // 1.20%
        FRAIS_ASSIETTE: 0.00234625  // 0.234625%
      }
    },
    NEUF: {
      TAUX: 0.00715,  // 0.715%
      TVA: 0.20       // 20%
    }
  }
};

const TaxesTab = ({ formData, setFormData }) => {
  React.useEffect(() => {
    if (!formData.taxes) {
      setFormData(prev => ({
        ...prev,
        taxes: {
          type: 'old',
          hasVAT: false,
          marginBased: false,
          margin: 0,
          hasDepartmentalTax: true,
          hasMunicipalTax: true
        }
      }));
    }
  }, []);

  const updateTaxes = (field, value) => {
    setFormData(prev => ({
      ...prev,
      taxes: {
        ...prev.taxes,
        [field]: value
      }
    }));
  };

  // Calcul des différentes taxes
  const calculateTaxes = () => {
    const amount = parseFloat(formData.baseAmount) || 0;
    const { taxes = {} } = formData;

    // TVA Immobilière (pour les biens neufs)
    const vat = taxes.type === 'new' ? amount * TAXES.TVA.TAUX : 0;

    // Droits de mutation selon le type de bien
    const transferTax = taxes.type === 'new' 
      ? amount * TAXES.DROITS_MUTATION.NEUF.TAUX
      : amount * TAXES.DROITS_MUTATION.ANCIEN.TAUX;

    // Détail des taxes départementales et communales
    const departmentalTax = taxes.hasDepartmentalTax && taxes.type !== 'new'
      ? amount * TAXES.DROITS_MUTATION.ANCIEN.DETAIL.DEPARTEMENT 
      : 0;
    
    const municipalTax = taxes.hasMunicipalTax && taxes.type !== 'new'
      ? amount * TAXES.DROITS_MUTATION.ANCIEN.DETAIL.COMMUNE 
      : 0;

    const assessmentFees = taxes.type !== 'new'
      ? amount * TAXES.DROITS_MUTATION.ANCIEN.DETAIL.FRAIS_ASSIETTE
      : 0;

    return {
      vat,
      transferTax,
      departmentalTax,
      municipalTax,
      assessmentFees,
      total: vat + transferTax + departmentalTax + municipalTax + assessmentFees
    };
  };

  const taxes = calculateTaxes();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="font-medium text-lg">Taxes et droits</h3>
        <div className="text-gray-500 text-sm">
          Total des taxes: {taxes.total.toFixed(2)} €
        </div>
      </div>

      {/* Type de bien (ancien/neuf) */}
      <div className="bg-white p-4 rounded-lg border">
        <div className="mb-4">
          <div className="flex items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">Type de bien</label>
            <Tooltip content="Le régime de TVA dépend de la nature et de l'âge du bien">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <div className="mt-2 space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                checked={formData.taxes?.type === 'old'}
                onChange={() => updateTaxes('type', 'old')}
                className="mr-2"
              />
              <span>Ancien ({(TAXES.DROITS_MUTATION.ANCIEN.TAUX * 100).toFixed(3)}%)</span>
              <Tooltip content="Bien de plus de 5 ans ou déjà occupé">
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                checked={formData.taxes?.type === 'new'}
                onChange={() => updateTaxes('type', 'new')}
                className="mr-2"
              />
              <span>Neuf ({(TAXES.DROITS_MUTATION.NEUF.TAUX * 100).toFixed(3)}% + TVA)</span>
              <Tooltip content="Bien neuf, VEFA ou moins de 5 ans après achèvement">
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </label>
          </div>
        </div>
      </div>

      {/* Détail des taxes */}
      <div className="space-y-4">
        {/* TVA Immobilière */}
        {formData.taxes?.type === 'new' && (
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Percent className="w-4 h-4 mr-2" />
                <div className="flex items-center">
                  <span className="font-medium">TVA Immobilière (20%)</span>
                  <Tooltip content="TVA applicable aux logements neufs">
                    <button className="ml-2 text-gray-400 hover:text-gray-600">
                      <HelpCircle className="w-4 h-4" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <span>{taxes.vat.toFixed(2)} €</span>
            </div>
          </div>
        )}

        {/* Droits de mutation */}
        <div className="bg-white p-4 rounded-lg border">
          <div className="space-y-4">
            {/* Taxe départementale */}
            {formData.taxes?.type === 'old' && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span>Taxe départementale ({(TAXES.DROITS_MUTATION.ANCIEN.DETAIL.DEPARTEMENT * 100).toFixed(1)}%)</span>
                  <Tooltip content="Part départementale des droits de mutation">
                    <button className="ml-2 text-gray-400 hover:text-gray-600">
                      <HelpCircle className="w-4 h-4" />
                    </button>
                  </Tooltip>
                </div>
                <span>{taxes.departmentalTax.toFixed(2)} €</span>
              </div>
            )}

            {/* Taxe communale */}
            {formData.taxes?.type === 'old' && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span>Taxe communale ({(TAXES.DROITS_MUTATION.ANCIEN.DETAIL.COMMUNE * 100).toFixed(1)}%)</span>
                  <Tooltip content="Part communale des droits de mutation">
                    <button className="ml-2 text-gray-400 hover:text-gray-600">
                      <HelpCircle className="w-4 h-4" />
                    </button>
                  </Tooltip>
                </div>
                <span>{taxes.municipalTax.toFixed(2)} €</span>
              </div>
            )}

            {/* Frais d'assiette */}
            {formData.taxes?.type === 'old' && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span>Frais d'assiette ({(TAXES.DROITS_MUTATION.ANCIEN.DETAIL.FRAIS_ASSIETTE * 100).toFixed(3)}%)</span>
                  <Tooltip content="Frais d'assiette et de recouvrement">
                    <button className="ml-2 text-gray-400 hover:text-gray-600">
                      <HelpCircle className="w-4 h-4" />
                    </button>
                  </Tooltip>
                </div>
                <span>{taxes.assessmentFees.toFixed(2)} €</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Note informative */}
      <div className="mt-6">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <AlertCircle className="w-5 h-5 text-yellow-400 mr-2" />
            <div className="text-sm text-yellow-700">
              <p className="font-medium">Informations importantes :</p>
              <ul className="mt-2 list-disc pl-5 space-y-1">
                <li>Les droits de mutation sont des taxes fixes définies par la loi</li>
                <li>La TVA immobilière ne s'applique qu'aux logements neufs</li>
                <li>Ces montants sont donnés à titre indicatif et peuvent varier selon la commune</li>
                <li>Certains cas particuliers peuvent bénéficier d'exonérations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxesTab;