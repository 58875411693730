import React from 'react';
import { Tab } from "@headlessui/react";
import { AlertCircle, HelpCircle } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";
import { TABS, TAB_VALIDATIONS } from '../config';

export const CalculatorTabs = ({
  activeTab,
  onTabChange,
  formData,
  validationErrors,
  children
}) => (
  <Tab.Group selectedIndex={activeTab} onChange={onTabChange}>
    <Tab.List className="flex space-x-8 border-b border-gray-200">
      {TABS.map((tab) => (
        <Tab
          key={tab.id}
          className={({ selected }) =>
            `group relative whitespace-nowrap pb-4 px-1 border-b-2 font-medium 
            text-sm focus:outline-none 
            ${selected 
              ? 'border-blue-500 text-blue-600' 
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`
          }
        >
          <div className="flex items-center space-x-2">
            <tab.icon className="w-4 h-4" />
            <span>{tab.label}</span>
            {validationErrors[tab.id] ? (
              <AlertCircle className="w-4 h-4 text-red-500" />
            ) : TAB_VALIDATIONS[tab.id]?.(formData) ? (
              <tab.validateIcon className="w-4 h-4 text-green-500" />
            ) : null}
            <Tooltip content={tab.help}>
              <HelpCircle className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
            </Tooltip>
          </div>
          {validationErrors[tab.id] && (
            <Tooltip content={validationErrors[tab.id]}>
              <div className="absolute -bottom-8 left-0 right-0 text-center text-xs text-red-500">
                ⚠️
              </div>
            </Tooltip>
          )}
        </Tab>
      ))}
    </Tab.List>

    <Tab.Panels className="mt-8">{children}</Tab.Panels>
  </Tab.Group>
);