// services/calculationService/debours.js

export class DeboursService {
  // Constantes réglementaires
  static CSI = {
    TAUX: 0.001, // 0.1%
    MINIMUM: 15,
    types: {
      PROPORTIONNEL: 'proportional',
      FIXE: 'fixed'
    }
  };

  static ETAT_HYPOTHECAIRE = {
    HORS_FORMALITE: 12,  // État hors formalité
    SUR_FORMALITE: 30,   // État sur formalité (urgent)
    TYPES: {
      STANDARD: 'standard',
      URGENT: 'urgent'
    }
  };

  static EXTRAIT_CADASTRAL = {
    PRIX_UNITAIRE: 12,
    URGENT: {
      PRIX_UNITAIRE: 24
    }
  };

  static URBANISME = {
    CERTIFICAT: {
      PRIX: 40,
      DELAI: 'normal'
    },
    ALIGNEMENT: {
      PRIX: 25,
      DELAI: 'normal'
    },
    NOTE_RENSEIGNEMENT: {
      PRIX: 35,
      DELAI: 'normal'
    }
  };

  /**
   * Calcule l'ensemble des débours
   */
  static calculerDebours(formData) {
    try {
      const result = {
        details: {},
        total: 0
      };

      // 1. Calcul de la CSI
      const csi = this.calculerCSI(formData);
      result.details.csi = csi;
      result.total += csi.montant;

      // 2. Calcul des états hypothécaires
      const etatsHypo = this.calculerEtatsHypothecaires(formData);
      result.details.etatsHypothecaires = etatsHypo;
      result.total += etatsHypo.total;

      // 3. Calcul des extraits cadastraux
      const extraitsCad = this.calculerExtraitsCadastraux(formData);
      result.details.extraitsCadastraux = extraitsCad;
      result.total += extraitsCad.total;

      // 4. Calcul des frais d'urbanisme
      const urbanisme = this.calculerUrbanisme(formData);
      result.details.urbanisme = urbanisme;
      result.total += urbanisme.total;

      // Arrondir le total à 2 décimales
      result.total = Math.round(result.total * 100) / 100;

      return result;

    } catch (error) {
      console.error('Erreur dans le calcul des débours:', error);
      return this.getEmptyResult(error.message);
    }
  }

  /**
   * Calcule la Contribution de Sécurité Immobilière (CSI)
   */
  static calculerCSI(formData) {
    const { baseAmount = 0, debours = {} } = formData;
    const csiType = debours.csi?.type || this.CSI.types.PROPORTIONNEL;

    let montant = this.CSI.MINIMUM;
    const details = {
      type: csiType,
      baseCalcul: baseAmount,
      tauxApplique: this.CSI.TAUX
    };

    if (csiType === this.CSI.types.PROPORTIONNEL && baseAmount > 0) {
      montant = Math.max(baseAmount * this.CSI.TAUX, this.CSI.MINIMUM);
    }

    return {
      montant,
      details
    };
  }

  /**
   * Calcule les frais d'états hypothécaires
   */
  static calculerEtatsHypothecaires(formData) {
    const { debours = {} } = formData;
    const states = debours.mortgageStatements || { regularCount: 0, urgentCount: 0 };

    const standard = {
      count: states.regularCount || 0,
      prixUnitaire: this.ETAT_HYPOTHECAIRE.HORS_FORMALITE,
      total: (states.regularCount || 0) * this.ETAT_HYPOTHECAIRE.HORS_FORMALITE
    };

    const urgent = {
      count: states.urgentCount || 0,
      prixUnitaire: this.ETAT_HYPOTHECAIRE.SUR_FORMALITE,
      total: (states.urgentCount || 0) * this.ETAT_HYPOTHECAIRE.SUR_FORMALITE
    };

    return {
      standard,
      urgent,
      total: standard.total + urgent.total,
      details: {
        nombreTotal: standard.count + urgent.count
      }
    };
  }

  /**
   * Calcule les frais d'extraits cadastraux
   */
  static calculerExtraitsCadastraux(formData) {
    const { debours = {} } = formData;
    const extraits = debours.cadastral || { count: 0 };

    const standard = {
      count: extraits.count || 0,
      prixUnitaire: this.EXTRAIT_CADASTRAL.PRIX_UNITAIRE,
      total: (extraits.count || 0) * this.EXTRAIT_CADASTRAL.PRIX_UNITAIRE
    };

    return {
      standard,
      total: standard.total,
      details: {
        nombreExtraits: standard.count
      }
    };
  }

  /**
   * Calcule les frais d'urbanisme
   */
  static calculerUrbanisme(formData) {
    const { debours = {} } = formData;
    let total = 0;
    const details = {};

    if (debours.demandeCU) {
      details.certificatUrbanisme = {
        prix: this.URBANISME.CERTIFICAT.PRIX,
        delai: this.URBANISME.CERTIFICAT.DELAI
      };
      total += this.URBANISME.CERTIFICAT.PRIX;
    }

    if (debours.demandeAlignement) {
      details.alignement = {
        prix: this.URBANISME.ALIGNEMENT.PRIX,
        delai: this.URBANISME.ALIGNEMENT.DELAI
      };
      total += this.URBANISME.ALIGNEMENT.PRIX;
    }

    return {
      details,
      total
    };
  }

  /**
   * Retourne un résultat vide avec message d'erreur
   */
  static getEmptyResult(error = '') {
    return {
      details: {
        error
      },
      total: 0
    };
  }

  /**
   * Valide les données d'entrée des débours
   */
  static validateDebours(debours) {
    const errors = [];

    // Validation CSI
    if (debours?.csi && !Object.values(this.CSI.types).includes(debours.csi.type)) {
      errors.push("Type de CSI invalide");
    }

    // Validation états hypothécaires
    if (debours?.mortgageStatements) {
      if (debours.mortgageStatements.regularCount < 0) {
        errors.push("Le nombre d'états hypothécaires ne peut pas être négatif");
      }
      if (debours.mortgageStatements.urgentCount < 0) {
        errors.push("Le nombre d'états hypothécaires urgents ne peut pas être négatif");
      }
    }

    // Validation extraits cadastraux
    if (debours?.cadastral && debours.cadastral.count < 0) {
      errors.push("Le nombre d'extraits cadastraux ne peut pas être négatif");
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  /**
   * Vérifie si des états hypothécaires sont nécessaires
   */
  static necessiteEtatsHypothecaires(formData) {
    const actesNecessitant = ['vente-gre-a-gre', 'pret-hypothecaire', 'mainlevee'];
    return actesNecessitant.includes(formData.selectedAct?.id);
  }

  /**
   * Vérifie si des extraits cadastraux sont nécessaires
   */
  static necessiteExtraitsCadastraux(formData) {
    const actesNecessitant = ['vente-gre-a-gre', 'vente-viager', 'donation'];
    return actesNecessitant.includes(formData.selectedAct?.id);
  }
}

export default DeboursService;