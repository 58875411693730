import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X, ArrowLeft, LayoutDashboard } from "lucide-react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Pages qui devraient afficher le bouton retour
  const pagesWithBackButton = ['/documents', '/settings', '/taxation', '/plusvalue', '/profile', '/evaluation-immobiliere'];
  const shouldShowBackButton = pagesWithBackButton.includes(location.pathname);

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            {/* Bouton retour au tableau de bord */}
            {shouldShowBackButton && (
              <Link
                to="/dashboard"
                className="flex items-center text-gray-600 hover:text-gray-900 mr-4"
              >
                <ArrowLeft className="h-5 w-5 mr-1" />
                <span>Tableau de bord</span>
              </Link>
            )}
            
            {/* Logo */}
            <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Notaria Prime
            </Link>
          </div>
          
          {/* Navigation desktop */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-gray-700 hover:text-gray-900">
              Accueil
            </Link>
            <Link to="/taxation" className="text-gray-700 hover:text-gray-900">
              Taxation
            </Link>
            <Link to="/plusvalue" className="text-gray-700 hover:text-gray-900">
              Plus-value
            </Link>
            <Link to="/evaluation-immobiliere" className="text-gray-700 hover:text-gray-900">
              Évaluation immobilière
            </Link>
            {/* Lien vers le dashboard */}
            <Link 
              to="/dashboard" 
              className="flex items-center text-gray-700 hover:text-gray-900"
            >
              <LayoutDashboard className="h-5 w-5 mr-1" />
              Mon Compte
            </Link>
            <Link 
              to="/essai-gratuit" 
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Essai gratuit
            </Link>
          </div>
          
          {/* Bouton menu mobile */}
          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-gray-700" />
            ) : (
              <Menu className="h-6 w-6 text-gray-700" />
            )}
          </button>
        </div>

        {/* Menu mobile */}
        {isMenuOpen && (
          <div className="md:hidden mt-4 space-y-4">
            <Link 
              to="/" 
              className="block text-gray-700 hover:text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              Accueil
            </Link>
            <Link 
              to="/taxation" 
              className="block text-gray-700 hover:text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              Taxation
            </Link>
            <Link 
              to="/plusvalue" 
              className="block text-gray-700 hover:text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              Plus-value
            </Link>
            <Link 
              to="/evaluation-immobiliere" 
              className="block text-gray-700 hover:text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              Évaluation immobilière
            </Link>
            <Link 
              to="/dashboard" 
              className="block text-gray-700 hover:text-gray-900"
              onClick={() => setIsMenuOpen(false)}
            >
              <span className="flex items-center">
                <LayoutDashboard className="h-5 w-5 mr-1" />
                Mon Compte
              </span>
            </Link>
            <Link 
              to="/essai-gratuit" 
              className="block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Essai gratuit
            </Link>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;