// Calculator/index.jsx
import React, { useState, useCallback } from 'react';
import { useCalculator } from './hooks/useCalculator';
import { useTabs } from './hooks/useTabs';
import { useHistory } from './hooks/useHistory';
import { CalculatorHeader } from './components/CalculatorHeader';
import { CalculatorTabs } from './components/CalculatorTabs';
import { CalculatorForm } from './components/CalculatorForm';
import { CalculatorActions } from './components/CalculatorActions';
import { CalculatorErrors } from './components/CalculatorErrors';
import { ResetModal } from './components/modals/ResetModal';
import { HistoryModal } from './components/modals/HistoryModal';
import { ResultsDisplay } from '../ResultsDisplay';
import { initialFormState } from './config';
import { ACTES_CATEGORIES } from '@/constants/calculator';

const Calculator = () => {
  const {
    formData,
    setFormData,
    results,
    setResults,
    calculationError,
    setCalculationError,
    isCalculating,
    validationErrors,
    setValidationErrors,
    calculate
  } = useCalculator();

  const {
    activeTab,
    handleTabChange
  } = useTabs(formData, setValidationErrors);

  const {
    savedCalculations,
    isSaving,
    showHistory,
    setShowHistory,
    handleSave
  } = useHistory(formData, results);

  const [showConfirmReset, setShowConfirmReset] = useState(false);

  const handleCategoryChange = useCallback((categoryId) => {
    setFormData(prev => ({
      ...prev,
      selectedCategory: categoryId,
      selectedAct: null,
      specifics: {
        ...initialFormState.specifics,
        isHLM: categoryId === 'IMMOBILIER' ? prev.specifics.isHLM : false,
      }
    }));
  }, []);

  const handleActChange = useCallback((actId) => {
    const category = ACTES_CATEGORIES[formData.selectedCategory];
    const act = category?.actes?.find(a => a.id === actId);
    
    setFormData(prev => ({
      ...prev,
      selectedAct: act,
      specifics: {
        ...prev.specifics,
        isViager: act?.specificites?.type === "VIAGER",
        viagerDetails: act?.specificites?.type === "VIAGER" 
          ? { bouquet: 0, rente: 0, age: 0 }
          : null,
        hasPret: act?.specificites?.hasPret || false,
        hasHypothèque: act?.specificites?.hasHypothèque || false
      },
      debours: {
        ...prev.debours,
        csi: { 
          type: act?.type === 'PROPORTIONNEL' ? 'proportional' : 'fixed',
          isUrgent: false
        }
      }
    }));
  }, [formData.selectedCategory]);

  const handleBaseAmountChange = useCallback((value) => {
    const numValue = Number(value);
    if (isNaN(numValue) || numValue < 0) {
      setValidationErrors(prev => ({
        ...prev,
        baseAmount: "Le montant doit être un nombre positif"
      }));
      return;
    }

    setFormData(prev => ({
      ...prev,
      baseAmount: value,
      options: {
        ...prev.options,
        discount: Math.min(prev.options.discount || 0, 40)
      }
    }));

    setValidationErrors(prev => ({
      ...prev,
      baseAmount: undefined
    }));
  }, [setValidationErrors]);

  const handleReset = useCallback(() => {
    setFormData(initialFormState);
    setResults(null);
    setCalculationError(null);
    setValidationErrors({});
    setShowConfirmReset(false);
  }, [setFormData, setResults, setCalculationError, setValidationErrors]);

  const handleHistorySelect = useCallback((calc) => {
    setFormData(calc.formData);
    setResults(calc.results);
    setShowHistory(false);
    setCalculationError(null);
    setValidationErrors({});
  }, [setFormData, setResults, setCalculationError, setValidationErrors]);

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        <CalculatorHeader
          savedCalculations={savedCalculations}
          onShowHistory={() => setShowHistory(true)}
          onShowReset={() => setShowConfirmReset(true)}
          hasChanges={formData !== initialFormState || !!results}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-6">
            <CalculatorTabs
              activeTab={activeTab}
              onTabChange={handleTabChange}
              formData={formData}
              validationErrors={validationErrors}
            >
              <CalculatorForm
                activeTab={activeTab}
                formData={formData}
                setFormData={setFormData}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                onCategoryChange={handleCategoryChange}
                onActChange={handleActChange}
                onBaseAmountChange={handleBaseAmountChange}
              />
            </CalculatorTabs>

            <CalculatorActions
              onCalculate={calculate}
              onSave={handleSave}
              isCalculating={isCalculating}
              isSaving={isSaving}
              hasValidationErrors={Object.keys(validationErrors).length > 0}
              hasResults={!!results}
            />

            <CalculatorErrors
              calculationError={calculationError}
              validationErrors={validationErrors}
            />
          </div>

          {results && (
            <div className="lg:sticky lg:top-8">
              <ResultsDisplay
                results={results}
                formData={formData}
                onSave={handleSave}
                isSaving={isSaving}
              />
            </div>
          )}
        </div>
      </div>

      {showConfirmReset && (
        <ResetModal
          hasResults={!!results}
          onConfirm={handleReset}
          onCancel={() => setShowConfirmReset(false)}
        />
      )}

      {showHistory && (
        <HistoryModal
          calculations={savedCalculations}
          onSelect={handleHistorySelect}
          onClose={() => setShowHistory(false)}
        />
      )}
    </div>
  );
};

export default Calculator;