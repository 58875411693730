import { useState, useCallback } from 'react';
import { TAB_VALIDATIONS } from '../config';

export const useTabs = (formData, setValidationErrors) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback((index, currentTabId) => {
    // Validation de l'onglet actuel avant changement
    const isValid = TAB_VALIDATIONS[currentTabId]?.(formData) ?? true;

    if (!isValid) {
      setValidationErrors(prev => ({
        ...prev,
        [currentTabId]: "Veuillez compléter tous les champs obligatoires"
      }));
      return false;
    }

    setActiveTab(index);
    return true;
  }, [formData, setValidationErrors]);

  return {
    activeTab,
    handleTabChange
  };
};