// src/components/immobilier/ImmobilierEvaluationResultDisplay.jsx

import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { formatCurrency } from '@/utils/format';

const ImmobilierEvaluationResultDisplay = ({ result }) => {
  return (
    <Card className="mt-8">
      <CardHeader>
        <CardTitle>Résultats de l'évaluation</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <p>
            Prix au m² estimé :{' '}
            <strong>
              {formatCurrency(result.prixM2.min)} - {formatCurrency(result.prixM2.max)}
            </strong>
          </p>
          <p>
            Valeur vénale estimée :{' '}
            <strong>{formatCurrency(result.valeurVenale)}</strong>
          </p>
          <p>
            Valeur locative estimée :{' '}
            <strong>{formatCurrency(result.valeurLocative)}</strong> / mois
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default ImmobilierEvaluationResultDisplay;