// Import des différents modules
import {
  CALCUL_CONSTANTS,
  ROUNDING,
  VALIDATION_RULES,
  ERROR_CODES
} from './base';

import {
  TAXES,
  TVA_HELP_TEXTS
} from './taxes';

// Import des modules d'actes
import ACTES_CATEGORIES from './actes/index';
import { CONFIG_REMISES } from './actes/config';
import {
  TYPES_TARIFICATION,
  SPECIFICITES,
  REFERENCES_LEGALES
} from './actes/divers';

import {
  SPECIAL_RULES,
  HELP_TEXTS,
  DEBOURS_HELP
} from './rules';

import {
  FORMALITES,
  DOCUMENTS,
  DEBOURS
} from './fees';

// Groupement thématique des constantes
export const CALCULATOR = Object.freeze({
  // Constantes de base
  BASE: {
    ...CALCUL_CONSTANTS,
    ROUNDING,
    VALIDATION: VALIDATION_RULES,
    ERRORS: ERROR_CODES
  },

  // Taxes et TVA
  TAXES: {
    ...TAXES,
    HELP: TVA_HELP_TEXTS
  },

  // Actes et configurations spécifiques
  ACTES: {
    CATEGORIES: ACTES_CATEGORIES,
    CONFIG: CONFIG_REMISES,
    TYPES: TYPES_TARIFICATION,
    SPECIFICITES: SPECIFICITES,
    REFERENCES: REFERENCES_LEGALES
  },

  // Règles spéciales et textes d'aide
  RULES: {
    SPECIAL: SPECIAL_RULES,
    HELP: HELP_TEXTS,
    DEBOURS_HELP
  },

  // Frais et débours
  FEES: {
    FORMALITES,
    DOCUMENTS,
    DEBOURS
  }
});

// Export des constantes individuelles pour la rétrocompatibilité
export {
  CALCUL_CONSTANTS,
  ROUNDING,
  VALIDATION_RULES,
  ERROR_CODES,
  TAXES,
  TVA_HELP_TEXTS,
  ACTES_CATEGORIES,
  CONFIG_REMISES,
  CONFIG_REMISES as SPECIFIC_CONFIGS, // Pour maintenir la compatibilité
  SPECIAL_RULES,
  HELP_TEXTS,
  DEBOURS_HELP,
  FORMALITES,
  DOCUMENTS,
  DEBOURS
};

// Types et définitions
export const TYPE_EMOLUMENT = Object.freeze({
  ...TYPES_TARIFICATION,
  FORFAITAIRE: 'FORFAITAIRE'  // Maintenu pour rétrocompatibilité
});

export const TYPES_ASSIETTE = Object.freeze({
  PRIX_VALEUR: "PRIX_VALEUR",
  CAPITAL: "CAPITAL",
  VALEUR_RESIDUELLE: "VALEUR_RESIDUELLE",
  MONTANT_PRET: "MONTANT_PRET",
  BENEFICE: "BENEFICE",
  LOYER_ANNUEL: "LOYER_ANNUEL",
  INDEMNITE: "INDEMNITE",
  RENTE_ANNUELLE: "RENTE_ANNUELLE",
  VALEUR_ECONOMIQUE: "VALEUR_ECONOMIQUE",
  EVALUATION_PARTIES: "EVALUATION_PARTIES"
});

export const CONDITIONS_SPECIALES = Object.freeze({
  DROIT_USAGE_HABITATION: "Droit d'usage et d'habitation",
  NUES_PROPRIETES: "Nues propriétés",
  USUFRUITS: "Usufruits",
  SERVITUDES: "Servitudes",
  BIENS_INDIVIS: "Biens indivis"
});

// Helpers
export const getCalculatorConstant = (path) => {
  try {
    return path.split('.').reduce((obj, key) => obj[key], CALCULATOR);
  } catch (error) {
    console.error(`Erreur lors de l'accès à la constante: ${path}`, error);
    return null;
  }
};

export const isValidCalculatorConstant = (path) => {
  const value = getCalculatorConstant(path);
  return value !== null && value !== undefined;
};

// Documentation
export const CONSTANTS_DOCUMENTATION = Object.freeze({
  version: "1.0.0",
  lastUpdate: "2024-02-01",
  source: "Code de commerce - Annexe 4-7",
  references: {
    emoluments: "Articles A. 444-53 à A. 444-186",
    tva: "Article 278 CGI",
    csi: "Articles 879 et 881 CGI"
  },
  help: "Pour plus d'informations, consultez la documentation officielle ou contactez le support"
});

export default CALCULATOR;