import React from 'react';
import { HelpCircle, RefreshCw } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";
import { HELP_TEXTS } from '@/constants/calculator';

export const CalculatorHeader = ({
  savedCalculations,
  onShowHistory,
  onShowReset,
  hasChanges
}) => (
  <div className="flex justify-between items-center">
    <div className="flex items-center space-x-4">
      <h2 className="text-2xl font-bold text-gray-900">
        Calculateur de frais notariés
      </h2>
      <Tooltip content={HELP_TEXTS.GENERAL}>
        <HelpCircle className="w-5 h-5 text-gray-400 cursor-help" />  
      </Tooltip>
      {savedCalculations.length > 0 && (
        <button
          onClick={onShowHistory}
          className="text-sm text-blue-600 hover:text-blue-800"
        >
          Historique ({savedCalculations.length})
        </button>
      )}
    </div>
    
    {hasChanges && (
      <button
        onClick={onShowReset}
        className="flex items-center px-4 py-2 text-gray-600 hover:text-gray-900 
          transition-colors rounded-lg hover:bg-gray-100"
      >
        <RefreshCw className="w-4 h-4 mr-2" />
        Nouveau calcul
      </button>
    )}
  </div>
);