import { EmolumentsService } from './emoluments';
import { DeboursService } from './debours';
import { FormalitesService } from './formalites';
import { DocumentsService } from './documents';
import { TaxesService } from './taxes';

class CalculationService {
  static VERSION = '1.0.0';
  static BASE_REGLEMENTAIRE = "Annexe 4-7 du Code de commerce, arrêté du 28 février 2020";

  constructor() {
    // Services de calcul
    this.services = {
      emoluments: EmolumentsService,
      debours: DeboursService,
      formalites: FormalitesService,
      documents: DocumentsService,
      taxes: TaxesService
    };

    // Liaison des méthodes
    this.calculateAll = this.calculateAll.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  /**
   * Effectue tous les calculs pour un acte notarié
   * @param {Object} formData - Données du formulaire
   * @returns {Promise<Object>} Résultats détaillés des calculs
   * @throws {Error} Si les données sont invalides ou si une erreur survient
   */
  async calculateAll(formData) {
    try {
      // 1. Validation des données
      this.validateInput(formData);

      console.log('Début des calculs avec les données:', formData);

      // 2. Calculs parallèles pour optimisation
      const [
        emoluments,
        debours,
        formalites,
        documents,
        taxes
      ] = await Promise.all([
        this.calculateEmoluments(formData),
        this.calculateDebours(formData),
        this.calculateFormalites(formData),
        this.calculateDocuments(formData),
        this.calculateTaxes(formData)
      ]);

      console.log('Résultats des calculs individuels:', {
        emoluments,
        debours,
        formalites,
        documents,
        taxes
      });

      // 3. Agrégation des résultats
      const total = this.calculateTotal({
        emoluments,
        debours,
        formalites,
        documents,
        taxes
      });

      console.log('Total calculé:', total);

      const results = {
        emoluments,
        debours,
        formalites,
        documents,
        taxes,
        total
      };

      // 4. Formatage et retour des résultats
      const formattedResults = this.formatResults(results, formData);
      console.log('Résultats formatés:', formattedResults);
      return formattedResults;

    } catch (error) {
      console.error('Erreur dans le calcul:', error);
      throw this.formatError(error);
    }
  }

  /**
   * Calculs individuels avec gestion des erreurs
   */
  async calculateEmoluments(formData) {
    try {
      const result = await this.services.emoluments.calculerEmoluments(formData);
      console.log('Résultat calcul émoluments:', result);
      return result || this.getEmptyResult('emoluments');
    } catch (error) {
      console.error('Erreur calcul émoluments:', error);
      return this.getEmptyResult('emoluments', error.message);
    }
  }

  async calculateDebours(formData) {
    try {
      const result = await this.services.debours.calculerDebours(formData);
      console.log('Résultat calcul débours:', result);
      return result || this.getEmptyResult('debours');
    } catch (error) {
      console.error('Erreur calcul débours:', error);
      return this.getEmptyResult('debours', error.message);
    }
  }

  async calculateFormalites(formData) {
    try {
      const result = await this.services.formalites.calculerFormalites(formData);
      console.log('Résultat calcul formalités:', result);
      return result || this.getEmptyResult('formalites');
    } catch (error) {
      console.error('Erreur calcul formalités:', error);
      return this.getEmptyResult('formalites', error.message);
    }
  }

  async calculateDocuments(formData) {
    try {
      const result = await this.services.documents.calculerDocuments(formData);
      console.log('Résultat calcul documents:', result);
      return result || this.getEmptyResult('documents');
    } catch (error) {
      console.error('Erreur calcul documents:', error);
      return this.getEmptyResult('documents', error.message);
    }
  }

  async calculateTaxes(formData) {
    try {
      const result = await this.services.taxes.calculerTaxes(formData);
      console.log('Résultat calcul taxes:', result);
      return result || this.getEmptyResult('taxes');
    } catch (error) {
      console.error('Erreur calcul taxes:', error);
      return this.getEmptyResult('taxes', error.message);
    }
  }

  /**
   * Validation des données d'entrée avec gestion détaillée des erreurs
   */
  validateInput(formData) {
    if (!formData) {
      throw new Error('Aucune donnée fournie');
    }

    console.log('Validation des données:', formData);
    const errors = [];

    // Validation de base
    if (!formData.selectedAct) {
      errors.push("Le type d'acte doit être spécifié");
    }

    if (formData.selectedAct?.type === 'PROPORTIONNEL' && (!formData.baseAmount || formData.baseAmount <= 0)) {
      errors.push("Le montant de base doit être supérieur à 0");
    }

    // Validation par service
    Object.entries(this.services).forEach(([name, service]) => {
      if (service.validateInput) {
        try {
          const validation = service.validateInput(formData);
          if (!validation.isValid) {
            errors.push(...validation.errors);
          }
        } catch (error) {
          errors.push(`Erreur de validation ${name}: ${error.message}`);
        }
      }
    });

    if (errors.length > 0) {
      console.error('Erreurs de validation:', errors);
      throw new Error(errors.join('; '));
    }
  }

  /**
   * Calcul du total avec vérification des valeurs
   */
  calculateTotal(results) {
    try {
      console.log('Calcul du total avec:', results);

      // Calcul du total HT
      const totalHT = this.sumSafe([
        Number(results.emoluments?.montants?.ht || 0),
        Number(results.debours?.total || 0),
        Number(results.formalites?.montants?.ht || 0),
        Number(results.documents?.montants?.ht || 0)
      ]);

      // Calcul de la TVA
      const totalTVA = this.sumSafe([
        Number(results.emoluments?.montants?.tva || 0),
        Number(results.formalites?.montants?.tva || 0),
        Number(results.documents?.montants?.tva || 0)
      ]);

      // Calcul des taxes
      const totalTaxes = Number(results.taxes?.total || 0);

      // Total TTC
      const totalTTC = totalHT + totalTVA + totalTaxes;

      const total = {
        ht: Math.round(totalHT * 100) / 100,
        tva: Math.round(totalTVA * 100) / 100,
        taxes: Math.round(totalTaxes * 100) / 100,
        ttc: Math.round(totalTTC * 100) / 100
      };

      console.log('Total calculé:', total);
      return total;

    } catch (error) {
      console.error('Erreur dans le calcul du total:', error);
      return { ht: 0, tva: 0, taxes: 0, ttc: 0 };
    }
  }

  /**
   * Formatage des résultats pour l'affichage
   */
  formatResults(results, formData) {
    console.log('Formatage des résultats:', results);

    const formattedResults = {
      sections: {
        emoluments: this.formatSection("Émoluments", results.emoluments || {}, "Rémunération de l'office notarial"),
        debours: this.formatSection("Débours", results.debours || {}, "Sommes acquittées pour le compte du client"),
        formalites: this.formatSection("Formalités", results.formalites || {}, "Démarches administratives obligatoires"),
        documents: this.formatSection("Documents", results.documents || {}, "Copies et autres documents délivrés"),
        taxes: this.formatSection("Taxes et droits", results.taxes || {}, "TVA et droits de mutation")
      },
      total: results.total,
      metadata: {
        version: CalculationService.VERSION,
        baseReglementaire: CalculationService.BASE_REGLEMENTAIRE,
        dateCalcul: new Date().toISOString(),
        parametres: this.sanitizeParams(formData)
      }
    };

    console.log('Résultats formatés:', formattedResults);
    return formattedResults;
  }

  /**
   * Méthodes utilitaires
   */
  getEmptyResult(type, error = null) {
    return {
      montants: { ht: 0, tva: 0, ttc: 0 },
      total: 0,
      details: error ? { error } : {},
      type
    };
  }

  sumSafe(values) {
    return values.reduce((sum, value) => {
      const num = Number(value);
      return sum + (isNaN(num) ? 0 : num);
    }, 0);
  }

  formatSection(label, data, help) {
    // S'assurer que data a toujours une structure valide
    const validatedData = {
      montants: {
        ht: Number(data.montants?.ht || 0),
        tva: Number(data.montants?.tva || 0),
        ttc: Number(data.montants?.ttc || 0)
      },
      total: Number(data.total || 0),
      details: data.details || {},
      ...data
    };

    return {
      label,
      help,
      ...validatedData
    };
  }

  formatError(error) {
    return new Error(`Erreur de calcul: ${error.message}`);
  }

  sanitizeParams(formData) {
    const { selectedAct, baseAmount } = formData;
    return {
      type: selectedAct?.id || 'inconnu',
      montant: Number(baseAmount || 0),
      date: new Date().toISOString()
    };
  }
}

// Export d'une instance unique du service
export const calculationService = new CalculationService();

// Export par défaut pour l'utilisation directe
export default calculationService;