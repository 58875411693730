import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Info } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const RegisterForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      raisonSociale: '',
      password: '',
      confirmPassword: ''
    },
    mode: 'onBlur'
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { register: registerUser } = useAuth();
  
  const password = watch('password');

  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        setError('confirmPassword', {
          type: 'manual',
          message: 'Les mots de passe ne correspondent pas'
        });
        return;
      }
      
      const { confirmPassword, ...registerData } = data;
      await registerUser(registerData);
      navigate('/dashboard', { replace: true });
    } catch (error) {
      setError('root', {
        type: 'manual',
        message: error.message || 'Une erreur est survenue lors de l\'inscription'
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-md">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Créer un compte
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ou{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
              connectez-vous à votre compte existant
            </Link>
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          {/* Nom complet */}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom complet
            </label>
            <input
              id="name"
              type="text"
              autoComplete="name"
              {...register('name', {
                required: 'Veuillez saisir votre nom complet',
                minLength: {
                  value: 2,
                  message: 'Le nom doit contenir au moins 2 caractères'
                }
              })}
              className={`mt-1 block w-full rounded-md shadow-sm
                ${errors.name ? 'border-red-300' : 'border-gray-300'}
                focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              placeholder="Jean Dupont"
            />
            {errors.name && (
              <p className="mt-2 text-sm text-red-600">{errors.name.message}</p>
            )}
          </div>

          {/* Raison sociale */}
          <div>
            <label htmlFor="raisonSociale" className="block text-sm font-medium text-gray-700">
              Raison sociale de l'étude
            </label>
            <input
              id="raisonSociale"
              type="text"
              {...register('raisonSociale', {
                required: 'Veuillez saisir la raison sociale de l\'étude'
              })}
              className={`mt-1 block w-full rounded-md shadow-sm
                ${errors.raisonSociale ? 'border-red-300' : 'border-gray-300'}
                focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              placeholder="Office Notarial de..."
            />
            {errors.raisonSociale && (
              <p className="mt-2 text-sm text-red-600">{errors.raisonSociale.message}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email professionnel
            </label>
            <input
              id="email"
              type="email"
              autoComplete="email"
              {...register('email', {
                required: 'Veuillez saisir votre email professionnel',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Veuillez saisir un email valide'
                }
              })}
              className={`mt-1 block w-full rounded-md shadow-sm
                ${errors.email ? 'border-red-300' : 'border-gray-300'}
                focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              placeholder="jean.dupont@notaires.fr"
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
            )}
          </div>

          {/* Mot de passe */}
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Mot de passe
            </label>
            <div className="mt-1 relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  required: 'Veuillez saisir un mot de passe',
                  minLength: {
                    value: 8,
                    message: 'Le mot de passe doit contenir au moins 8 caractères'
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                    message: 'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial'
                  }
                })}
                className={`block w-full pr-10 rounded-md shadow-sm
                  ${errors.password ? 'border-red-300' : 'border-gray-300'}
                  focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                placeholder="********"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 px-3 flex items-center"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
            )}
            <div className="mt-1 flex items-center text-sm text-gray-500">
              <Info className="h-4 w-4 mr-1" />
              Minimum 8 caractères, une majuscule, un chiffre et un caractère spécial
            </div>
          </div>

          {/* Confirmation mot de passe */}
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              Confirmer le mot de passe
            </label>
            <div className="mt-1 relative">
              <input
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                {...register('confirmPassword', {
                  required: 'Veuillez confirmer votre mot de passe',
                  validate: value => 
                    value === password || 'Les mots de passe ne correspondent pas'
                })}
                className={`block w-full pr-10 rounded-md shadow-sm
                  ${errors.confirmPassword ? 'border-red-300' : 'border-gray-300'}
                  focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                placeholder="********"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 px-3 flex items-center"
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="mt-2 text-sm text-red-600">{errors.confirmPassword.message}</p>
            )}
          </div>

          {/* Conditions générales */}
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                {...register('terms', {
                  required: 'Vous devez accepter les conditions générales'
                })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="font-medium text-gray-700">
                J'accepte les{' '}
                <Link to="/legal/terms" className="text-blue-600 hover:text-blue-500">
                  conditions générales
                </Link>
                {' '}et la{' '}
                <Link to="/legal/privacy" className="text-blue-600 hover:text-blue-500">
                  politique de confidentialité
                </Link>
              </label>
              {errors.terms && (
                <p className="mt-2 text-sm text-red-600">{errors.terms.message}</p>
              )}
            </div>
          </div>

          {/* Message d'erreur général */}
          {errors.root && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm font-medium text-red-800">
                {errors.root.message}
              </p>
            </div>
          )}

          {/* Bouton d'inscription */}
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              {isSubmitting ? (
                <div className="flex items-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                  Inscription en cours...
                </div>
              ) : (
                'Créer mon compte'
              )}
            </button>
          </div>
        </form>

        <div className="mt-6">
          <p className="text-center text-xs text-gray-600">
            En créant un compte, vous acceptez que vos données soient traitées conformément à notre{' '}
            <Link to="/legal/privacy" className="text-blue-600 hover:text-blue-500">
              politique de confidentialité
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
