/**
 * Service de gestion de l'authentification et des profils
 */
class AuthService {
  constructor() {
    this.baseUrl = import.meta.env.VITE_APP_API_URL || 'http://localhost:3000';
    this.tokenKey = 'authToken';
    this.userKey = 'userData';
    
    // Mode mock activé si pas d'URL d'API ou si explicitement demandé
    this.isMockMode = true; // Forcer le mode mock pour le test
    
    // Nettoyer les sessions au démarrage
    this.removeSession();
    this.removeUserData();
    
    if (this.isMockMode) {
        console.log('AuthService: Mode mock activé');
        
        // Créer l'utilisateur test
        const testUser = {
            id: '1',
            name: 'Test User',
            email: 'test@test.com',
            password: 'Test123!',
            dateCreation: new Date().toISOString(),
            dernierLogin: new Date().toISOString()
        };
        
        // Forcer la mise à jour des mockUsers
        this.mockUsers = [testUser];
        
        // Sauvegarder dans localStorage
        localStorage.setItem('mockUsers', JSON.stringify(this.mockUsers));
        
        console.log('Utilisateur test créé:', testUser);
        console.log('mockUsers initialisé:', this.mockUsers);
        console.log('localStorage mockUsers:', JSON.parse(localStorage.getItem('mockUsers')));
    }
  }

  /**
   * Gestion des requêtes HTTP
   */
  async fetchWithAuth(endpoint, options = {}) {
    // Si mode mock, utiliser les données simulées
    if (this.isMockMode) {
      return this.handleMockRequest(endpoint, options);
    }

    const headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };

    const token = this.getSession();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      console.log(`Requête API vers ${endpoint}:`, options);

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        ...options,
        headers,
      });

      if (response.status === 401) {
        this.logout();
        throw new Error('Session expirée');
      }

      if (response.status === 403) {
        throw new Error('Accès non autorisé');
      }

      if (response.status === 404) {
        throw new Error('Ressource non trouvée');
      }

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.message || data.error || 'Une erreur est survenue';
        throw new Error(errorMessage);
      }

      console.log(`Réponse API de ${endpoint}:`, data);
      return data;
    } catch (error) {
      console.error(`Erreur API (${endpoint}):`, error);
      
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        throw new Error('Impossible de contacter le serveur. Vérifiez votre connexion internet.');
      }
      
      throw error;
    }
  }

  /**
   * Gestion des requêtes mock
   */
  async handleMockRequest(endpoint, options) {
    await new Promise(resolve => setTimeout(resolve, 500));
    console.log('Mock request:', endpoint, options);

    try {
      switch (endpoint) {
        case '/api/auth/register': {
          const userData = JSON.parse(options.body);
          return this.mockRegister(userData);
        }
        case '/api/auth/login': {
          const credentials = JSON.parse(options.body);
          return this.mockLogin(credentials);
        }
        case '/api/auth/reset-password': {
          const { email } = JSON.parse(options.body);
          return this.mockResetPassword(email);
        }
        case '/api/profile': {
          if (options.method === 'PUT') {
            const profileData = JSON.parse(options.body);
            return this.mockUpdateProfile(profileData);
          }
          return this.mockGetProfile();
        }
        case '/api/auth/refresh-token': {
          return this.mockRefreshToken();
        }
        default:
          throw new Error('Endpoint non supporté en mode mock');
      }
    } catch (error) {
      console.error('Mock error:', error);
      throw error;
    }
  }

  /**
   * Méthodes mock
   */
  mockRegister(userData) {
    const existingUser = this.mockUsers.find(u => u.email === userData.email);
    if (existingUser) {
        throw new Error('Cet email est déjà utilisé');
    }

    const newUser = {
        id: Date.now().toString(),
        ...userData,
        dateCreation: new Date().toISOString(),
        dernierLogin: new Date().toISOString()
    };

    this.mockUsers.push(newUser);
    localStorage.setItem('mockUsers', JSON.stringify(this.mockUsers));

    // Créer la réponse
    const response = {
        user: { ...newUser, password: undefined },
        token: `mock_token_${newUser.id}`
    };

    // Sauvegarder la session
    this.setSession(response.token);
    this.setUserData(response.user);

    return response;
  }

  mockLogin(credentials) {
    console.log('=== Début tentative de connexion ===');
    console.log('Credentials reçues:', credentials);
    
    this.mockUsers = JSON.parse(localStorage.getItem('mockUsers') || '[]');
    console.log('Utilisateurs disponibles:', this.mockUsers);
    
    const user = this.mockUsers.find(u => {
        console.log('Comparaison avec:', u);
        console.log('Email match:', u.email === credentials.email);
        console.log('Password match:', u.password === credentials.password);
        return u.email === credentials.email && u.password === credentials.password;
    });

    if (!user) {
        console.log('Échec de connexion: utilisateur non trouvé ou mot de passe incorrect');
        throw new Error('Email ou mot de passe incorrect');
    }

    console.log('Connexion réussie pour:', user.email);
    
    // Créer la réponse
    const response = {
        user: { ...user, password: undefined },
        token: `mock_token_${user.id}`
    };
    
    // Sauvegarder la session
    this.setSession(response.token);
    this.setUserData(response.user);
    
    console.log('Réponse de connexion:', response);
    console.log('=== Fin tentative de connexion ===');
    
    return response;
  }

  mockResetPassword(email) {
    const user = this.mockUsers.find(u => u.email === email);
    if (!user) {
      throw new Error('Aucun compte associé à cet email');
    }
    return { message: 'Instructions envoyées par email' };
  }

  mockGetProfile() {
    const token = this.getSession();
    if (!token) throw new Error('Non authentifié');

    const userId = token.split('_')[2];
    const user = this.mockUsers.find(u => u.id === userId);
    if (!user) throw new Error('Utilisateur non trouvé');

    return { ...user, password: undefined };
  }

  mockUpdateProfile(profileData) {
    const token = this.getSession();
    if (!token) throw new Error('Non authentifié');

    const userId = token.split('_')[2];
    const userIndex = this.mockUsers.findIndex(u => u.id === userId);
    if (userIndex === -1) throw new Error('Utilisateur non trouvé');

    const updatedUser = {
      ...this.mockUsers[userIndex],
      ...profileData,
      id: userId
    };

    this.mockUsers[userIndex] = updatedUser;
    localStorage.setItem('mockUsers', JSON.stringify(this.mockUsers));

    return { ...updatedUser, password: undefined };
  }

  mockRefreshToken() {
    const token = this.getSession();
    if (!token) throw new Error('Non authentifié');
    return { token: `${token}_refreshed` };
  }

  /**
   * Inscription d'un nouvel utilisateur
   */
  async register(userData) {
    try {
      const sanitizedData = this.sanitizeUserData(userData);
      const data = await this.fetchWithAuth('/api/auth/register', {
        method: 'POST',
        body: JSON.stringify(sanitizedData),
      });

      if (data.token) {
        this.setSession(data.token);
        this.setUserData(data.user);
      }

      return data;
    } catch (error) {
      console.error('Erreur d\'inscription:', error);
      throw error;
    }
  }

  /**
   * Connexion d'un utilisateur
   */
  async login(credentials) {
    try {
      const data = await this.fetchWithAuth('/api/auth/login', {
        method: 'POST',
        body: JSON.stringify(credentials),
      });

      if (data.token) {
        this.setSession(data.token);
        this.setUserData(data.user);
        this.startSessionRefreshTimer();
      }

      return data;
    } catch (error) {
      console.error('Erreur de connexion:', error);
      throw error;
    }
  }

  /**
   * Réinitialisation du mot de passe
   */
  async resetPassword(email) {
    try {
      return await this.fetchWithAuth('/api/auth/reset-password', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
    } catch (error) {
      console.error('Erreur de réinitialisation du mot de passe:', error);
      throw new Error('Impossible de réinitialiser le mot de passe. Veuillez réessayer plus tard.');
    }
  }

  /**
   * Mise à jour du profil
   */
  async updateProfile(profileData) {
    try {
      const data = await this.fetchWithAuth('/api/profile', {
        method: 'PUT',
        body: JSON.stringify(profileData),
      });

      this.setUserData(data);
      return data;
    } catch (error) {
      console.error('Erreur de mise à jour du profil:', error);
      throw new Error('Impossible de mettre à jour le profil. Veuillez réessayer.');
    }
  }

  /**
   * Récupération du profil
   */
  async getProfile() {
    try {
      const data = await this.fetchWithAuth('/api/profile');
      this.setUserData(data);
      return data;
    } catch (error) {
      console.error('Erreur de récupération du profil:', error);
      throw new Error('Impossible de récupérer les informations du profil.');
    }
  }

  /**
   * Nettoyage des données utilisateur
   */
  sanitizeUserData(userData) {
    const { password, confirmPassword, ...cleanData } = userData;
    return {
      ...cleanData,
      password: password,
      email: userData.email?.toLowerCase().trim()
    };
  }

  /**
   * Rafraîchissement automatique du token
   */
  startSessionRefreshTimer() {
    const REFRESH_INTERVAL = 55 * 60 * 1000; // 55 minutes
    
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
    
    this.refreshTimer = setInterval(async () => {
      try {
        const response = await this.fetchWithAuth('/api/auth/refresh-token', {
          method: 'POST'
        });
        
        if (response.token) {
          this.setSession(response.token);
        }
      } catch (error) {
        console.error('Erreur lors du rafraîchissement du token:', error);
        this.logout();
      }
    }, REFRESH_INTERVAL);
  }

  /**
   * Déconnexion
   */
  logout() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
    
    this.removeSession();
    this.removeUserData();
    window.location.href = '/login';
  }

  /**
   * Vérification de l'authentification
   */
  isAuthenticated() {
    const token = this.getSession();
    const userData = this.getUserData();
    return !!(token && userData && (!this.isMockMode || token.startsWith('mock_token_')));
  }

  /**
   * Gestion de la session
   */
  setSession(token) {
    sessionStorage.setItem(this.tokenKey, token);
  }

  getSession() {
    return sessionStorage.getItem(this.tokenKey);
  }

  removeSession() {
    sessionStorage.removeItem(this.tokenKey);
  }

  /**
   * Gestion des données utilisateur
   */
  setUserData(user) {
    sessionStorage.setItem(this.userKey, JSON.stringify(user));
  }

  getUserData() {
    const userData = sessionStorage.getItem(this.userKey);
    return userData ? JSON.parse(userData) : null;
  }

  removeUserData() {
    sessionStorage.removeItem(this.userKey);
  }

  /**
   * Méthode de débogage pour voir les utilisateurs mock
   */
  getMockUsers() {
    if (this.isMockMode) {
      return this.mockUsers;
    }
    return [];
  }
}

export const authService = new AuthService();
