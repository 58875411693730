import { ACTES_FAMILLE } from './famille';
import { ACTES_SUCCESSIONS } from './successions';
import { ACTES_IMMOBILIER } from './immobilier';
import { ACTES_PRETS } from './prets';
import { ACTES_SOCIETES } from './societes';
import { ACTES_DIVERS } from './divers';

const ACTES_CATEGORIES = {
  FAMILLE: {
    id: "FAMILLE",
    label: "Actes relatifs à la famille",
    actes: ACTES_FAMILLE
  },
  SUCCESSIONS: {
    id: "SUCCESSIONS",
    label: "Actes relatifs aux successions et libéralités",
    actes: ACTES_SUCCESSIONS
  },
  IMMOBILIER: {
    id: "IMMOBILIER",
    label: "Actes relatifs aux biens immobiliers",
    actes: ACTES_IMMOBILIER
  },
  PRETS: {
    id: "PRETS",
    label: "Actes relatifs aux prêts et sûretés",
    actes: ACTES_PRETS
  },
  SOCIETES: {
    id: "SOCIETES",
    label: "Actes relatifs aux sociétés",
    actes: ACTES_SOCIETES
  },
  DIVERS: {
    id: "DIVERS",
    label: "Actes divers et procurations",
    actes: ACTES_DIVERS
  }
};

export default ACTES_CATEGORIES;