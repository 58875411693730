import React, { useState } from 'react';
import { 
  Info, 
  Printer, 
  Calculator, 
  FileText, 
  DollarSign, 
  Percent,
  ChevronUp,
  ChevronDown
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

// Utilitaire de formatage monétaire
const formatCurrency = (value) => {
  if (typeof value !== 'number') return '0,00 €';
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  }).format(value);
};

// Configuration des catégories
const RESULT_CATEGORIES = [
  {
    key: 'emoluments',
    title: 'Émoluments',
    icon: Calculator,
    showTva: true,
    description: 'Rémunération de l\'office notarial'
  },
  {
    key: 'debours',
    title: 'Débours',
    icon: DollarSign,
    showTva: false,
    description: 'Sommes acquittées pour le compte du client'
  },
  {
    key: 'formalites',
    title: 'Formalités',
    icon: FileText,
    showTva: true,
    description: 'Démarches administratives obligatoires'
  },
  {
    key: 'documents',
    title: 'Documents',
    icon: FileText,
    showTva: true,
    description: 'Copies et autres documents délivrés'
  },
  {
    key: 'taxes',
    title: 'Taxes et droits',
    icon: Percent,
    showTva: false,
    description: 'TVA et droits de mutation'
  }
];

// Composant détails d'une catégorie
const CategoryDetails = ({ 
  title, 
  icon: Icon, 
  montants, 
  details, 
  description,
  showTva = false 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const total = showTva ? (montants?.ttc || 0) : (details?.total || 0);
  const ht = montants?.ht || 0;
  const tva = montants?.tva || 0;
  const categoryDetails = details?.details || {};

  return (
    <Card className="mb-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 
          transition-colors rounded-t-lg"
      >
        <div className="flex items-center space-x-3">
          <Icon className="w-5 h-5 text-blue-600" />
          <div className="text-left">
            <span className="font-medium text-gray-900">{title}</span>
            {description && (
              <p className="text-sm text-gray-500">{description}</p>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <span className="font-medium">{formatCurrency(total)}</span>
          {isExpanded ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
        </div>
      </button>

      {isExpanded && (
        <CardContent className="border-t border-gray-200 bg-gray-50">
          <div className="space-y-4">
            {/* Montants principaux */}
            <div className="space-y-2">
              {showTva ? (
                <>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Montant HT</span>
                    <span>{formatCurrency(ht)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">TVA (20%)</span>
                    <span>{formatCurrency(tva)}</span>
                  </div>
                  <div className="flex justify-between font-medium border-t border-gray-200 pt-2">
                    <span>Total TTC</span>
                    <span>{formatCurrency(total)}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span>{formatCurrency(total)}</span>
                </div>
              )}
            </div>

            {/* Détails supplémentaires */}
            {Object.keys(categoryDetails).length > 0 && (
              <div className="border-t border-gray-200 pt-4 space-y-2">
                <h4 className="font-medium text-sm text-gray-900 mb-2">Détails</h4>
                {Object.entries(categoryDetails).map(([key, value]) => (
                  <div key={key} className="flex justify-between text-sm">
                    <span className="text-gray-600">
                      {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                    </span>
                    <span>{formatCurrency(value)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export const ResultsDisplay = ({ results }) => {
  if (!results) return null;

  // Calcul du total général optimisé
  const calculateTotal = () => {
    return Object.values(results.sections || {}).reduce((sum, section) => {
      const sectionTotal = section.montants?.ttc || section.total || 0;
      return sum + sectionTotal;
    }, 0);
  };

  return (
    <div className="lg:pt-20 space-y-6">
      {/* En-tête avec total */}
      <Card className="bg-gradient-to-r from-blue-600 to-blue-700 text-white">
        <CardHeader>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Résultat du calcul</h3>
            <button
              onClick={() => window.print()}
              className="flex items-center px-4 py-2 bg-white text-blue-600 rounded-lg 
                hover:bg-blue-50 transition-colors"
            >
              <Printer className="w-4 h-4 mr-2" />
              Imprimer
            </button>
          </div>
          <div className="text-3xl font-bold">
            {formatCurrency(calculateTotal())}
          </div>
        </CardHeader>
      </Card>

      {/* Détails par catégorie */}
      <div className="space-y-4">
        {RESULT_CATEGORIES.map(({ key, title, icon, showTva, description }) => (
          results.sections?.[key] && (
            <CategoryDetails
              key={key}
              title={title}
              icon={icon}
              montants={results.sections[key].montants}
              details={results.sections[key]}
              description={description}
              showTva={showTva}
            />
          )
        ))}
      </div>

      {/* Notes importantes */}
      <Card className="bg-blue-50">
        <CardContent className="p-4">
          <div className="flex items-start space-x-2">
            <Info className="w-5 h-5 text-blue-600 mt-0.5 flex-shrink-0" />
            <div className="text-sm text-blue-800">
              <p className="font-medium mb-2">Notes importantes :</p>
              <ul className="list-disc pl-5 space-y-1">
                <li>Les montants sont calculés selon les tarifs en vigueur</li>
                <li>TVA appliquée au taux de 20% sur les émoluments, formalités et copies</li>
                <li>Les droits de mutation sont fixes et définis par la loi</li>
                <li>Certains montants peuvent varier selon les spécificités locales</li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Styles d'impression */}
      <style>
        {`
          @media print {
            .no-print { display: none; }
            .print-only { display: block; }
          }
        `}
      </style>
    </div>
  );
};

export default ResultsDisplay;