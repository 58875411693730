import React from "react";
import { Scale, ScrollText, FileText, ExternalLink, Link as LinkIcon } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

const LegalLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-flex items-center text-blue-600 hover:text-blue-800 hover:underline"
  >
    <LinkIcon className="w-4 h-4 mr-1" />
    {children}
  </a>
);

const LegalFramework = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {/* En-tête */}
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">
          Cadre juridique des tarifs notariaux
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Le calculateur NotariaPrime se base sur les textes réglementaires en vigueur qui encadrent la tarification des actes notariés.
        </p>
      </div>

      {/* Base légale principale avec liens */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center text-xl">
            <Scale className="w-6 h-6 mr-2 text-blue-600" />
            Textes de référence
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div>
            <h3 className="font-medium mb-3">1. Code de commerce</h3>
            <ul className="space-y-2 text-gray-600 ml-4">
              <li>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875833">
                  Annexe 4-7 (Articles A. 444-53 à A. 444-186)
                </LegalLink>
                <p className="text-sm text-gray-500 ml-5">Base principale des tarifs notariaux</p>
              </li>
              <li>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000041474827">
                  Article R. 444-10
                </LegalLink>
                <p className="text-sm text-gray-500 ml-5">Table 5 des remises autorisées</p>
              </li>
              <li>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032132392">
                  Article L. 444-1
                </LegalLink>
                <p className="text-sm text-gray-500 ml-5">Principes généraux de tarification</p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-medium mb-3">2. Arrêtés en vigueur</h3>
            <ul className="space-y-2 text-gray-600 ml-4">
              <li>
                <LegalLink href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000041663457">
                  Arrêté du 28 février 2020
                </LegalLink>
                <p className="text-sm text-gray-500 ml-5">Fixation des tarifs réglementés</p>
              </li>
            </ul>
          </div>
        </CardContent>
      </Card>

      {/* Détails des calculs */}
      <div className="grid gap-8 md:grid-cols-2 mb-8">
        <Card>
          <CardHeader>
            <CardTitle className="text-lg">Tranches de calcul des émoluments</CardTitle>
          </CardHeader>
          <CardContent>
            <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875833">
              Article A. 444-54
            </LegalLink>
            <ul className="mt-4 space-y-2 text-sm text-gray-600">
              <li>3,870 % jusqu'à 6 500 €</li>
              <li>1,596 % de 6 500 € à 17 000 €</li>
              <li>1,064 % de 17 000 € à 60 000 €</li>
              <li>0,799 % au-delà de 60 000 €</li>
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-lg">Barème des remises</CardTitle>
          </CardHeader>
          <CardContent>
            <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000041474827">
              Article R. 444-10
            </LegalLink>
            <ul className="mt-4 space-y-2 text-sm text-gray-600">
              <li>Maximum 10% jusqu'à 100 000 €</li>
              <li>Maximum 20% de 100 000 € à 300 000 €</li>
              <li>Maximum 40% au-delà de 300 000 €</li>
            </ul>
          </CardContent>
        </Card>
      </div>

      {/* Ressources complémentaires */}
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Ressources officielles</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <h3 className="font-medium mb-2">Notariat</h3>
              <ul className="space-y-2 text-sm">
                <li>
                  <LegalLink href="https://www.notaires.fr/fr/profession-notaire/tarif-notaires">
                    Conseil Supérieur du Notariat - Tarifs
                  </LegalLink>
                </li>
                <li>
                  <LegalLink href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000041663457">
                    Journal Officiel - Arrêté tarifaire
                  </LegalLink>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-medium mb-2">Outils de recherche</h3>
              <ul className="space-y-2 text-sm">
                <li>
                  <LegalLink href="https://www.legifrance.gouv.fr/recherche">
                    Légifrance - Recherche avancée
                  </LegalLink>
                </li>
                <li>
                  <LegalLink href="https://www.service-public.fr/particuliers/vosdroits/N172">
                    Service-public.fr - Section Notaires
                  </LegalLink>
                </li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Note de mise à jour */}
      <div className="mt-8 bg-blue-50 rounded-lg p-6">
        <p className="text-sm text-blue-800">
          Dernière mise à jour des tarifs : Arrêté du 28 février 2020
          <br />
          Tous les calculs effectués par NotariaPrime sont conformes aux textes en vigueur.
        </p>
      </div>
    </div>
  );
};

export default LegalFramework;
