export class RapportService {
    static TAUX_IMPOSITION = {
      IR: 0.19,  // 19% Impôt sur le Revenu
      PS: 0.172  // 17.2% Prélèvements Sociaux
    };
  
    static REFERENCES = {
      IR: "CGI, art. 150 U et suivants",
      PS: "Code de la sécurité sociale, art. L136-7",
      CALCUL: "BOI-RFPI-PVI-20"
    };
  
    /**
     * Construction du résultat complet
     */
    static construireResultat(params) {
      const {
        formData,
        dateCalcul,
        prixDeRevient,
        plusValueBrute,
        dureeDetention,
        abattements
      } = params;
  
      try {
        // Calcul des plus-values nettes
        const plusValueNetteIR = Math.max(0, plusValueBrute - abattements.ir);
        const plusValueNettePS = Math.max(0, plusValueBrute - abattements.ps);
  
        // Calcul des impositions
        const impositionIR = plusValueNetteIR * this.TAUX_IMPOSITION.IR;
        const impositionPS = plusValueNettePS * this.TAUX_IMPOSITION.PS;
        const impositionTotale = impositionIR + impositionPS;
  
        return {
          montants: {
            plusValueBrute: Math.round(plusValueBrute),
            plusValueNetteIR: Math.round(plusValueNetteIR),
            plusValueNettePS: Math.round(plusValueNettePS),
            impositionTotale: Math.round(impositionTotale)
          },
          details: {
            dateCalcul,
            prixDeRevient: {
              total: Math.round(prixDeRevient.total),
              detail: prixDeRevient.details
            },
            dureeDetention,
            abattements: {
              ir: Math.round(abattements.ir),
              ps: Math.round(abattements.ps),
              taux: abattements.taux
            },
            taxes: {
              ir: Math.round(impositionIR),
              ps: Math.round(impositionPS),
              taux: {
                ir: this.TAUX_IMPOSITION.IR,
                ps: this.TAUX_IMPOSITION.PS
              }
            }
          },
          parametres: this.extraireParametres(formData),
          metadata: this.genererMetadata()
        };
  
      } catch (error) {
        console.error('Erreur dans la construction du résultat:', error);
        throw error;
      }
    }
  
    /**
     * Génération du résultat pour un bien exonéré
     */
    static getResultatExonere(exonerations) {
      return {
        montants: {
          plusValueBrute: 0,
          plusValueNetteIR: 0,
          plusValueNettePS: 0,
          impositionTotale: 0
        },
        details: {
          exoneration: true,
          motif: exonerations.motif,
          article: exonerations.article,
          conditions: exonerations.details
        },
        metadata: this.genererMetadata()
      };
    }
  
    /**
     * Génération du résultat en cas d'absence de plus-value
     */
    static getResultatNul(formData, prixDeRevient) {
      return {
        montants: {
          plusValueBrute: 0,
          plusValueNetteIR: 0,
          plusValueNettePS: 0,
          impositionTotale: 0
        },
        details: {
          message: "Pas de plus-value imposable",
          prixDeRevient: {
            total: Math.round(prixDeRevient.total),
            detail: prixDeRevient.details
          },
          prixVente: Number(formData.prixVente)
        },
        parametres: this.extraireParametres(formData),
        metadata: this.genererMetadata()
      };
    }
  
    /**
     * Extraction des paramètres utilisés pour le calcul
     */
    static extraireParametres(formData) {
      return {
        typeBien: formData.propertyType,
        dateAcquisition: formData.dateAcquisition,
        dateVente: formData.dateVente,
        prixAcquisition: Number(formData.prixAcquisition),
        prixVente: Number(formData.prixVente),
        quotePart: formData.soleOwner ? 100 : Number(formData.ownershipShare),
        options: {
          travauxForfait: formData.travauxForfait || false,
          fraisAcquisitionForfait: formData.fraisAcquisitionForfait || false
        }
      };
    }
  
    /**
     * Génération des métadonnées du calcul
     */
    static genererMetadata() {
      return {
        version: "1.0.0",
        dateCalcul: new Date().toISOString(),
        references: this.REFERENCES
      };
    }
  
    /**
     * Génère un rapport détaillé au format texte
     */
    static genererRapportDetaille(resultat) {
      let rapport = "CALCUL DE PLUS-VALUE IMMOBILIÈRE\n";
      rapport += "================================\n\n";
  
      // Informations générales
      rapport += `Date de calcul : ${new Date(resultat.metadata.dateCalcul).toLocaleDateString()}\n`;
      rapport += `Référence légale : ${resultat.metadata.references.CALCUL}\n\n`;
  
      // Montants principaux
      rapport += "RÉSULTAT DU CALCUL\n";
      rapport += "-----------------\n";
      rapport += `Plus-value brute : ${this.formatMontant(resultat.montants.plusValueBrute)}\n`;
      rapport += `Plus-value nette (IR) : ${this.formatMontant(resultat.montants.plusValueNetteIR)}\n`;
      rapport += `Plus-value nette (PS) : ${this.formatMontant(resultat.montants.plusValueNettePS)}\n`;
      rapport += `Imposition totale : ${this.formatMontant(resultat.montants.impositionTotale)}\n\n`;
  
      // Détails des calculs
      if (resultat.details.prixDeRevient) {
        rapport += "DÉTAIL DU PRIX DE REVIENT\n";
        rapport += "------------------------\n";
        const { prixDeRevient } = resultat.details;
        Object.entries(prixDeRevient.detail).forEach(([key, value]) => {
          rapport += `${this.formatLabel(key)} : ${this.formatMontant(value)}\n`;
        });
        rapport += `Total : ${this.formatMontant(prixDeRevient.total)}\n\n`;
      }
  
      return rapport;
    }
  
    /**
     * Formatage des montants
     */
    static formatMontant(montant) {
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(montant);
    }
  
    /**
     * Formatage des libellés
     */
    static formatLabel(key) {
      return key
        .split(/(?=[A-Z])/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
  }