// components/tabs/DocumentsTab.jsx
import React from 'react';
import { HelpCircle } from 'lucide-react';
import { Tooltip } from "@/components/ui/tooltip";

// Constantes locales
const DOCUMENTS = {
  COPIE_EXECUTOIRE: {
    montant: 4.00,
    description: "Copie revêtue de la formule exécutoire"
  },
  COPIE_AUTHENTIQUE: {
    montant: 4.00,
    description: "Copie certifiée conforme à la minute"
  },
  COPIE_HYPOTHECAIRE: {
    montant: 4.00,
    description: "Copie pour la publicité foncière"
  },
  FRAIS_ROLE: {
    montant: 2.00,
    description: "Frais d'établissement de l'acte original"
  }
};

// Nombre de pages par défaut selon le type d'acte
const PAGES_PAR_DEFAUT = {
  'vente-gre-a-gre': 25,
  'vente-viager': 30,
  'vente-vefa': 35,
  'pret-hypothecaire': 20,
  'mainlevee': 8,
  'donation': 20,
  'contrat-mariage': 15,
  'attestation-propriete': 12,
  'bail-commercial': 25,
  'constitution-societe': 30,
  DEFAULT: 15
};

// Nombre de copies par défaut selon le type d'acte
const COPIES_PAR_DEFAUT = {
  'vente-gre-a-gre': {
    executoire: 0,
    authentique: 2,
    hypothecaire: 1
  },
  'pret-hypothecaire': {
    executoire: 1,
    authentique: 2,
    hypothecaire: 1
  },
  DEFAULT: {
    executoire: 0,
    authentique: 1,
    hypothecaire: 0
  }
};

const TVA = 0.20;

const DocumentsTab = ({ formData, setFormData }) => {
  React.useEffect(() => {
    const initializeDocuments = () => {
      const actType = formData.selectedAct?.id;
      const defaultPages = PAGES_PAR_DEFAUT[actType] || PAGES_PAR_DEFAUT.DEFAULT;
      const defaultCopies = COPIES_PAR_DEFAUT[actType] || COPIES_PAR_DEFAUT.DEFAULT;

      if (!formData.documents) {
        setFormData(prev => ({
          ...prev,
          documents: {
            pageCount: defaultPages,
            executoryCopy: defaultCopies.executoire,
            authenticCopies: defaultCopies.authentique,
            mortgageCopies: defaultCopies.hypothecaire
          }
        }));
      }
    };

    initializeDocuments();
  }, [formData.selectedAct]);

  const updateDocuments = (field, value) => {
    setFormData(prev => ({
      ...prev,
      documents: {
        ...prev.documents,
        [field]: value
      }
    }));
  };

  // Calcul des coûts des documents
  const calculateCosts = () => {
    const { documents = {} } = formData;
    const pageCount = documents.pageCount || PAGES_PAR_DEFAUT.DEFAULT;

    return {
      executory: (documents.executoryCopy || 0) * pageCount * DOCUMENTS.COPIE_EXECUTOIRE.montant,
      authentic: (documents.authenticCopies || 0) * pageCount * DOCUMENTS.COPIE_AUTHENTIQUE.montant,
      mortgage: (documents.mortgageCopies || 0) * pageCount * DOCUMENTS.COPIE_HYPOTHECAIRE.montant,
      role: pageCount * DOCUMENTS.FRAIS_ROLE.montant,
      get total() {
        return this.executory + this.authentic + this.mortgage + this.role;
      },
      get tva() {
        return this.total * TVA;
      },
      get ttc() {
        return this.total * (1 + TVA);
      }
    };
  };

  const costs = calculateCosts();

  return (
    <div className="space-y-6">
      {/* Message d'info sur les valeurs par défaut */}
      <div className="bg-blue-50 p-4 rounded-lg mb-6">
        <p className="text-sm text-blue-800 flex items-center">
          <HelpCircle className="w-4 h-4 mr-2" />
          Les valeurs sont calculées automatiquement selon le type d'acte.
          Vous pouvez les modifier si nécessaire.
        </p>
      </div>

      {/* Nombre de pages */}
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-2">
          <label className="text-sm font-medium text-gray-700">
            Nombre de pages de l'acte
          </label>
          <Tooltip content="Nombre total de pages de l'acte original, annexes comprises">
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <HelpCircle className="w-4 h-4" />
            </button>
          </Tooltip>
        </div>
        <div className="flex items-center space-x-4">
          <input
            type="number"
            min="1"
            value={formData.documents?.pageCount || PAGES_PAR_DEFAUT.DEFAULT}
            onChange={(e) => updateDocuments('pageCount', Math.max(1, parseInt(e.target.value) || 0))}
            className="w-24 p-2 border rounded-lg"
          />
          <span className="text-sm text-gray-500">
            Frais de rôle : {costs.role.toFixed(2)} € ({DOCUMENTS.FRAIS_ROLE.montant} € par page)
          </span>
        </div>
      </div>

      {/* Copies */}
      <div className="space-y-4">
        {/* Copie exécutoire */}
        <div className="bg-white p-4 rounded-lg border">
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">Copie exécutoire</label>
            <Tooltip content="Copie authentique revêtue de la formule exécutoire, nécessaire pour les actes de prêt">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="number"
              min="0"
              value={formData.documents?.executoryCopy || 0}
              onChange={(e) => updateDocuments('executoryCopy', Math.max(0, parseInt(e.target.value) || 0))}
              className="w-24 p-2 border rounded-lg"
            />
            <span className="text-sm text-gray-500">
              × {DOCUMENTS.COPIE_EXECUTOIRE.montant} € par page = {costs.executory.toFixed(2)} €
            </span>
          </div>
        </div>

        {/* Copies authentiques */}
        <div className="bg-white p-4 rounded-lg border">
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">Copies authentiques</label>
            <Tooltip content="Copies certifiées conformes à l'original pour les parties">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="number"
              min="0"
              value={formData.documents?.authenticCopies || 0}
              onChange={(e) => updateDocuments('authenticCopies', Math.max(0, parseInt(e.target.value) || 0))}
              className="w-24 p-2 border rounded-lg"
            />
            <span className="text-sm text-gray-500">
              × {DOCUMENTS.COPIE_AUTHENTIQUE.montant} € par page = {costs.authentic.toFixed(2)} €
            </span>
          </div>
        </div>

        {/* Copies hypothécaires */}
        <div className="bg-white p-4 rounded-lg border">
          <div className="flex items-center mb-2">
            <label className="text-sm font-medium text-gray-700">Copies hypothécaires</label>
            <Tooltip content="Copies destinées à la publicité foncière">
              <button className="ml-2 text-gray-400 hover:text-gray-600">
                <HelpCircle className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="number"
              min="0"
              value={formData.documents?.mortgageCopies || 0}
              onChange={(e) => updateDocuments('mortgageCopies', Math.max(0, parseInt(e.target.value) || 0))}
              className="w-24 p-2 border rounded-lg"
            />
            <span className="text-sm text-gray-500">
              × {DOCUMENTS.COPIE_HYPOTHECAIRE.montant} € par page = {costs.mortgage.toFixed(2)} €
            </span>
          </div>
        </div>
      </div>

      {/* Total et TVA */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <div className="flex justify-between items-center text-sm">
          <span>Total HT :</span>
          <span>{costs.total.toFixed(2)} €</span>
        </div>
        <div className="flex justify-between items-center text-sm mt-2">
          <span>TVA (20%) :</span>
          <span>{costs.tva.toFixed(2)} €</span>
        </div>
        <div className="flex justify-between items-center font-medium mt-2 pt-2 border-t border-gray-200">
          <span>Total TTC :</span>
          <span>{costs.ttc.toFixed(2)} €</span>
        </div>
      </div>

      {/* Note informative */}
      <div className="p-4 bg-blue-50 rounded-lg">
        <div className="text-sm text-blue-600 space-y-2">
          <p><strong>Notes :</strong></p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Les frais de copies sont soumis à TVA au taux de 20%</li>
            <li>Une copie authentique est nécessaire pour chaque partie</li>
            <li>La copie exécutoire est requise pour les actes de prêt</li>
            <li>La copie hypothécaire est obligatoire pour la publicité foncière</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DocumentsTab;