export class ExonerationsService {
    // Constantes des différents types d'exonération
    static EXONERATIONS = {
      RESIDENCE_PRINCIPALE: {
        id: "residence_principale",
        label: "Résidence principale",
        article: "150 U-II-1°",
        conditions: {
          type_occupation: "PRINCIPALE",
          delai_vente: 12, // mois après départ
          exceptions: {
            mobilite_pro: 24,  // mois
            sante: 36,        // mois
            retraite: 24      // mois
          }
        }
      },
      PREMIERE_CESSION: {
        id: "premiere_cession",
        label: "Première cession d'un logement",
        article: "150 U-II-1° bis",
        conditions: {
          delai_remploi: 24,      // mois
          revenu_fiscal_ref: 24000, // euros
          non_proprietaire: {
            duree: 4,           // années
            date_reference: "2012-02-01"
          }
        }
      },
      RETRAITE_INVALIDITE: {
        id: "retraite_invalidite",
        label: "Retraité ou invalide",
        article: "150 U-II-1° ter",
        conditions: {
          pension_retraite: true,
          invalidite: {
            categories: [2, 3],
            taux_minimum: 0.8
          }
        }
      },
      EXPROPRIATION: {
        id: "expropriation",
        label: "Expropriation",
        article: "150 U-II-4°",
        conditions: {
          delai_remploi: 12
        }
      }
    };
  
    static verifierExonerations(formData) {
      const resultats = {
        exonerationApplicable: false,
        motif: null,
        article: null,
        details: {}
      };
  
      try {
        // Vérification séquentielle des exonérations (par ordre de priorité)
        if (this.verifierExonerationResidencePrincipale(formData)) {
          return {
            exonerationApplicable: true,
            motif: "Résidence principale",
            article: this.EXONERATIONS.RESIDENCE_PRINCIPALE.article,
            details: {
              type: "TOTALE",
              baseLeglale: "CGI art. 150 U-II-1°"
            }
          };
        }
  
        if (this.verifierExonerationPremiereCession(formData)) {
          return {
            exonerationApplicable: true,
            motif: "Première cession d'un logement",
            article: this.EXONERATIONS.PREMIERE_CESSION.article,
            details: {
              type: "CONDITIONNELLE",
              delaiRemploi: `${this.EXONERATIONS.PREMIERE_CESSION.conditions.delai_remploi} mois`,
              baseLeglale: "CGI art. 150 U-II-1° bis"
            }
          };
        }
  
        if (this.verifierExonerationRetraiteInvalidite(formData)) {
          return {
            exonerationApplicable: true,
            motif: formData.exonerations.retraite ? "Retraite" : "Invalidité",
            article: this.EXONERATIONS.RETRAITE_INVALIDITE.article,
            details: {
              type: "CONDITIONNELLE",
              baseLeglale: "CGI art. 150 U-II-1° ter"
            }
          };
        }
  
        if (this.verifierExonerationExpropriation(formData)) {
          return {
            exonerationApplicable: true,
            motif: "Expropriation",
            article: this.EXONERATIONS.EXPROPRIATION.article,
            details: {
              type: "CONDITIONNELLE",
              delaiRemploi: `${this.EXONERATIONS.EXPROPRIATION.conditions.delai_remploi} mois`,
              baseLeglale: "CGI art. 150 U-II-4°"
            }
          };
        }
  
        return resultats;
  
      } catch (error) {
        console.error('Erreur lors de la vérification des exonérations:', error);
        return resultats;
      }
    }
  
    static verifierExonerationResidencePrincipale(formData) {
      if (!formData.exonerations?.residencePrincipale) return false;
  
      const dateReference = new Date(formData.dateVente);
      let delaiMaximal = this.EXONERATIONS.RESIDENCE_PRINCIPALE.conditions.delai_vente;
  
      // Vérification des exceptions pour le délai
      if (formData.specifics?.mobilite_professionnelle) {
        delaiMaximal = this.EXONERATIONS.RESIDENCE_PRINCIPALE.conditions.exceptions.mobilite_pro;
      } else if (formData.specifics?.raison_sante) {
        delaiMaximal = this.EXONERATIONS.RESIDENCE_PRINCIPALE.conditions.exceptions.sante;
      } else if (formData.specifics?.retraite) {
        delaiMaximal = this.EXONERATIONS.RESIDENCE_PRINCIPALE.conditions.exceptions.retraite;
      }
  
      // Vérification du délai si départ antérieur
      if (formData.dateDepart) {
        const delaiMois = this.calculerDelaiMois(formData.dateDepart, dateReference);
        return delaiMois <= delaiMaximal;
      }
  
      return true; // Exonération applicable si résidence principale au jour de la cession
    }
  
    static verifierExonerationPremiereCession(formData) {
      if (!formData.exonerations?.premiereCession) return false;
  
      const conditions = formData.premiereCessionDetails || {};
      
      // Vérification des conditions cumulatives
      if (conditions.beneficeAnterieur) return false;  // Déjà bénéficié de l'exonération
      if (conditions.proprio4ans) return false;        // Propriétaire RP dans les 4 ans
      if (!conditions.remploi24mois) return false;     // Pas d'engagement de remploi
  
      // Vérification du revenu fiscal si renseigné
      if (formData.revenuFiscal) {
        return Number(formData.revenuFiscal) <= this.EXONERATIONS.PREMIERE_CESSION.conditions.revenu_fiscal_ref;
      }
  
      return true;
    }
  
    static verifierExonerationRetraiteInvalidite(formData) {
      if (!formData.exonerations?.retraite && !formData.exonerations?.invalidite) return false;
  
      // Pour les retraités
      if (formData.exonerations?.retraite) {
        return Number(formData.dureeActivite || 0) >= 10; // Minimum 10 ans d'activité
      }
  
      // Pour les invalides
      if (formData.exonerations?.invalidite) {
        const categorie = Number(formData.categorieInvalidite || 0);
        return this.EXONERATIONS.RETRAITE_INVALIDITE.conditions.invalidite.categories.includes(categorie);
      }
  
      return false;
    }
  
    static verifierExonerationExpropriation(formData) {
      return formData.exonerations?.expropriation && 
             formData.expropriationDetails?.remployIndemnite === true;
    }
  
    static calculerDelaiMois(dateDebut, dateFin) {
      const debut = new Date(dateDebut);
      const fin = new Date(dateFin);
      return Math.floor((fin - debut) / (1000 * 60 * 60 * 24 * 30.44));
    }
  }