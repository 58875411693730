import React from 'react';
import { Calculator, HelpCircle } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tooltip } from "@/components/ui/tooltip";

const DatesPrices = ({ formData, handleChange }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Calculator className="w-5 h-5 mr-2 text-blue-600" />
          Dates et montants
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Dates */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Date d'acquisition */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date d'acquisition
              <Tooltip content="Date d'achat ou d'entrée en possession du bien">
                <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            </label>
            <input
              type="date"
              value={formData.dateAcquisition}
              onChange={(e) => handleChange('dateAcquisition', e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md"
              max={formData.dateVente}
            />
          </div>

          {/* Date de vente */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date de vente
              <Tooltip content="Date prévue de signature de l'acte authentique">
                <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            </label>
            <input
              type="date"
              value={formData.dateVente}
              onChange={(e) => handleChange('dateVente', e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md"
              min={formData.dateAcquisition}
            />
          </div>
        </div>

        {/* Promesse de vente */}
        <div className="space-y-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.hasPromise}
              onChange={(e) => handleChange('hasPromise', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-900">
              Promesse de vente signée
            </span>
            <Tooltip content="La date de la promesse sera utilisée pour le calcul si elle est antérieure à la vente">
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </label>

          {formData.hasPromise && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date de la promesse
              </label>
              <input
                type="date"
                value={formData.promiseDate}
                onChange={(e) => handleChange('promiseDate', e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                min={formData.dateAcquisition}
                max={formData.dateVente}
              />
            </div>
          )}
        </div>

        {/* Montants */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Prix d'acquisition */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Prix d'acquisition
              <Tooltip content="Prix d'achat ou valeur déclarée lors de l'acquisition">
                <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            </label>
            <div className="relative">
              <input
                type="number"
                value={formData.prixAcquisition}
                onChange={(e) => handleChange('prixAcquisition', e.target.value)}
                className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                placeholder="0"
                min="0"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
            </div>
          </div>

          {/* Prix de vente */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Prix de vente
              <Tooltip content="Prix de vente convenu avec l'acquéreur">
                <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            </label>
            <div className="relative">
              <input
                type="number"
                value={formData.prixVente}
                onChange={(e) => handleChange('prixVente', e.target.value)}
                className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                placeholder="0"
                min="0"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
            </div>
          </div>
        </div>

        {/* Frais d'acquisition */}
        <div className="space-y-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.fraisAcquisitionForfait}
              onChange={(e) => handleChange('fraisAcquisitionForfait', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-900">
              Option forfaitaire pour les frais d'acquisition (7,5%)
            </span>
            <Tooltip content="Frais de notaire, droits d'enregistrement, commissions...">
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </label>

          {!formData.fraisAcquisitionForfait && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frais d'acquisition réels
              </label>
              <div className="relative">
                <input
                  type="number"
                  value={formData.fraisAcquisitionSaisis}
                  onChange={(e) => handleChange('fraisAcquisitionSaisis', e.target.value)}
                  className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                  placeholder="0"
                  min="0"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">€</span>
                </div>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Saisissez le montant réel des frais payés lors de l'acquisition
              </p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default DatesPrices;