import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';

// Layout components
import Header from './components/layout/header/header';
import Footer from './components/layout/footer/footer';
import { Breadcrumbs } from "./components/ui"; 

// Page components
import HomePage from "./components/features/home/NewHomePage";
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import ProfileForm from './components/profile/ProfileForm';
import Dashboard from './components/dashboard/Dashboard';
import Trial from './pages/trial/trial';
import Calculator from '@/components/taxation/Calculator';
import DocumentsPage from './components/documents/DocumentsPage';
import SettingsPage from './components/settings/SettingsPage';
import PlusValueCalculator from "./pages/plusvalue";
import ImmobilierEvaluationCalculator from './components/immobilier/ImmobilierEvaluationCalculator';

// Pages légales
import LegalFramework from './pages/legal/framework';
import PrivacyPolicy from './pages/legal/privacy';
import TermsOfService from './pages/legal/terms';
import ActesNotaries from './pages/legal/ActesNotaries';

// Composant de layout qui utilise useLocation
const LayoutWithLocation = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  
  const noHeaderRoutes = ['/login', '/register'];
  const shouldShowHeader = !noHeaderRoutes.includes(location.pathname);
  
  return (
    <div className="min-h-screen flex flex-col">
      {shouldShowHeader && <Header />}
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

// Route protégée qui vérifie l'authentification
const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    const currentPath = window.location.pathname;
    sessionStorage.setItem('redirectAfterLogin', currentPath);
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Route publique qui redirige vers le dashboard si déjà authentifié
const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

// Composant principal de l'application
const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Routes publiques qui ne nécessitent pas le layout standard */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <RegisterForm />
              </PublicRoute>
            }
          />

          {/* Routes avec le layout standard */}
          <Route
            path="/"
            element={
              <LayoutWithLocation>
                <HomePage />
              </LayoutWithLocation>
            }
          />
          <Route
            path="/essai-gratuit"
            element={
              <LayoutWithLocation>
                <Trial />
              </LayoutWithLocation>
            }
          />

          {/* Routes légales */}
          <Route
            path="/legal/framework"
            element={
              <LayoutWithLocation>
                <LegalFramework />
              </LayoutWithLocation>
            }
          />
          <Route
            path="/legal/privacy"
            element={
              <LayoutWithLocation>
                <PrivacyPolicy />
              </LayoutWithLocation>
            }
          />
          <Route
            path="/legal/terms"
            element={
              <LayoutWithLocation>
                <TermsOfService />
              </LayoutWithLocation>
            }
          />
          <Route
            path="/legal/actes-notaries"
            element={
              <LayoutWithLocation>
                <ActesNotaries />
              </LayoutWithLocation>
            }
          />

          {/* Routes protégées */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <Dashboard />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <ProfileForm />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          <Route
            path="/taxation"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <Calculator />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          <Route
            path="/plusvalue"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <PlusValueCalculator />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          {/* Nouvelle route pour l'évaluation immobilière */}
          <Route
            path="/evaluation-immobiliere"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <ImmobilierEvaluationCalculator />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <DocumentsPage />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <LayoutWithLocation>
                  <SettingsPage />
                </LayoutWithLocation>
              </PrivateRoute>
            }
          />

          {/* Route par défaut pour les URLs non trouvées */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;