import React from 'react';
import { FileText, Info, HelpCircle } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tooltip } from "@/components/ui/tooltip";

// Constantes
const PROPERTY_TYPES = {
  RESIDENTIAL: {
    id: "RESIDENTIAL",
    label: "Résidentiel"
  },
  BUILDING_LAND: {
    id: "BUILDING_LAND",
    label: "Terrain à bâtir"
  },
  OTHER: {
    id: "OTHER",
    label: "Autre"
  }
};

const WorksExpenses = ({ formData, handleChange }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <FileText className="w-5 h-5 mr-2 text-blue-600" />
          Travaux et améliorations
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Option forfait travaux */}
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.travauxForfait}
              onChange={(e) => handleChange('travauxForfait', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-900">
              Option forfaitaire pour les travaux
            </span>
            <Tooltip content="15% du prix d'acquisition pour les biens détenus depuis plus de 5 ans">
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </label>

          {formData.travauxForfait && (
            <div className="mt-4 bg-gray-50 p-4 rounded-lg">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.travauxEnergetiques}
                  onChange={(e) => handleChange('travauxEnergetiques', e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">
                  Travaux d'amélioration énergétique (+5%)
                </span>
                <Tooltip content="Majoration pour les travaux d'amélioration de la performance énergétique">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </label>
            </div>
          )}
        </div>

        {/* Travaux réels */}
        {!formData.travauxForfait && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Montant total des travaux
                <Tooltip content="Montant total des travaux effectués et justifiés">
                  <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                </Tooltip>
              </label>
              <div className="relative">
                <input
                  type="number"
                  value={formData.travauxRealisesSaisis || ''}
                  onChange={(e) => handleChange('travauxRealisesSaisis', e.target.value)}
                  className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                  placeholder="0"
                  min="0"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">€</span>
                </div>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Les travaux doivent être justifiés par des factures
              </p>
            </div>
          </div>
        )}

        {/* Frais de voirie et réseaux (pour terrains) */}
        {formData.propertyType === PROPERTY_TYPES.BUILDING_LAND.id && (
          <div className="mt-6 pt-6 border-t border-gray-200">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Frais de voirie et réseaux divers (VRD)
                <Tooltip content="Frais de raccordement aux réseaux et voirie">
                  <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                </Tooltip>
              </label>
              <div className="relative">
                <input
                  type="number"
                  value={formData.fraisVoirieReseaux || ''}
                  onChange={(e) => handleChange('fraisVoirieReseaux', e.target.value)}
                  className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                  placeholder="0"
                  min="0"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">€</span>
                </div>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Ces frais sont pris en compte uniquement pour les terrains à bâtir
              </p>
            </div>
          </div>
        )}

        {/* Message d'information */}
        <div className="mt-4 bg-blue-50 p-4 rounded-lg">
          <div className="flex space-x-2">
            <Info className="w-5 h-5 text-blue-600 flex-shrink-0" />
            <div className="text-sm text-blue-700">
              <p className="font-medium mb-1">Informations importantes :</p>
              <ul className="list-disc pl-5 space-y-1">
                <li>Le forfait de 15% s'applique aux biens détenus depuis plus de 5 ans</li>
                <li>Les travaux réels doivent être justifiés par des factures d'entreprises</li>
                <li>Les travaux d'entretien courant ne sont pas déductibles</li>
                <li>Seuls les travaux effectivement payés sont pris en compte</li>
              </ul>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WorksExpenses;