// src/components/ui/input.jsx

import React from 'react';

export const Input = ({ label, type = 'text', ...props }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input 
      type={type}
      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      {...props}
    />
  </div>
);

export default Input;