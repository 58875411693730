// actes/societes.js
export const ACTES_SOCIETES = [
    {
      id: "constitution-societe",
      label: "Constitution de société",
      reference: "Art. A444-139",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.052 },
        { jusqu_a: 17000, taux: 0.579 },
        { jusqu_a: 60000, taux: 0.395 },
        { au_dela: true, taux: 0.290 }
      ]
    },
    {
      id: "augmentation-capital",
      label: "Augmentation de capital",
      reference: "Art. A444-140",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.052 },
        { jusqu_a: 17000, taux: 0.579 },
        { jusqu_a: 60000, taux: 0.395 },
        { au_dela: true, taux: 0.290 }
      ]
    },
    {
      id: "reduction-capital",
      label: "Réduction de capital",
      reference: "Art. A444-141",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.052 },
        { jusqu_a: 17000, taux: 0.579 },
        { jusqu_a: 60000, taux: 0.395 },
        { au_dela: true, taux: 0.290 }
      ]
    },
    {
      id: "cession-parts",
      label: "Cession de parts sociales",
      reference: "Art. A444-141",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.578 },
        { jusqu_a: 17000, taux: 0.868 },
        { jusqu_a: 60000, taux: 0.592 },
        { au_dela: true, taux: 0.434 }
      ]
    },
    {
      id: "modification-statuts",
      label: "Modification des statuts",
      reference: "Art. A444-142",
      type: "FIXE",
      montant: 230.77
    },
    {
      id: "dissolution-societe",
      label: "Dissolution de société",
      reference: "Art. A444-148",
      type: "FIXE",
      montant: 230.77
    }
  ];