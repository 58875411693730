import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import { Eye, EyeOff, Save, Trash2, Clock } from 'lucide-react';

// Composant pour les sections du formulaire
const FormSection = ({ title, children }) => (
  <div className="p-6 bg-white rounded-lg shadow mb-8">
    <h3 className="text-lg font-medium text-gray-900 pb-2 mb-4 border-b border-gray-200">
      {title}
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {children}
    </div>
  </div>
);

// Composant pour chaque champ du formulaire
const FormField = ({ label, error, children, required = false }) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-gray-700">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="mt-1">
      {children}
    </div>
    {error && (
      <p className="text-sm text-red-600 mt-1">
        {error}
      </p>
    )}
  </div>
);

// Composant pour les inputs standards
const Input = React.forwardRef(({ error, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    className={`block w-full px-4 py-2.5 text-gray-900 placeholder-gray-400 border
      rounded-lg focus:ring-2 focus:ring-offset-2 transition-colors duration-200
      ${error 
        ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'}`}
  />
));

const ProfileForm = () => {
  const { user, updateProfile } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      raisonSociale: user?.raisonSociale || '',
      siren: user?.siren || '',
      siret: user?.siret || '',
      formeJuridique: user?.formeJuridique || '',
      tvaIntracommunautaire: user?.tvaIntracommunautaire || '',
      'rcs.ville': user?.rcs?.ville || '',
      'rcs.numero': user?.rcs?.numero || '',
      'adresse.rue': user?.adresse?.rue || '',
      'adresse.codePostal': user?.adresse?.codePostal || '',
      'adresse.ville': user?.adresse?.ville || '',
      'contact.telephone': user?.contact?.telephone || '',
      'contact.email': user?.contact?.email || '',
      'contact.fax': user?.contact?.fax || ''
    }
  });

  const onSubmit = async (data) => {
    try {
      await updateProfile(data);
      alert('Profil mis à jour avec succès');
    } catch (error) {
      alert('Erreur lors de la mise à jour: ' + error.message);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">
          Informations de l'Étude
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Informations générales */}
          <FormSection title="Informations générales">
            <FormField 
              label="Raison Sociale" 
              required 
              error={errors.raisonSociale?.message}
            >
              <Input
                {...register("raisonSociale", { 
                  required: "La raison sociale est requise" 
                })}
                placeholder="Nom de l'étude"
                error={errors.raisonSociale}
              />
            </FormField>

            <FormField
              label="Forme Juridique"
              required
              error={errors.formeJuridique?.message}
            >
              <select
                {...register("formeJuridique", {
                  required: "La forme juridique est requise"
                })}
                className="block w-full px-4 py-2.5 text-gray-900 border border-gray-300 
                  rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                  focus:border-blue-500 transition-colors duration-200"
              >
                <option value="">Sélectionnez...</option>
                <option value="SCP">SCP</option>
                <option value="SELARL">SELARL</option>
                <option value="SELAS">SELAS</option>
                <option value="Individuel">Individuel</option>
              </select>
            </FormField>

            <FormField 
              label="SIREN" 
              required 
              error={errors.siren?.message}
            >
              <Input
                {...register("siren", {
                  required: "Le SIREN est requis",
                  pattern: {
                    value: /^[0-9]{9}$/,
                    message: "Le SIREN doit contenir 9 chiffres"
                  }
                })}
                placeholder="000000000"
                error={errors.siren}
              />
            </FormField>

            <FormField 
              label="SIRET" 
              required 
              error={errors.siret?.message}
            >
              <Input
                {...register("siret", {
                  required: "Le SIRET est requis",
                  pattern: {
                    value: /^[0-9]{14}$/,
                    message: "Le SIRET doit contenir 14 chiffres"
                  }
                })}
                placeholder="00000000000000"
                error={errors.siret}
              />
            </FormField>

            <FormField 
              label="N° TVA Intracommunautaire"
              required
              error={errors.tvaIntracommunautaire?.message}
            >
              <Input
                {...register("tvaIntracommunautaire", {
                  required: "Le numéro de TVA est requis"
                })}
                placeholder="FR00000000000"
                error={errors.tvaIntracommunautaire}
              />
            </FormField>
          </FormSection>

          {/* RCS */}
          <FormSection title="RCS">
            <FormField 
              label="Ville RCS" 
              required 
              error={errors['rcs.ville']?.message}
            >
              <Input
                {...register("rcs.ville", {
                  required: "La ville RCS est requise"
                })}
                placeholder="Ville"
                error={errors['rcs.ville']}
              />
            </FormField>

            <FormField 
              label="Numéro RCS" 
              required 
              error={errors['rcs.numero']?.message}
            >
              <Input
                {...register("rcs.numero", {
                  required: "Le numéro RCS est requis"
                })}
                placeholder="000 000 000"
                error={errors['rcs.numero']}
              />
            </FormField>
          </FormSection>

          {/* Adresse */}
          <FormSection title="Adresse">
            <FormField 
              label="Rue" 
              required 
              error={errors['adresse.rue']?.message}
            >
              <Input
                {...register("adresse.rue", {
                  required: "L'adresse est requise"
                })}
                placeholder="Numéro et nom de rue"
                error={errors['adresse.rue']}
              />
            </FormField>

            <FormField 
              label="Code Postal" 
              required 
              error={errors['adresse.codePostal']?.message}
            >
              <Input
                {...register("adresse.codePostal", {
                  required: "Le code postal est requis",
                  pattern: {
                    value: /^[0-9]{5}$/,
                    message: "Format de code postal invalide"
                  }
                })}
                placeholder="00000"
                error={errors['adresse.codePostal']}
              />
            </FormField>

            <FormField 
              label="Ville" 
              required 
              error={errors['adresse.ville']?.message}
            >
              <Input
                {...register("adresse.ville", {
                  required: "La ville est requise"
                })}
                placeholder="Ville"
                error={errors['adresse.ville']}
              />
            </FormField>
          </FormSection>

          {/* Contact */}
          <FormSection title="Contact">
            <FormField 
              label="Téléphone" 
              required 
              error={errors['contact.telephone']?.message}
            >
              <Input
                type="tel"
                {...register("contact.telephone", {
                  required: "Le téléphone est requis",
                  pattern: {
                    value: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
                    message: "Format de téléphone invalide"
                  }
                })}
                placeholder="06 00 00 00 00"
                error={errors['contact.telephone']}
              />
            </FormField>

            <FormField 
              label="Email" 
              required 
              error={errors['contact.email']?.message}
            >
              <Input
                type="email"
                {...register("contact.email", {
                  required: "L'email est requis",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Format d'email invalide"
                  }
                })}
                placeholder="contact@etude.fr"
                error={errors['contact.email']}
              />
            </FormField>

            <FormField 
              label="Fax" 
              error={errors['contact.fax']?.message}
            >
              <Input
                type="tel"
                {...register("contact.fax")}
                placeholder="01 00 00 00 00"
                error={errors['contact.fax']}
              />
            </FormField>
          </FormSection>

          {/* Boutons d'action */}
          <div className="flex justify-between items-center pt-6 border-t border-gray-200">
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex items-center px-6 py-3 border border-transparent 
                rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 
                hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-blue-500 transition-colors duration-200 disabled:opacity-50
                disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <>
                  <Clock className="animate-spin -ml-1 mr-2 h-5 w-5" />
                  Enregistrement...
                </>
              ) : (
                <>
                  <Save className="-ml-1 mr-2 h-5 w-5" />
                  Enregistrer
                </>
              )}
            </button>

            <button
              type="button"
              onClick={() => {
                if (window.confirm('Voulez-vous vraiment supprimer votre compte ? Cette action est irréversible.')) {
                  // Logique de suppression
                }
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent 
                text-base font-medium text-red-600 hover:text-red-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="-ml-1 mr-2 h-5 w-5" />
              Supprimer le compte
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
