import React from 'react';
import { FileText, ExternalLink, Link as LinkIcon, Scale, ScrollText } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

const LegalLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-flex items-center text-blue-600 hover:text-blue-800 hover:underline"
  >
    <LinkIcon className="w-4 h-4 mr-1" />
    {children}
  </a>
);

const ActesNotaries = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {/* En-tête */}
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">
          Liste complète des actes notariés
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Conformément à l'annexe 4-7 du Code de commerce et l'arrêté du 28 février 2020
        </p>
      </div>

      {/* Textes de référence */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center text-xl">
            <Scale className="w-6 h-6 mr-2 text-blue-600" />
            Textes de référence
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <p className="text-gray-600">
            Notre tarification se base sur les textes réglementaires suivants :
          </p>
          <ul className="space-y-2 text-gray-600 ml-4">
            <li>
              <LegalLink href="https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000005634379/LEGISCTA000043466935/">
                Annexe 4-7 du Code de commerce
              </LegalLink>
              <p className="text-sm text-gray-500 ml-5">Base de la tarification des actes notariés</p>
            </li>
            <li>
              <LegalLink href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000041663457">
                Arrêté du 28 février 2020
              </LegalLink>
              <p className="text-sm text-gray-500 ml-5">Fixation des tarifs réglementés</p>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Actes Famille */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
            I. Actes relatifs à la famille
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li>
              <div className="flex items-center">
                <span className="font-medium">1. Contrat de mariage</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875844/" className="ml-2">
                  Art. A444-67
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">2. Changement de régime matrimonial</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875844/" className="ml-2">
                  Art. A444-67
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">3. PACS</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000043466978/" className="ml-2">
                  Art. A444-162
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">4. Donation entre vifs</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875838/" className="ml-2">
                  Art. A444-68
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">5. Testament authentique</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875867/" className="ml-2">
                  Art. A444-58
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">6. Donation-partage</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875836/" className="ml-2">
                  Art. A444-69
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">7. Partage successoral</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875848/" className="ml-2">
                  Art. A444-65
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">8. Déclaration de succession</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875865/" className="ml-2">
                  Art. A444-59
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">9. Acte de notoriété</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875863/" className="ml-2">
                  Art. A444-60
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">10. Inventaire</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875859/" className="ml-2">
                  Art. A444-61
                </LegalLink>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Actes Immobilier */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
            II. Actes relatifs aux biens immobiliers
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li>
              <div className="flex items-center">
                <span className="font-medium">1. Vente immobilière de gré à gré</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875790/" className="ml-2">
                  Art. A444-91
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">2. Vente en viager</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875846/" className="ml-2">
                  Art. A444-66
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">3. Vente en l'état futur d'achèvement</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875790/" className="ml-2">
                  Art. A444-91
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">4. Vente sur adjudication</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875786/" className="ml-2">
                  Art. A444-92
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">5. Bail d'habitation</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875758/" className="ml-2">
                  Art. A444-103
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">6. Bail commercial</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875756/" className="ml-2">
                  Art. A444-104
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">7. Bail rural</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875752/" className="ml-2">
                  Art. A444-105
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">8. Crédit-bail immobilier</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875750/" className="ml-2">
                  Art. A444-106
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">9. Échange</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875782/" className="ml-2">
                  Art. A444-95
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">10. Servitude</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875762/" className="ml-2">
                  Art. A444-102
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">11. Division - État descriptif</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875738/" className="ml-2">
                  Art. A444-110
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">12. Règlement de copropriété</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875742/" className="ml-2">
                  Art. A444-108
                </LegalLink>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Actes Prêts et sûretés */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
            III. Actes relatifs aux prêts et sûretés
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li>
              <div className="flex items-center">
                <span className="font-medium">1. Prêt hypothécaire</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875720/" className="ml-2">
                  Art. A444-119
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">2. Prêt viager hypothécaire</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875694/" className="ml-2">
                  Art. A444-131
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">3. Prêt relais</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875698/" className="ml-2">
                  Art. A444-129
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">4. Affectation hypothécaire</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875684/" className="ml-2">
                  Art. A444-136
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">5. Mainlevée d'hypothèque</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875690/" className="ml-2">
                  Art. A444-133
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">6. Translation d'hypothèque</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875682/" className="ml-2">
                  Art. A444-137
                </LegalLink>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Actes Sociétés */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
            IV. Actes relatifs aux sociétés
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li>
              <div className="flex items-center">
                <span className="font-medium">1. Constitution de société</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875678/" className="ml-2">
                  Art. A444-139
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">2. Augmentation de capital</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875674/" className="ml-2">
                  Art. A444-140
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">3. Modification des statuts</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875666/" className="ml-2">
                  Art. A444-142
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">4. Cession de parts/actions</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875670/" className="ml-2">
                  Art. A444-141
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">5. Cession de fonds de commerce</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875666/" className="ml-2">
                  Art. A444-142
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">6. Fusion de sociétés</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875658/" className="ml-2">
                  Art. A444-146
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">7. Scission de société</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875654/" className="ml-2">
                  Art. A444-147
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">8. Dissolution de société</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875652/" className="ml-2">
                  Art. A444-148
                </LegalLink>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Actes Divers */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <ScrollText className="w-5 h-5 mr-2 text-blue-600" />
            V. Actes divers
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li>
              <div className="flex items-center">
                <span className="font-medium">1. Promesse de vente</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875648/" className="ml-2">
                  Art. A444-149
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">2. Promesse synallagmatique</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875646/" className="ml-2">
                  Art. A444-150
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">3. Attestation immobilière</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875588/" className="ml-2">
                  Art. A444-168
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">4. Procuration authentique</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875644/" className="ml-2">
                  Art. A444-151
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">5. Notoriété acquisitive</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875614/" className="ml-2">
                  Art. A444-158
                </LegalLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className="font-medium">6. Commodat</span>
                <LegalLink href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042875758/" className="ml-2">
                  Art. A444-103
                </LegalLink>
              </div>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Note de bas de page */}
      <div className="mt-8 bg-blue-50 rounded-lg p-6">
        <p className="text-sm text-blue-800">
          Cette liste n'est pas exhaustive. Pour plus d'informations, veuillez consulter les textes de référence.
          Le calculateur NotariaPrime prend en compte les spécificités de chaque type d'acte,
          ainsi que les différents débours, taxes et formalités obligatoires.
          Les options et majorations autorisées sont appliquées conformément à la réglementation en vigueur.
          <br /><br />
          Dernière mise à jour : {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default ActesNotaries;

