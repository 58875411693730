// actes/famille.js
export const ACTES_FAMILLE = [
    {
      id: "contrat-mariage",
      label: "Contrat de mariage",
      reference: "Art. A444-67",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.558 },
        { jusqu_a: 17000, taux: 0.856 },
        { jusqu_a: 60000, taux: 0.585 },
        { au_dela: true, taux: 0.428 }
      ]
    },
    {
      id: "changement-regime",
      label: "Changement de régime matrimonial",
      reference: "Art. A444-67",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.558 },
        { jusqu_a: 17000, taux: 0.856 },
        { jusqu_a: 60000, taux: 0.585 },
        { au_dela: true, taux: 0.428 }
      ]
    },
    {
      id: "donation-epoux",
      label: "Donation entre époux",
      reference: "Art. A444-68",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 4.000 },
        { jusqu_a: 17000, taux: 1.650 },
        { jusqu_a: 60000, taux: 1.100 },
        { au_dela: true, taux: 0.825 }
      ]
    },
    {
      id: "testament",
      label: "Testament authentique",
      reference: "Art. A444-162",
      type: "FIXE",
      montant: 115.39
    },
    {
      id: "donation",
      label: "Donation entre vifs",
      reference: "Art. A444-68",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 4.000 },
        { jusqu_a: 17000, taux: 1.650 },
        { jusqu_a: 60000, taux: 1.100 },
        { au_dela: true, taux: 0.825 }
      ]
    },
    {
      id: "donation-partage",
      label: "Donation-partage",
      reference: "Art. A444-68",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 4.000 },
        { jusqu_a: 17000, taux: 1.650 },
        { jusqu_a: 60000, taux: 1.100 },
        { au_dela: true, taux: 0.825 }
      ]
    },
    {
      id: "pacs",
      label: "Pacte civil de solidarité (PACS)",
      reference: "Art. A444-162",
      type: "FIXE",
      montant: 192.31
    },
    {
      id: "notoriete",
      label: "Acte de notoriété (hors succession)",
      reference: "Art. A444-60",
      type: "FIXE",
      montant: 57.69
    }
  ];