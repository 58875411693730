// services/calculationService/formalites.js

export class FormalitesService {
  // Constantes réglementaires
  static FORFAIT_BASE = {
    VENTE: {
      montant: 130,
      description: "Publication d'une vente",
      reference: "Art. A. 444-169"
    },
    PRET: {
      montant: 100,
      description: "Publication d'un prêt hypothécaire",
      reference: "Art. A. 444-169"
    },
    MAINLEVEE: {
      montant: 100,
      description: "Publication d'une mainlevée",
      reference: "Art. A. 444-169"
    },
    DONATION: {
      montant: 130,
      description: "Publication d'une donation",
      reference: "Art. A. 444-169"
    }
  };

  static TELEACTES = {
    montant: 50,
    description: "Télétransmission et signature électronique",
    reference: "Convention ADSN"
  };

  static RECOMMANDE = {
    SIMPLE: {
      montant: 7.08,
      description: "Lettre recommandée sans AR"
    },
    AR: {
      montant: 8.33,
      description: "Lettre recommandée avec AR"
    },
    INTERNATIONAL: {
      SIMPLE: {
        montant: 11.08,
        description: "Lettre recommandée internationale"
      },
      AR: {
        montant: 12.33,
        description: "Lettre recommandée internationale avec AR"
      }
    }
  };

  static FORMALITES = {
    PREALABLES: {
      montant: 100,
      description: "Demandes de pièces et vérifications",
      obligatoire: true
    },
    POSTERIEURES: {
      montant: 100,
      description: "Notifications et archivage",
      obligatoire: true
    }
  };

  static TVA = 0.20;

  /**
   * Calcule l'ensemble des formalités
   */
  static calculerFormalites(formData) {
    try {
      const { formalities = {} } = formData;
      let totalHT = 0;
      const details = {};

      // 1. Forfait de base selon le type d'acte
      const forfaitBase = this.calculerForfaitBase(formData);
      if (forfaitBase.applicable) {
        totalHT += forfaitBase.montant;
        details.forfaitBase = forfaitBase;
      }

      // 2. Téléactes
      if (formalities.teleactes) {
        totalHT += this.TELEACTES.montant;
        details.teleactes = {
          ...this.TELEACTES,
          applicable: true
        };
      }

      // 3. Lettres recommandées
      const recommandes = this.calculerRecommandes(formalities);
      totalHT += recommandes.total;
      details.recommandes = recommandes;

      // 4. Formalités préalables
      if (formalities.prealables) {
        totalHT += this.FORMALITES.PREALABLES.montant;
        details.prealables = {
          ...this.FORMALITES.PREALABLES,
          applicable: true
        };
      }

      // 5. Formalités postérieures
      if (formalities.posterieures) {
        totalHT += this.FORMALITES.POSTERIEURES.montant;
        details.posterieures = {
          ...this.FORMALITES.POSTERIEURES,
          applicable: true
        };
      }

      // Calcul TVA et total TTC
      const tva = totalHT * this.TVA;
      const totalTTC = totalHT + tva;

      return {
        montants: {
          ht: totalHT,
          tva: tva,
          ttc: totalTTC
        },
        details,
        validation: {
          dateCalcul: new Date().toISOString(),
          reference: "Art. A. 444-169"
        }
      };

    } catch (error) {
      console.error('Erreur dans le calcul des formalités:', error);
      return this.getEmptyResult(error.message);
    }
  }

  /**
   * Calcule le forfait de base selon le type d'acte
   */
  static calculerForfaitBase(formData) {
    const { selectedAct } = formData;
    if (!selectedAct) return { applicable: false, montant: 0 };

    let forfait;
    switch (selectedAct.id) {
      case 'vente-gre-a-gre':
      case 'vente-viager':
      case 'vente-vefa':
        forfait = this.FORFAIT_BASE.VENTE;
        break;
      case 'pret-hypothecaire':
        forfait = this.FORFAIT_BASE.PRET;
        break;
      case 'mainlevee':
        forfait = this.FORFAIT_BASE.MAINLEVEE;
        break;
      case 'donation':
      case 'donation-partage':
        forfait = this.FORFAIT_BASE.DONATION;
        break;
      default:
        return { applicable: false, montant: 0 };
    }

    return {
      ...forfait,
      applicable: true
    };
  }

  /**
   * Calcule les frais de recommandés
   */
  static calculerRecommandes(formalities) {
    const count = formalities.registeredMails || 0;
    const withAR = formalities.withAR || false;
    const international = formalities.international || false;

    let prixUnitaire;
    if (international) {
      prixUnitaire = withAR 
        ? this.RECOMMANDE.INTERNATIONAL.AR.montant 
        : this.RECOMMANDE.INTERNATIONAL.SIMPLE.montant;
    } else {
      prixUnitaire = withAR 
        ? this.RECOMMANDE.AR.montant 
        : this.RECOMMANDE.SIMPLE.montant;
    }

    const total = count * prixUnitaire;

    return {
      count,
      prixUnitaire,
      total,
      type: withAR ? 'AR' : 'SIMPLE',
      international,
      details: {
        description: `${count} lettre${count > 1 ? 's' : ''} recommandée${count > 1 ? 's' : ''}${withAR ? ' avec AR' : ''}${international ? ' internationale' : ''}`
      }
    };
  }

  /**
   * Retourne un résultat vide avec message d'erreur
   */
  static getEmptyResult(error = '') {
    return {
      montants: {
        ht: 0,
        tva: 0,
        ttc: 0
      },
      details: {
        error
      }
    };
  }

  /**
   * Valide les données d'entrée des formalités
   */
  static validateFormalites(formalities) {
    const errors = [];
    
    // Validation du nombre de recommandés
    if (formalities?.registeredMails < 0) {
      errors.push("Le nombre de recommandés ne peut pas être négatif");
    }

    // Vérification de la cohérence téléactes/recommandés
    if (formalities?.teleactes && (!formalities.registeredMails || formalities.registeredMails === 0)) {
      errors.push("La télétransmission nécessite au moins un envoi en recommandé");
    }

    // Vérification des formalités obligatoires
    if (this.FORMALITES.PREALABLES.obligatoire && !formalities?.prealables) {
      errors.push("Les formalités préalables sont obligatoires");
    }
    if (this.FORMALITES.POSTERIEURES.obligatoire && !formalities?.posterieures) {
      errors.push("Les formalités postérieures sont obligatoires");
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  /**
   * Vérifie si des formalités sont nécessaires pour un acte
   */
  static necessiteFormalites(formData) {
    const actesNecessitant = [
      'vente-gre-a-gre', 
      'vente-viager', 
      'vente-vefa',
      'pret-hypothecaire',
      'mainlevee',
      'donation',
      'donation-partage'
    ];
    
    return actesNecessitant.includes(formData.selectedAct?.id);
  }

  /**
   * Génère les suggestions de formalités selon le type d'acte
   */
  static getSuggestions(formData) {
    const suggestions = {
      teleactes: true,
      registeredMails: 2, // Par défaut, deux recommandés (vendeur et acquéreur)
      prealables: true,
      posterieures: true
    };

    // Ajustements selon le type d'acte
    if (formData.selectedAct?.id === 'mainlevee') {
      suggestions.registeredMails = 1;
    }

    if (formData.selectedAct?.id === 'donation') {
      suggestions.registeredMails = 3; // Donateur + donataires + fiscalité
    }

    return suggestions;
  }
}

export default FormalitesService;