import React from "react";
import { Shield, Lock, Eye, Server, Bell, Trash } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">
          Politique de confidentialité
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Protection de vos données sur NotariaPrime
        </p>
      </div>

      <div className="space-y-8">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Shield className="w-5 h-5 mr-2 text-blue-600" />
              Introduction
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              NotariaPrime s'engage à protéger vos données personnelles conformément au Règlement Général sur la Protection des Données (RGPD) et à la loi Informatique et Libertés.
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Eye className="w-5 h-5 mr-2 text-blue-600" />
              Données collectées
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-4 text-gray-600">
              <li className="flex items-start">
                <span className="font-medium mr-2">•</span>
                <span>Données d'identification : nom, prénom, email professionnel</span>
              </li>
              <li className="flex items-start">
                <span className="font-medium mr-2">•</span>
                <span>Données de l'étude : raison sociale, SIREN, adresse</span>
              </li>
              <li className="flex items-start">
                <span className="font-medium mr-2">•</span>
                <span>Données de connexion : logs, adresse IP</span>
              </li>
              <li className="flex items-start">
                <span className="font-medium mr-2">•</span>
                <span>Données de calcul : paramètres utilisés (anonymisés)</span>
              </li>
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Server className="w-5 h-5 mr-2 text-blue-600" />
              Utilisation des données
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-4 text-gray-600">
              <li>Fourniture du service de calcul</li>
              <li>Amélioration de nos services</li>
              <li>Assistance utilisateur</li>
              <li>Obligations légales et réglementaires</li>
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Lock className="w-5 h-5 mr-2 text-blue-600" />
              Protection des données
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <p>Nous mettons en œuvre les mesures techniques et organisationnelles appropriées :</p>
              <ul className="list-disc ml-6 space-y-2">
                <li>Chiffrement des données</li>
                <li>Accès restreint et sécurisé</li>
                <li>Hébergement en France</li>
                <li>Sauvegardes régulières</li>
              </ul>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Trash className="w-5 h-5 mr-2 text-blue-600" />
              Vos droits
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 text-gray-600">
              <p>Vous disposez des droits suivants :</p>
              <ul className="list-disc ml-6 space-y-2">
                <li>Accès à vos données</li>
                <li>Rectification des données</li>
                <li>Effacement des données</li>
                <li>Opposition au traitement</li>
                <li>Portabilité des données</li>
              </ul>
              <p className="mt-4">
                Pour exercer ces droits, contactez-nous à : contact@notariaprime.fr
              </p>
            </div>
          </CardContent>
        </Card>

        <div className="mt-8 text-sm text-gray-500 text-center">
          <p>Dernière mise à jour : {new Date().toLocaleDateString()}</p>
          <p>Pour toute question : contact@notariaprime.fr</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
