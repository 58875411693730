// src/services/immobilierEvaluationService.js

import { PRIX_M2_PAR_ZONE, STANDINGS } from '@/constants/immobilier';

class ImmobilierEvaluationService {
  static async evaluate(data) {
    const { type, surface, state, location } = data;

    const standing = this.determinerStanding(type, state);
    const prixM2 = this.getPrixM2(location, standing);
    const valeurVenale = this.estimerValeurVenale(surface, state, prixM2);
    const valeurLocative = this.estimerValeurLocative(surface, standing);

    return {
      prixM2,
      valeurVenale,
      valeurLocative,
    };
  }

  static determinerStanding(type, state) {
    // Logique pour déterminer le standing en fonction du type et de l'état du bien
    // Utilisez les données de référence dans les constantes
    // Exemple simplifié :
    if (type === 'house' && state === 'new') {
      return STANDINGS.LUXE;
    } else if (type === 'apartment' && state === 'good') {
      return STANDINGS.SUPERIEUR;
    } else {
      return STANDINGS.COURANT;
    }
  }

  static getPrixM2(location, standing) {
    // Récupérer la fourchette de prix au m² en fonction de la localisation et du standing
    // Utilisez la grille tarifaire dans les constantes
    const zone = location.toUpperCase();
    const prixM2Zone = PRIX_M2_PAR_ZONE[zone];

    if (!prixM2Zone) {
      throw new Error("Localisation non répertoriée");
    }

    return prixM2Zone[standing];
  }

  static estimerValeurVenale(surface, etat, prixM2) {
    // Estimer la valeur vénale en fonction de la surface, de l'état et du prix au m²
    let estimation = surface * ((prixM2.min + prixM2.max) / 2);

    if (etat === PROPERTY_STATES.NEW) {
      estimation *= 1.1; // Majoration de 10% si le bien est neuf
    } else if (etat === PROPERTY_STATES.WORK_NEEDED) {
      estimation *= 0.8; // Minoration de 20% si le bien nécessite des travaux
    }

    return Math.round(estimation);
  }

  static estimerValeurLocative(surface, standing) {
    // Estimer la valeur locative en fonction de la surface et du standing
    // Utilisez les données de référence dans les constantes
    const loyerM2 = VALEURS_LOCATIVES[standing];
    return Math.round(surface * loyerM2);
  }
}

export const immobilierEvaluationService = new ImmobilierEvaluationService();