import React from 'react';
import { 
  Shield, 
  HelpCircle, 
  Info, 
  FileText,  // Ajout de l'import FileText
  Building, 
  Home 
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tooltip } from "@/components/ui/tooltip";

const EXONERATIONS = {
  RESIDENCE_PRINCIPALE: {
    id: "residence_principale",
    label: "Résidence principale",
    description: "Exonération de la résidence principale au jour de la cession",
    reference: "CGI, art. 150 U-II-1°",
    tooltip: "La résidence principale au jour de la cession est totalement exonérée. Un délai de vente normal est accepté après le départ.",
    delais: {
      normal: 12, // mois
      mobilite: 24, // mois (mutation, licenciement...)
      force_majeure: 36 // mois (raisons indépendantes de la volonté)
    }
  },

  PREMIERE_CESSION: {
    id: "premiere_cession",
    label: "Première cession d'un logement",
    description: "Exonération sous conditions de la première cession d'un logement autre que la résidence principale",
    reference: "CGI, art. 150 U-II-1° bis",
    tooltip: "Exonération possible sous conditions de revenu et de remploi",
    conditions: [
      {
        id: "benefice_anterieur",
        label: "Avez-vous déjà bénéficié de cette exonération depuis le 01/02/2012 ?",
        tooltip: "L'exonération ne peut être utilisée qu'une seule fois"
      },
      {
        id: "proprio_4_ans",
        label: "Avez-vous été propriétaire de votre résidence principale au cours des 4 dernières années ?",
        tooltip: "Condition de non-propriété sur 4 ans"
      },
      {
        id: "remploi_24_mois",
        label: "Allez-vous remployer le produit de la vente dans l'achat de votre résidence principale sous 24 mois ?",
        tooltip: "Le remploi doit être effectué dans les 24 mois de la vente"
      }
    ],
    plafonds: {
      revenu: 24000 // Montant à vérifier sur revenus N-2
    }
  },

  RETRAITE: {
    id: "retraite",
    label: "Départ à la retraite",
    description: "Exonération liée au départ à la retraite d'un dirigeant",
    reference: "CGI, art. 150 U-II-1° ter",
    tooltip: "Exonération si la cession intervient avant le 31 décembre de la 2ème année suivant le départ à la retraite",
    conditions: {
      delaiMax: "31/12/N+2", // N = année de départ en retraite
      dureeActiviteMin: 10 // années
    }
  },

  INVALIDITE: {
    id: "invalidite",
    label: "Invalidité",
    description: "Exonération pour les personnes en invalidité",
    reference: "CGI, art. 150 U-II-1° ter",
    tooltip: "Exonération pour les titulaires d'une carte d'invalidité de 2ème ou 3ème catégorie",
    categories: [2, 3]
  },

  EXPROPRIATION: {
    id: "expropriation",
    label: "Expropriation",
    description: "Exonération en cas d'expropriation et de remploi",
    reference: "CGI, art. 150 U-II-4°",
    tooltip: "Exonération en cas de remploi de l'indemnité d'expropriation",
    conditions: {
      delaiRemploi: 12 // mois
    }
  },

  // Nouveaux cas d'exonération
  CESSION_HLM: {
    id: "cession_hlm",
    label: "Cession à un organisme HLM",
    description: "Exonération pour cession à un organisme HLM ou une collectivité",
    reference: "CGI, art. 150 U-II-7°",
    tooltip: "Exonération si engagement de construire des logements sociaux",
    conditions: {
      delaiConstruction: 36, // mois
      typeAcquereur: ["HLM", "SEM", "EPL", "Collectivité territoriale"],
      usageSocial: true
    }
  },

  BIEN_AGRICOLE: {
    id: "bien_agricole",
    label: "Bien rural",
    description: "Exonération des biens ruraux loués par bail à long terme",
    reference: "CGI, art. 150 U-II-6°",
    tooltip: "Exonération sous condition de prix et de durée d'exploitation",
    conditions: {
      plafondPrix: 250000,
      dureeBailMin: 5, // années
      typeBail: "long terme"
    }
  },

  MAISON_RETRAITE: {
    id: "maison_retraite",
    label: "Entrée en EHPAD",
    description: "Exonération de l'ancienne résidence principale suite à l'entrée en EHPAD",
    reference: "CGI, art. 150 U-III",
    tooltip: "Exonération sous conditions de délai et de ressources",
    conditions: {
      delaiVente: 24, // mois après l'entrée en EHPAD
      plafondRevenu: 25000,
      residencePrincipaleAvant: true,
      dureeDetentionMin: 12 // mois
    }
  }
};

const ExemptionCases = ({
  formData,
  handleChange,
  handleExonerationChange,
  handlePremiereCessionDetailChange
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Shield className="w-5 h-5 mr-2 text-blue-600" />
          Cas d'exonération
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Résidence principale */}
        <div className="space-y-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations.residencePrincipale}
              onChange={(e) => handleExonerationChange('residencePrincipale', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">
                Résidence principale
              </span>
              <Tooltip content={`${EXONERATIONS.RESIDENCE_PRINCIPALE.tooltip} (${EXONERATIONS.RESIDENCE_PRINCIPALE.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations.residencePrincipale && (
            <div className="ml-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date de départ du logement
                <Tooltip content={`Délai normal : ${EXONERATIONS.RESIDENCE_PRINCIPALE.delais.normal} mois. Délai étendu : ${EXONERATIONS.RESIDENCE_PRINCIPALE.delais.mobilite} mois en cas de mobilité professionnelle.`}>
                  <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="date"
                value={formData.dateDepart}
                onChange={(e) => handleChange('dateDepart', e.target.value)}
                max={formData.dateVente}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md"
              />
              <div className="mt-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.exonerationDetails?.residencePrincipale?.mobilite || false}
                    onChange={(e) => handleChange('exonerationDetails.residencePrincipale.mobilite', e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Départ pour mobilité professionnelle</span>
                </label>
              </div>
            </div>
          )}
        </div>

        {/* Première cession */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations.premiereCession}
              onChange={(e) => handleExonerationChange('premiereCession', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">
                Première cession d'un logement
              </span>
              <Tooltip content={`${EXONERATIONS.PREMIERE_CESSION.tooltip} (${EXONERATIONS.PREMIERE_CESSION.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations.premiereCession && (
            <div className="ml-6 space-y-4 bg-gray-50 p-4 rounded-lg">
              {EXONERATIONS.PREMIERE_CESSION.conditions.map(condition => (
                <div key={condition.id}>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={formData.premiereCessionDetails[condition.id]}
                      onChange={(e) => handlePremiereCessionDetailChange(condition.id, e.target.checked)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-700">{condition.label}</span>
                    <Tooltip content={condition.tooltip}>
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                    </Tooltip>
                  </label>
                </div>
              ))}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Revenu fiscal de référence (N-2)
                  <Tooltip content={`Doit être inférieur à ${EXONERATIONS.PREMIERE_CESSION.plafonds.revenu.toLocaleString()}€`}>
                    <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                  </Tooltip>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    value={formData.revenuFiscal}
                    onChange={(e) => handleChange('revenuFiscal', e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                    placeholder="0"
                    min="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">€</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Retraite/Invalidité */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations.retraite}
              onChange={(e) => handleExonerationChange('retraite', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">Retraite</span>
              <Tooltip content={`${EXONERATIONS.RETRAITE.tooltip} (${EXONERATIONS.RETRAITE.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations.retraite && (
            <div className="ml-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Durée d'activité (années)
                <Tooltip content={`Minimum ${EXONERATIONS.RETRAITE.conditions.dureeActiviteMin} ans d'activité requis`}>
                  <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="number"
                value={formData.dureeActivite}
                onChange={(e) => handleChange('dureeActivite', e.target.value)}
                className="block w-32 px-3 py-2 border border-gray-300 rounded-md"
                min="0"
                max="99"
              />
            </div>
          )}
        </div>

        {/* Invalidité */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations.invalidite}
              onChange={(e) => handleExonerationChange('invalidite', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">Invalidité</span>
              <Tooltip content={`${EXONERATIONS.INVALIDITE.tooltip} (${EXONERATIONS.INVALIDITE.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations.invalidite && (
            <div className="ml-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Catégorie d'invalidité
              </label>
              <select
                value={formData.categorieInvalidite}
                onChange={(e) => handleChange('categorieInvalidite', e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Sélectionnez...</option>
                <option value="2">2ème catégorie</option>
                <option value="3">3ème catégorie</option>
              </select>
            </div>
          )}
        </div>

        {/* Expropriation */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations.expropriation}
              onChange={(e) => handleExonerationChange('expropriation', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">Expropriation</span>
              <Tooltip content={`${EXONERATIONS.EXPROPRIATION.tooltip} (${EXONERATIONS.EXPROPRIATION.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations.expropriation && (
            <div className="ml-6">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.expropriationDetails.remployIndemnite}
                  onChange={(e) => handleChange('expropriationDetails', {
                    ...formData.expropriationDetails,
                    remployIndemnite: e.target.checked
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">
                  Je m'engage à remployer l'intégralité de l'indemnité d'expropriation
                </span>
              </label>
            </div>
          )}
        </div>

        {/* Cession HLM - Nouveau */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations?.cessionHlm || false}
              onChange={(e) => handleExonerationChange('cessionHlm', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <div className="flex-grow">
              <span className="text-sm font-medium text-gray-900">
                {EXONERATIONS.CESSION_HLM.label}
              </span>
              <Tooltip content={`${EXONERATIONS.CESSION_HLM.tooltip} (${EXONERATIONS.CESSION_HLM.reference})`}>
                <button className="ml-2 text-gray-400 hover:text-gray-600">
                  <HelpCircle className="w-4 h-4" />
                </button>
              </Tooltip>
            </div>
          </label>

          {formData.exonerations?.cessionHlm && (
            <div className="ml-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Type d'acquéreur
                </label>
                <select
                  value={formData.exonerationDetails?.cessionHlm?.typeAcquereur || ''}
                  onChange={(e) => handleChange('exonerationDetails.cessionHlm.typeAcquereur', e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                  <option value="">Sélectionnez...</option>
                  {EXONERATIONS.CESSION_HLM.conditions.typeAcquereur.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.exonerationDetails?.cessionHlm?.engagement || false}
                  onChange={(e) => handleChange('exonerationDetails.cessionHlm.engagement', e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-700">
                  L'acquéreur s'engage à construire des logements sociaux sous {EXONERATIONS.CESSION_HLM.conditions.delaiConstruction} mois
                </span>
              </label>
            </div>
          )}
        </div>

 {/* Bien agricole - Nouveau */}
 <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations?.bienAgricole || false}
              onChange={(e) => handleExonerationChange('bienAgricole', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-900">
              {EXONERATIONS.BIEN_AGRICOLE.label}
            </span>
            <Tooltip content={EXONERATIONS.BIEN_AGRICOLE.description}>
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </label>

          {formData.exonerations?.bienAgricole && (
            <div className="ml-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Durée d'exploitation (années)
                </label>
                <input
                  type="number"
                  value={formData.exonerationDetails?.bienAgricole?.dureeExploitation || ''}
                  onChange={(e) => handleDetailsChange('bienAgricole', 'dureeExploitation', e.target.value)}
                  className="block w-32 px-3 py-2 border border-gray-300 rounded-md"
                  min="0"
                />
              </div>
            </div>
          )}
        </div>

        {/* Maison de retraite - Nouveau */}
        <div className="space-y-4 pt-4 border-t border-gray-200">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formData.exonerations?.maisonRetraite || false}
              onChange={(e) => handleExonerationChange('maisonRetraite', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-900">
              {EXONERATIONS.MAISON_RETRAITE.label}
            </span>
            <Tooltip content={EXONERATIONS.MAISON_RETRAITE.description}>
              <HelpCircle className="w-4 h-4 text-gray-400" />
            </Tooltip>
          </label>

          {formData.exonerations?.maisonRetraite && (
            <div className="ml-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date d'entrée en EHPAD
                </label>
                <input
                  type="date"
                  value={formData.exonerationDetails?.maisonRetraite?.dateEntree || ''}
                  onChange={(e) => handleDetailsChange('maisonRetraite', 'dateEntree', e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                  max={formData.dateVente}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Revenu fiscal de référence
                </label>
                <div className="relative">
                  <input
                    type="number"
                    value={formData.exonerationDetails?.maisonRetraite?.revenuFiscal || ''}
                    onChange={(e) => handleDetailsChange('maisonRetraite', 'revenuFiscal', e.target.value)}
                    className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md"
                    min="0"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">€</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Note informative */}
        <div className="mt-4 bg-blue-50 p-4 rounded-lg">
          <div className="flex items-start">
            <FileText className="w-5 h-5 text-blue-600 mt-0.5" />
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Les conditions d'exonération sont définies par les articles 150 U et suivants du CGI.
                L'exonération n'est acquise que si toutes les conditions sont remplies.
                En cas de doute, consultez votre notaire.
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ExemptionCases;

