import React from "react";
import { Link } from "react-router-dom";
import { Mail, Phone, MapPin } from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-6 py-8">
        {/* Base légale des calculs et expertises */}
        <div className="mb-8 text-sm text-gray-400 border-b border-gray-800 pb-6">
          <p>
            Tarification complète des actes notariés selon : annexe 4-7 du Code de commerce, arrêté du 28 février 2020, table 5 pour les remises. 
            Évaluation immobilière selon les règles de la Charte de l'Expertise Immobilière - 5ème édition.
          </p>
        </div>

        {/* Grille 4 colonnes */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Colonne 1: Logo et description */}
          <div>
            <span className="text-xl font-bold">Notaria Prime</span>
            <p className="mt-4 text-sm text-gray-400">
              Solutions de calculs notariaux et d'expertise immobilière pour les professionnels du notariat.
            </p>
          </div>

          {/* Colonne 2: Services */}
          <div>
            <span className="text-sm font-semibold text-gray-400">Services</span>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/" className="text-sm text-gray-400 hover:text-white">
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-sm text-gray-400 hover:text-white">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/essai-gratuit" className="text-sm text-gray-400 hover:text-white">
                  Essai gratuit
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-sm text-gray-400 hover:text-white">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Colonne 3: Légal */}
          <div>
            <span className="text-sm font-semibold text-gray-400">Informations légales</span>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/legal/framework" className="text-sm text-gray-400 hover:text-white">
                  Cadre juridique
                </Link>
              </li>
              <li>
                <Link to="/legal/terms" className="text-sm text-gray-400 hover:text-white">
                  Conditions d'utilisation
                </Link>
              </li>
              <li>
                <Link to="/legal/privacy" className="text-sm text-gray-400 hover:text-white">
                  Politique de confidentialité
                </Link>
              </li>
              <li>
                <Link to="/legal/actes-notaries" className="text-sm text-gray-400 hover:text-white">
                  Actes notariés
                </Link>
              </li>
            </ul>
          </div>

          {/* Colonne 4: Contact */}
          <div>
            <span className="text-sm font-semibold text-gray-400">Contact</span>
            <ul className="mt-4 space-y-2">
              <li className="flex items-center text-sm text-gray-400 hover:text-white">
                <Mail className="w-4 h-4 mr-2" />
                <a href="mailto:contact@notariaprime.fr">
                  contact@notariaprime.fr
                </a>
              </li>
              <li className="flex items-center text-sm text-gray-400 hover:text-white">
                <Phone className="w-4 h-4 mr-2" />
                <a href="tel:+33689291008">
                  06 89 29 10 08
                </a>
              </li>
              <li className="flex items-center text-sm text-gray-400">
                <MapPin className="w-4 h-4 mr-2" />
                France
              </li>
            </ul>
          </div>
        </div>

        {/* Mention légale */}
        <div className="mt-8 pt-4 border-t border-gray-800 text-center text-sm text-gray-400">
          <p>NotariaPrime - Tous droits réservés - {new Date().getFullYear()}</p>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
