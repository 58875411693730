import React from 'react';
import { Split, HelpCircle } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Tooltip } from "@/components/ui/tooltip";

const DEMEMBREMENT_BAREME = {
  // Barème de l'article 669 du CGI
  USUFRUIT: [
    { ageMax: 20, valeur: 0.9 },
    { ageMax: 30, valeur: 0.8 },
    { ageMax: 40, valeur: 0.7 },
    { ageMax: 50, valeur: 0.6 },
    { ageMax: 60, valeur: 0.5 },
    { ageMax: 70, valeur: 0.4 },
    { ageMax: 80, valeur: 0.3 },
    { ageMax: 90, valeur: 0.2 },
    { ageMax: Infinity, valeur: 0.1 }
  ]
};

const PropertyDismemberment = ({ formData, handleChange }) => {
  const handleDemembrement = (type) => {
    handleChange('demembrement', {
      ...formData.demembrement,
      type
    });
  };

  const handleAge = (age) => {
    const valeurUsufruit = DEMEMBREMENT_BAREME.USUFRUIT.find(
      tranche => age <= tranche.ageMax
    )?.valeur || 0.1;

    handleChange('demembrement', {
      ...formData.demembrement,
      age,
      valeurUsufruit,
      valeurNuePropriete: 1 - valeurUsufruit
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Split className="w-5 h-5 mr-2 text-blue-600" />
          Démembrement de propriété
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Type de démembrement */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Type de droit
            <Tooltip content="Sélectionnez le type de droit détenu sur le bien">
              <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
            </Tooltip>
          </label>
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                checked={formData.demembrement?.type === 'usufruit'}
                onChange={() => handleDemembrement('usufruit')}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2 text-sm text-gray-900">Usufruit</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                checked={formData.demembrement?.type === 'nue-propriete'}
                onChange={() => handleDemembrement('nue-propriete')}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2 text-sm text-gray-900">Nue-propriété</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                checked={!formData.demembrement?.type || formData.demembrement.type === 'pleine-propriete'}
                onChange={() => handleDemembrement('pleine-propriete')}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2 text-sm text-gray-900">Pleine propriété</span>
            </label>
          </div>
        </div>

        {/* Age de l'usufruitier */}
        {formData.demembrement?.type && formData.demembrement.type !== 'pleine-propriete' && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Âge de l'usufruitier
              <Tooltip content="L'âge de l'usufruitier détermine la valeur respective de l'usufruit et de la nue-propriété">
                <HelpCircle className="inline-block w-4 h-4 ml-1 text-gray-400" />
              </Tooltip>
            </label>
            <input
              type="number"
              min="1"
              max="99"
              value={formData.demembrement?.age || ''}
              onChange={(e) => handleAge(Number(e.target.value))}
              className="block w-32 px-3 py-2 border border-gray-300 rounded-md"
            />
            {formData.demembrement?.age && (
              <div className="mt-2 text-sm text-gray-600">
                <p>Valeur de l'usufruit : {(formData.demembrement.valeurUsufruit * 100).toFixed(0)}%</p>
                <p>Valeur de la nue-propriété : {(formData.demembrement.valeurNuePropriete * 100).toFixed(0)}%</p>
              </div>
            )}
          </div>
        )}

        {/* Notice explicative */}
        <div className="bg-blue-50 p-4 rounded-lg">
          <p className="text-sm text-blue-700">
            Le calcul de la plus-value en cas de démembrement de propriété s'effectue selon le barème 
            de l'article 669 du CGI. La valeur des droits dépend de l'âge de l'usufruitier au jour 
            de la cession.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default PropertyDismemberment;