import React from 'react';
import { X } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";

export const HistoryModal = ({
  calculations,
  onSelect,
  onClose
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <Card className="max-w-4xl mx-4 max-h-[80vh] flex flex-col">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>Historique des calculs</span>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-1 overflow-auto">
        {calculations.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            Aucun calcul sauvegardé
          </div>
        ) : (
          <div className="space-y-4">
            {calculations.map((calc) => (
              <div
                key={calc.id}
                className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer"
                onClick={() => onSelect(calc)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium">
                      {calc.formData.selectedAct?.label || 'Calcul sans titre'}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {new Date(calc.date).toLocaleString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <span className="font-medium text-blue-600">
                      {new Intl.NumberFormat('fr-FR', { 
                        style: 'currency', 
                        currency: 'EUR' 
                      }).format(calc.results.total.ttc)}
                    </span>
                    <p className="text-sm text-gray-500">
                      {calc.formData.baseAmount 
                        ? new Intl.NumberFormat('fr-FR', { 
                          style: 'currency', 
                          currency: 'EUR' 
                        }).format(calc.formData.baseAmount)
                        : 'Montant non défini'}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  </div>
);