// actes/divers.js
export const ACTES_DIVERS = [
    {
      id: "procuration-generale",
      label: "Procuration générale",
      reference: "Art. A444-151",
      type: "FIXE",
      montant: 76.92,
      specificites: {
        type: "PROCURATION",
        subtype: "GENERALE"
      }
    },
    {
      id: "procuration-speciale",
      label: "Procuration spéciale",
      reference: "Art. A444-151",
      type: "FIXE",
      montant: 76.92,
      specificites: {
        type: "PROCURATION",
        subtype: "SPECIALE"
      }
    },
    {
      id: "consentement-adoption",
      label: "Consentement à adoption",
      reference: "Art. A444-162",
      type: "FIXE",
      montant: 76.92
    },
    {
      id: "acceptation-donation",
      label: "Acceptation de donation ou de legs",
      reference: "Art. A444-156",
      type: "FIXE",
      montant: 76.92
    },
    {
      id: "renonciation-donation",
      label: "Renonciation à donation ou à legs",
      reference: "Art. A444-157",
      type: "FIXE",
      montant: 76.92
    },
    {
      id: "declaration-insaisissabilite",
      label: "Déclaration d'insaisissabilité",
      reference: "Art. A444-155",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "depot-testament",
      label: "Dépôt de testament olographe ou mystique",
      reference: "Art. A444-58",
      type: "FIXE",
      montant: 38.46
    },
    {
      id: "acte-complementaire",
      label: "Acte complémentaire ou interprétatif",
      reference: "Art. A444-172",
      type: "FIXE",
      montant: 76.92
    },
    {
      id: "acte-rectificatif",
      label: "Acte rectificatif",
      reference: "Art. A444-173",
      type: "FIXE",
      montant: 115.38
    },
    {
      id: "copie-authentique",
      label: "Copie authentique",
      reference: "Art. A444-186",
      type: "UNITE",
      montant: 2.30,
      specificites: {
        type: "PAR_PAGE"
      }
    },
    {
      id: "certificat",
      label: "Certificat (propriété, vie, identité...)",
      reference: "Art. A444-169",
      type: "FIXE",
      montant: 38.46
    },
    {
      id: "attestation-notariee",
      label: "Attestation notariée spéciale",
      reference: "Art. A444-169",
      type: "FIXE",
      montant: 57.69
    }
  ];
  
  // Constantes pour les types de tarification
  export const TYPES_TARIFICATION = {
    FIXE: "FIXE",                    // Montant fixe
    PROPORTIONNEL: "PROPORTIONNEL",  // Pourcentage du montant de l'acte
    UNITE: "UNITE",                  // Tarif à l'unité (par page, etc.)
    VACATIONS: "VACATIONS"          // Tarif horaire
  };
  
  // Constantes pour les spécificités
  export const SPECIFICITES = {
    PROCURATION: {
      GENERALE: "GENERALE",
      SPECIALE: "SPECIALE"
    },
    TARIFICATION: {
      PAR_PAGE: "PAR_PAGE",
      PAR_ROLE: "PAR_ROLE",
      PAR_VACATION: "PAR_VACATION"
    }
  };
  
  // Constantes pour les références légales communes
  export const REFERENCES_LEGALES = {
    CODE_COMMERCE: "Code de commerce - Annexe 4-7",
    ARRETE_2020: "Arrêté du 28 février 2020",
    TABLE_5: "Table 5 des remises autorisées"
  };