// actes/prets.js
export const ACTES_PRETS = [
    {
      id: "pret-hypothecaire",
      label: "Prêt hypothécaire",
      reference: "Art. A444-119",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 2.105 },
        { jusqu_a: 17000, taux: 1.158 },
        { jusqu_a: 60000, taux: 0.790 },
        { au_dela: true, taux: 0.579 }
      ]
    },
    {
      id: "pret-viager",
      label: "Prêt viager hypothécaire",
      reference: "Art. A444-131",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 2.105 },
        { jusqu_a: 17000, taux: 1.158 },
        { jusqu_a: 60000, taux: 0.790 },
        { au_dela: true, taux: 0.579 }
      ]
    },
    {
      id: "cautionnement",
      label: "Cautionnement",
      reference: "Art. A444-124",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 1.315 },
        { jusqu_a: 17000, taux: 0.723 },
        { jusqu_a: 60000, taux: 0.493 },
        { au_dela: true, taux: 0.362 }
      ]
    },
    {
      id: "ppd",
      label: "Privilège de prêteur de deniers",
      reference: "Art. A444-136",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 0.789 },
        { jusqu_a: 17000, taux: 0.434 },
        { jusqu_a: 60000, taux: 0.296 },
        { au_dela: true, taux: 0.217 }
      ]
    },
    {
      id: "affectation-hypothecaire",
      label: "Affectation hypothécaire",
      reference: "Art. A444-136",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 0.789 },
        { jusqu_a: 17000, taux: 0.434 },
        { jusqu_a: 60000, taux: 0.296 },
        { au_dela: true, taux: 0.217 }
      ]
    },
    {
      id: "mainlevee",
      label: "Mainlevée d'hypothèque",
      reference: "Art. A444-133",
      type: "FIXE",
      montant: 230.77
    },
    {
      id: "quittance",
      label: "Quittance",
      reference: "Art. A444-135",
      type: "PROPORTIONNEL",
      tranches: [
        { jusqu_a: 6500, taux: 0.789 },
        { jusqu_a: 17000, taux: 0.434 },
        { jusqu_a: 60000, taux: 0.296 },
        { au_dela: true, taux: 0.217 }
      ]
    }
  ];