// services/plusValueService/index.js

import { ExonerationsService } from './exonerations';
import { PrixDeRevientService } from './prixDeRevient';
import { AbattementsService } from './abattements';
import { ValidationService } from './validation';
import { RapportService } from './rapport';

class PlusValueCalculationService {
  static VERSION = '1.0.0';
  static BASE_REGLEMENTAIRE = "Article 150 U du Code Général des Impôts";

  constructor() {
    // Services spécialisés
    this.services = {
      exonerations: ExonerationsService,
      prixDeRevient: PrixDeRevientService,
      abattements: AbattementsService,
      validation: ValidationService,
      rapport: RapportService
    };

    // Liaison des méthodes
    this.calculate = this.calculate.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  /**
   * Point d'entrée principal pour le calcul de plus-value
   */
  async calculate(formData) {
    try {
      console.group('Calcul Plus-Value');
      console.log('Données entrantes:', formData);

      // 1. Validation initiale
      this.validateInput(formData);

      // 2. Vérification des exonérations
      const exonerations = this.services.exonerations.verifierExonerations(formData);
      if (exonerations.exonerationApplicable) {
        console.log('Exonération applicable:', exonerations);
        console.groupEnd();
        return this.services.rapport.getResultatExonere(exonerations);
      }

      // 3. Calculs principaux
      const result = await this.calculerPlusValue(formData);
      
      console.log('Résultat final:', result);
      console.groupEnd();
      return result;

    } catch (error) {
      console.error('Erreur dans le calcul de plus-value:', error);
      console.groupEnd();
      throw this.formatError(error);
    }
  }

  /**
   * Validation des données d'entrée
   */
  validateInput(formData) {
    const validation = this.services.validation.validateInput(formData);
    if (!validation.isValid) {
      throw new Error(validation.errors.join('; '));
    }
  }

  /**
   * Calcul principal de la plus-value
   */
  async calculerPlusValue(formData) {
    // 1. Calcul du prix de revient
    const prixDeRevient = await this.services.prixDeRevient.calculer(formData);
    console.log('Prix de revient calculé:', prixDeRevient);

    // 2. Calcul de la plus-value brute
    const plusValueBrute = this.calculerPlusValueBrute(formData, prixDeRevient);
    if (plusValueBrute <= 0) {
      return this.services.rapport.getResultatNul(formData, prixDeRevient);
    }

    // 3. Calcul des abattements
    const dureeDetention = this.services.abattements.calculerDureeDetention(
      formData.dateAcquisition,
      this.getDateCalcul(formData)
    );
    const abattements = this.services.abattements.calculerAbattements(
      plusValueBrute,
      dureeDetention
    );

    // 4. Construction du résultat
    return this.services.rapport.construireResultat({
      formData,
      dateCalcul: this.getDateCalcul(formData),
      prixDeRevient,
      plusValueBrute,
      dureeDetention,
      abattements
    });
  }

  /**
   * Calcule la plus-value brute
   */
  calculerPlusValueBrute(formData, prixDeRevient) {
    const prixVente = Number(formData.prixVente) || 0;
    const quotePartMultiplier = formData.soleOwner 
      ? 1 
      : (Number(formData.ownershipShare) / 100);
    
    return Math.max(0, (prixVente - prixDeRevient.total) * quotePartMultiplier);
  }

  /**
   * Détermine la date à utiliser pour les calculs
   */
  getDateCalcul(formData) {
    return formData.hasPromise && formData.promiseDate
      ? formData.promiseDate
      : formData.dateVente;
  }

  /**
   * Formate les erreurs de manière cohérente
   */
  formatError(error) {
    return new Error(`Erreur de calcul de plus-value: ${error.message}`);
  }
}

// Export d'une instance unique du service
export const plusValueService = new PlusValueCalculationService();

// Export par défaut pour l'utilisation directe
export default plusValueService;

// Export des services spécialisés si nécessaire
export {
  ExonerationsService,
  PrixDeRevientService,
  AbattementsService,
  ValidationService,
  RapportService
};